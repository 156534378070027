@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

.introAnimeStart{
	#header,#indexTop{
		opacity: 0.01;
		transition: opacity .3s 1s;
	}
}

// ローディング画面
#loading {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #ffffff;
	opacity: 1;
	z-index: 100000;
}

.pageTopBox{
	position: relative;
	@include props(b,-40);
	left: 0%;
	width: 100%;
	display: flex;
	&:hover{
		cursor: pointer;
	}

	@include mq(md){
		@include props(b,-67,750);
	}

	.pageTop{
		writing-mode: vertical-rl;
		margin-left: auto;
		position: relative;
		overflow: hidden;
		p{
			@include fz_vw(14);
			@include props(pl,50);
			@include props(h,91);
			text-align: center;
			position: relative;
			z-index: 1;

			@include mq(md){
				@include fz_vw(24);
				@include props(pl,56,750);
				@include props(h,134,750);
			}
		}

		.pageTopFish{
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -10;
			transform: translate(0,100%);
			@include props(w,38);
			@include props(h,91);
			animation: pageTopFish 10s infinite normal;

			@include mq(md){
				@include props(w,56,750);
				@include props(h,134,750);
			}
		}
		
		&:after{
			content: "";
			width: 1px;
			position: absolute;
			height: 100%;
			bottom: 0;
			@include props(l,22);
			background: $basicColor;
			animation: pageTopLine 5s infinite normal;

			@include mq(md){
				@include props(l,36,750);
			}
		}
	}
}

@keyframes pageTopLine {
	0% {
		transform: translateY(100%);
	  }
	  15%, 70% {
		transform: translateY(0);
	  }
	  100% {
		transform: translateY(-100%);
	  }
}

@keyframes pageTopFish {
	0% {
		transform: translateY(100%);
	  }
	7.5%, 35% {
		transform: translateY(100%);
	  }
	50% {
		transform: translateY(0%);
		opacity: 1;
	}
	53%{
		opacity: 0.01;
		transform: translateY(0%);
	}
	100%{
		opacity: 0.01;
		transform: translateY(0%);
	}
}
//下層ページのFV
.nonIndexMainVisual{
	overflow-x: hidden;
	@include props(mb,150);

	@include mq(md){
		@include props(mb,150,750);

		.minContainer{
			padding-right: 0 !important;
		}
	}

	.nonIndexMainVisualInner{
		@include mq(max){
			position: relative;
		}

		@include mq(md){
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;


			.img{
				position: relative;
				height: inherit;
				max-height: inherit;
				width: inherit;
				max-width: inherit;
				@include props(w,509,750);
				@include props(btlr,80,750);

				img{
					@include props(btlr,80,750);
				}
			}
		}
	
		.img{
			position: absolute;
			top: 0;
			right: 0;
			@include props(h,600);
			@include props(btlr,80);
			width: 80vw;

			@include mq(max){
				width: 75vw;
				@include props(l,223);
			}
	
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				@include props(btlr,80);
			}
		}
	}
  
	.nonIndexMainVisualTtl{
	  @include props(pt,58);
	  @include props(h,600);
	  letter-spacing: .05em;
	  display: inline;
	  @include fz_vw(50);
	  font-weight: bold;
	  writing-mode: vertical-rl;
	  text-orientation: upright;
	  display: flex;
	  align-items: center;

	  @include mq(md){
		@include props(pt,0);
		height: inherit;
		max-height: inherit;
		@include fz_vw(50);
		@include props(ml,40,750);
		margin-right: auto;
		position: relative;

	  }

	  &:after{
		letter-spacing: .05em;
		display: inline-block;
		text-align: center;
		writing-mode: horizontal-tb;
		text-orientation: unset;
		@include fz_vw(16);
		color: $keyColor;
		@include props(mt,20);
	
		@include mq(md){
			position: absolute;
			@include props(w,150,750);
			@include fz_vw(24);
			@include props(mt,0,750);
			left: 50%;
			@include props(b,-30,750);
			transform: translate(-50%,100%);
		}
	  }
	}
  }


  .topProductsBnr{
	text-align: center;

	.topProductsBnrDesc{
		@include mq(md){
			@include props(w,590,750);
			margin-left: auto;
			margin-right: auto;
		}
		span{
			@include fz_vw(20);
			font-weight: bold;
			color: $keyColor;
			@include props(mb,30);
			position: relative;
			display: inline-block;
	
			@include mq(md){
				@include fz_vw(32);
				@include props(mb,30,750);
			}

			&:before{
				content: "";
				width: 2px;
				height: 100%;
				background: $keyColor;
				position: absolute;
				transform: rotate(-30deg);
				left: -1em;
				bottom: 0;
			}

			&:after{
				content: "";
				width: 2px;
				height: 100%;
				background: $keyColor;
				position: absolute;
				transform: rotate(30deg);
				right: -1em;
				bottom: 0;
			}
		}
	}

	.img{
		img{
			margin: 0 auto;
		}
	}
  }


  // ページング
  .newsPaging{
	display: flex;
	justify-content: center;
	align-items: center;
	@include props(mb,240);

	@include mq(md){
		@include props(mb,250,750);
	}
	
	.page{
		@include props(pl,15);
		@include props(pr,15);

		@include mq(md){
			@include props(pl,0,750);
			@include props(pr,0,750);
			@include props(ml,10,750);
			@include props(mr,10,750);
		}

		a{
			@include fz_vw(22);
			font-weight: bold;

			@include mq(md){
				@include fz_vw(32);
			}
		}

		&.active{
			border-bottom: 1px solid $keyColor;

			a{
				color: $keyColor;
			}
		}

		&.skipPage{
			display: flex;
			align-items: center;
			&:before{
				content: "…";
				display: block;
				@include fz_vw(32);
				font-weight: bold;
				@include props(mr,30);

				@include mq(md){
					@include fz_vw(20);
					@include props(mr,20,750);
				}
			}
		}

		&.backSkipPage{
			display: flex;
			align-items: center;
			&:after{
				content: "…";
				display: block;
				@include fz_vw(32);
				font-weight: bold;
				@include props(ml,30);

				@include mq(md){
					@include fz_vw(20);
					@include props(ml,20,750);
				}
			}
		}


	}

	.pageArrow{
		@include props(pl,15);
		@include props(pr,15);

		@include mq(md){
			@include props(pl,0,750);
			@include props(pr,0,750);
			@include props(ml,10,750);
			@include props(mr,10,750);
		}

		img{
			@include props(w,67);
			@include props(h,8.6);

			@include mq(md){
				@include props(w,75,750);
				@include props(h,20,750);
			}
		}
	}
}