@charset "utf-8";

/* =====================================
オープニング
===================================== */
.introWrap{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $keyColor;
  z-index: 100000;
  opacity: 0.01;

  &.introFinish{
    background: none;
    transition: all 1s;

    .introAnime{
      &::before{
        width: 1000vw;
        height: 1000vh;
      }
    }

    .introCon{
      transition: all 1s;
      opacity: 0.01;
      visibility: hidden;
    }
  }

  .introAnime{
    position: relative;
    width: 100%;
    height: 100%;
    &::before{
      content: "";
      background: url(/images/index/index_intro_mask.svg) top center no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,0);
      width: 100%;
      height: 100%;
      transition: all 2s;
    }
  
    .introCon{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .txt{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        svg{
          height: 31.75vh;

          path{
            transition: all .3s;
            opacity: 0.01;

            &:nth-of-type(n + 6):nth-child(-n+13){
              transition: all .3s .5s;
            }
            &:nth-of-type(n + 14){
              transition: all .3s 1s;
            }

            &.active{
              opacity: 1;
            }
          }
        }

        .introLogo{
          opacity: 0.01;
          height: 16.66vh;
          transition: all .3s 1.5s;
          &.active{
            opacity: 1;
          }
        }
      }
    }
  }
}
/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background: $basicWhiteColor;

  .headerContents{
    position: relative;
    display: flex;
    align-items: center;
    transition: .5s;
    max-width: 1920px;
    margin: 0 auto;
    @include props(pl, 40);
    @include props(pr, 30);
    @include props(h, 120);

    @include mq(max){
      @include props(pl, 90);
    }

    @include mq(md) {
      @include props(pr, 40, 750);
      @include props(pl, 40, 750);
      @include props(h, 100, 750);
    }
  }

  .headerLogo{
    @include props(w, 121);
    flex-shrink: 0;
    transition: .5s;
    margin-right: auto;

    @include mq(md) {
      @include props(w, 115, 750);
    }

    a{
      display: block;
      max-width: 100%;
      width: 100%;
    }

    img{
      width: 100%;
    }
  }

  .navBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    @include props(pr, 40, 750);
    @include props(w, 100, 750);
    @include props(h, 40, 750);
    cursor: pointer;

    span {
      display: inline-block;
      position: absolute;
      @include props(r, 40, 750);
      @include props(w, 60, 750);
      @include props(h, 6, 750);
      background-color: $basicColor;
      transition: transform .5s ease-in-out , opacity .5s ease-in-out;
      &:nth-of-type(1) {
        @include props(t, 0, 750);
      }
      &:nth-of-type(2) {
        top: 45%;
      }
      &:nth-of-type(3) {
        @include props(b, 0, 750);
      }
    }
    &.isOpen {
      span {
        &:nth-of-type(1),
        &:nth-of-type(3) {
          top: 50%;
          background-color: $basicColor;
        }
        &:nth-of-type(1) {
          @include props(w, 60, 750);
          transform: rotate(-150deg);
          z-index: 1;
        }
        &:nth-of-type(2) {
          opacity: 0.01;
        }
        &:nth-of-type(3) {
          transform: rotate(150deg);
        }
      }
    }
  }

  .navArea{
    @include mq(pc){
      .content{
        display: flex;
        align-items: center;
      }
    }
    @include mq(md) {
      position: fixed;
      top: 0;
      right: 0;
      max-width: 76.26%;
      width: 100%;
      height: 100%;
      z-index: 998;visibility: hidden;
      -webkit-overflow-scrolling: touch;
      transition: visibility .7s .8s;

      &.isShow {
        visibility: visible;
        transition: visibility .7s;
        &::after {
          transition: transform .7s;
          transform: translateX(0);
        }
        .content {
          transition: opacity .8s .8s, transform .7s .8s;
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: -5px 0 20px 0 #0000000d;
        background: $basicWhiteColor;
        z-index: -1;
        transform: translateX(100%);
        transition: transform .7s .9s;
      }
      
      .content{
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        opacity: 0.01;
        transform: translateY(20px);
        transition: opacity .8s, transform .7s;
        @include props(pt, 167, 750);
        @include props(pb, 130, 750);
        @include props(pr, 40, 750);
        @include props(pl, 110, 750);
      }
    }
  }

  .pcGlobalNav{
    transition: .5s;

    ul{
      display: flex;
      align-items: center;

      @include mq(md) {
        display: block;
      }

      .pcGlobalNavList{
        @include props(mr, 35);

        @include mq(tab){
          @include props(mr, 25);
        }

        a{
          display: block;
          @include fz_vw(16);
          font-weight: 800;
          line-height: 1;
          letter-spacing: 0.9px;
          transition: opacity .3s;

          @include mq(pc){
            @include props(pt,10);
            @include props(pb,10);
            &.current{
              border-bottom: 1px solid $keyColor;
              color: $keyColor;
            }
          }
        }

        @include mq(md) {
          @include props(mb, 70, 750);
          @include props(mr, 0);
  
          a{
            @include fz_vw(34);
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .externalLink{
    display: flex;
    align-items: center;

    @include mq(md) {
      flex-wrap: wrap;
    }

    .externalLinkBtn{
      flex-shrink: 0;
      @include props(ml, 30);

      @include mq(tab){
        @include props(ml, 20);
      }

      @include mq(md) {
        @include props(ml, 0);
      }

      &.store{
        @include mq(md) {
          max-width: 100%;
          width: 100%;
          @include props(mb, 80, 750);
          @include props(mr, 0);
        }

        a{
          display: flex;
          align-items: center;
          justify-content: center;
          color: $basicWhiteColor;
          background: #484848;
          border-radius: 50px;
          @include fz_vw(15);
          @include props(pt,13);
          @include props(pb,13);
          @include props(pl,25);
          @include props(pr,25);
          font-weight: bold;
          line-height: 1;
          letter-spacing: 0.28px;
          transition: all .3s;

          &:hover{
            background: $keyColor;
          }

          &::before{
            content: "";
            display: inline-block;
            background-image: url(/images/common/footer_cart_icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 1.4em;
            height: 1.1em;
            margin-right: .66em;
          }

          &::after{
            content: "";
            display: inline-block;
            background-image: url(/images/common/blank_white_icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: .86em;
            height: .8em;
            margin-left: .66em
          }

          @include mq(md) {
            @include fz_vw(32);
            @include props(pt,24,750);
            @include props(pb,24,750);
            @include props(pl,33,750);
            @include props(pr,33,750);
            letter-spacing: 0.65px;
          }
        }
      }
      &.twitter{
        @include props(w, 23);

        @include mq(md) {
          @include props(w, 48, 750);
          img{
            width: 100%;
          }
        }
      }
      &.instagram{
        @include props(w, 24);

        @include mq(md) {
          @include props(w, 49, 750);
          @include props(mr, 50, 750);

          img{
            width: 100%;
          }
        }
      }
    }
  }

  #productsSubNav{
    display: none;
  }
}