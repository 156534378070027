@charset "utf-8";
/* ==============================================
404ページ
============================================== */

#notFoundTop{
	@include props(pt,285);
	@include mq(md){
		@include props(pt,200,750);
	}

	.notFoundArea{
		@include props(mb,250);

		.notFoundAreaTtl{
			@include fz_vw(30);
			font-weight: bold;
			text-align: center;
			line-height: 2;
			@include props(mb,60);
			&:before{
				content: "";
				background: url(/images/404/404_icon.svg) center center no-repeat;
				background-size: contain;
				@include props(w,200);
				@include props(h,175);
				display: block;
				margin-left:auto;
				margin-right: auto;
				@include props(mb,35); 
			}
		}

		.notFoundAreaDesc{
			@include fz_vw(24);
			line-height: 2.25;
			@include props(mb,120);
			text-align: center;

			a{
				@include fz_vw(24);
				line-height: 2.25;
			}
		}

		.btn01{
			text-align: center;
		}

		@include mq(md){
			@include props(mb,200,750);

			.notFoundAreaTtl{
				@include fz_vw(32);
				@include props(mb,60,750);

				&:before{
					@include props(w,200,750);
					@include props(h,175,750);
					@include props(mb,35,750); 
				}
			}

			.notFoundAreaDesc{
				@include fz_vw(32);
				text-align: left;
				@include props(mb,120,750);

				a{
					@include fz_vw(24);
					line-height: 2.25;
				}
			}
		}
	}
}