@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#indexTop{

  .indexMainVisual{
    @include props(pt,183);
    @include mq(md){
      @include props(pt,130,750);
    }

    .indexMainVisualCopy{
      margin-left: auto;
      margin-right: 11.5625vw;
      width: 12.86vw;

      @include mq(md){
        margin-right: auto;
        @include props(w,186,750)
      }
    }

    .imgWrap{
      position: relative;
      width: 100%;
      height: 100%;
      height: 44.11458333vw;

      @include mq(md){
        height: inherit;
        max-height: inherit;
      }
    }
    .slide01{
      position: absolute;
      top: 0;
      left: 0;
      width: 65.625vw;
      height: 36.40625vw;
      @include props(btrr,80);

      @include mq(md){
        position: relative;
        width: 100%;
        @include props(h,600,750);
        @include props(mt,40,750);
        @include props(btrr,0);
      }

      .slide01Inner{
        position: relative;
        width: 100%;
        height: 100%;
        @include mq(pc){
          @include props(btrr,80);
        }

          .slide{
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: -10;
            @include mq(pc){
              @include props(btrr,80);
              padding-right: 1px;
              padding-top: 1px;
              background: $basicWhiteColor;
            }


            img{
              height: 100%;
              max-width: inherit;
              @include mq(pc){
                @include props(btrr,80);
              }
            }
          }

          .slideButtons{
            position: absolute;
            bottom: 0;
            @include props(r,-40);
            display: flex;
            flex-direction: column;

            button{
              @include props(w,12);
              @include props(h,15);
              background: #EDE1D9;
              @include props(br,20);
              @include props(mt,15);
              transition: all .5s .5s;

              &.current{
                @include mq(pc){
                  @include props(h,30);
                }
                background: $keyColor;
              }

              &:hover{
                cursor: pointer;
              }
            }

            @include mq(md){
              right: 50%;
              @include props(b,-30,750);
              transform: translate(50%,0);
              flex-direction: inherit;

              button{
                @include props(w,15,750);
                @include props(h,12,750);
                @include props(br,20,750);
                @include props(mt,0);
                @include props(ml,7,750);
                @include props(mr,7,750);
  
                &.current{
                  @include props(w,30,750);
                }
              }
            }
          }
      }
      
    }

    .slide02{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 27.395833333vw;
      height: 15.208333333vw;
      overflow: hidden;
      @include mq(pc){
        @include props(btlr,80);
      }

      .slide02Inner{
        position: relative;
        width: 100%;
        height: 100%;
        @include mq(pc){
          @include props(btlr,80);
        }

          .slide{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: -10;
            padding-left: 1px;
            padding-top: 1px;
            background: $basicWhiteColor;
            @include mq(pc){
              @include props(btlr,80);
            }


            img{
              height: 100%;
              max-width: inherit;
              @include mq(pc){
                @include props(btlr,80);
              }
            }
          }
      }
    }
  }

  .topProductsBnr{
    @include props(mt,150);

    @include mq(md){
      @include props(mt,130,750);
    }
  }

  .indexAbout{
    @include props(mt,250);
    @include props(mb,220);
    position: relative;
    overflow: hidden;

    &:after{
      content: "";
      background: $basicWhiteColor;
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      transform: translate(-50%,-50%);
      z-index: 0;
      transition: all .6s ease-in-out;
    }

    @include mq(md){
      @include props(mt,150,750);
      @include props(mb,200,750);
    }

    &.active{
      &:after{
        @include props(w,1070);
        @include props(h,1070);

        @include mq(md){
          @include props(w,1300,750);
          @include props(h,1300,750);
        }
      }

      .indexAboutCon{
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
    .inner{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: inset(0);
   }

    .bgImg{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-image: url(/images/index/index_about_main.jpg);
      background-size: cover;
      background-position: center;
      z-index: -1;
    }

    .indexAboutCon{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
      opacity: 0.01;
      visibility:hidden;
      @include props(pt,120);
      @include props(pb,120);
      transform: translateY(100px);
      transition: opacity 1s .6s, transform 1s .6s;
      backface-visibility: hidden;

      @include mq(md){
        @include props(pt,230,750);
        @include props(pb,325,750);
      }

      .img{
        width: 13.9%;
        @include props(mr,67);

        @include mq(md){
          @include props(mr,0);
          @include props(w,190,750);
          @include props(mb,80,750)
        }
      }

      .txt{
        @include mq(md){
          width: 100%;
          @include props(pl,30,750);
          @include props(pr,30,750);
        }

        .indexAboutConTtl{
          @include fz_vw(40);
          line-height: 1.75;
          letter-spacing: .1em;
          font-weight: bold;
          @include props(mb,80);

          @include mq(md){
            @include fz_vw(40);
            line-height: 1.6;
            letter-spacing: .01em;
            @include props(mb,80,750);
          }
        }

        .indexAboutConread{
          @include fz_vw(18);
          line-height: 2.3;
          letter-spacing: 0.07em;
          @include props(mb,80);

          @include mq(md){
            @include fz_vw(32);
            line-height: 1.75;
            @include props(mb,80,750);
          }
        }

        .btn01{
          margin-left: 0;

          @include mq(md){
            @include props(w,411,750);
          }
        }
      }
    }
  }

  .indexProducts{
    @include props(mb,200);

    @include mq(md){
      @include props(mb,200,750);
    }

    .indexProductsMainList{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @include props(mb,194);
      position: relative;

      @include mq(md){
        @include props(mb,150,750);
      }

      .indexProductsTtlArea{
        display: inline-block;
        width: 14.12%;

        @include mq(pc){
          position: sticky;
          @include props(t,120);
          left: 0;
        }
  
        @include mq(md){
          display: flex;
          justify-content: center;
          width: 100%;
        }
  
        h2{
          @include fz_vw(40);
          font-weight: bold;
          writing-mode: vertical-rl;
          display: flex;
          align-items: center;
          @include props(h,540);

          @include mq(md){
            @include fz_vw(50);
            @include props(h,580,750);
            &:after{
              @include fz_vw(26);
              @include props(mt,30,750);
            }
          }

          &:after{
            content: "Products";
            @include fz_vw(16);
            font-weight: bold;
            color: $keyColor;
            writing-mode: horizontal-tb;
            @include props(mt,30);
          }
        }
      }

      ul{
        width: 85.88%;
        @include mq(md){
          width: 100%;
        }

        li{
          &:not(:last-child){
            @include props(mb,150);

            @include mq(md){
              @include props(mb,150,750);
            }
          }
          &:nth-child(odd){
            a{
              @include mq(md){
                @include props(pr,40,750);
              }
            }
          }
          &:nth-child(even){
            a{
              flex-direction: row-reverse;

              @include mq(md){
                @include props(pl,40,750);
              }
            }
          }
          &:nth-child(1){
            a{
              .img{
                @include props(btlr,80);

                img{
                  @include props(btlr,80);
                }


                @include mq(md){
                  @include props(btlr,0);
                  @include props(btrr,80,750);

                  img{
                    @include props(btlr,0);
                    @include props(btrr,80,750);
                  }
                }
              }
            }
          }
          &:nth-child(2){
            a{
              .img{
                @include props(btrr,80);

                img{
                  @include props(btrr,80);
                }

                @include mq(md){
                  @include props(btrr,0);
                  @include props(btlr,80,750);

                  img{
                    @include props(btrr,0);
                    @include props(btlr,80,750);
                  }
                }
              }
            }
          }
          &:nth-child(3){
            a{
              .img{
                @include props(bbrr,80);

                img{
                  @include props(bbrr,80);
                }

                @include mq(md){
                  @include props(bbrr,80,750);
                  img{
                    @include props(bbrr,80,750);
                  }
                }
              }
            }
          }
          a{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            
            .img{
              width: 46.03%;

              @include mq(md){
                width: 76.9%;
              }
            }

            .indexProductsTtl{
              @include fz_vw(32);
              color: $keyColor;
              font-weight: bold;
              @include props(mb,40);

              @include mq(md){
                @include fz_vw(55);
                line-height: 1;
                @include props(mb,0);
                writing-mode: vertical-rl;
                text-orientation: upright;

                p{
                  @include fz_vw(42);
                  @include props(mt,155,750);
                  @include props(mr,20,750);
                }
              }
            }

            .txt{
              width: 43.3%;
              padding-left: 2.98%;

              @include mq(md){
                width: 100%;
                padding-left: 0;
                @include props(mt,60,750);
              }

              .indexProductsDesc{
                @include fz_vw(18);
                line-height: 2;
                @include props(mb,50);

                @include mq(md){
                  @include fz_vw(32);
                  @include props(mb,50,750);
                }
              }
            }
          }
        }
      }
    }

    .btn01{
      text-align: right;

      @include mq(md){
        text-align: center;
      }
    }

    .indexProductsMainImg{
      @include props(mt,200);
      position: relative;
      @include props(h,550);

      .inner{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: inset(0);
     }
  
      .bgImg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: url(/images/index/index_products_main.jpg);
        background-size: cover;
        background-position: center;
        z-index: -1;
      }
  

      @include mq(md){
        @include props(mt,200,750);
        @include props(h,900,750);
      }
    }
  }

  .indexRecipe{
    position: relative;
    @include props(mb,240);
    overflow-x: hidden;

    @include mq(md){
      @include props(mb,200,750);
    }

    @include mq(pc){
      .minContainer{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        .btn01{
          width: 100%;
        }
      }
    }
    .indexRecipeTxtArea{
      position: relative;
      margin-left: auto;
      @include props(mb,90);
      writing-mode: vertical-rl;
      text-orientation: upright;
      z-index: 1;

      &:before{
        content: "";
        @include props(w,340);
        height: 100%;
        background: $basicWhiteColor;
        position: absolute;
        @include props(l,-90);
        top: 0;
        z-index: -1;
      }

      @include mq(md){
        @include props(mb,140,750);
        writing-mode: horizontal-tb;
        text-orientation: inherit;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &:before{
          content: none;
        }
      }

      .indexRecipeTxtTtl{
        @include fz_vw(40);
        font-weight: bold;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;

        @include mq(md){
          @include fz_vw(50);
          @include props(h,470,750);
          margin-left: auto;
          margin-right: auto;
          &:after{
            @include fz_vw(26);
            @include props(mt,30,750);
          }
        }

        &:after{
          content: "Recipe";
          @include fz_vw(16);
          font-weight: bold;
          color: $keyColor;
          writing-mode: horizontal-tb;
          @include props(mt,30);
        }
      }

      .indexRecipeTxtDesc{
        @include fz_vw(18);
        line-height: 2.5;
        @include props(mt,140);
        @include props(mr,70);
        @include props(h,450);
        letter-spacing: .07em;

        @include mq(md){
          @include fz_vw(32);
          height: 100%;
          max-height: inherit;
          line-height: 1.7;
          letter-spacing: .05em;
          @include props(mt,600,750);
          @include props(mr,0,750);
        }
      }
    }

    
    .btn01{
      text-align: right;

      @include mq(md){
        text-align: center;
      }
    }

    .indexRecipeSlide{
      position: absolute;
      left: 0;
      right: 0;
      @include props(t,140);
      z-index: -1;
      @include props(h,450);
      opacity: 0.01;
      transition: all .3s;

      &.slick-initialized{
        opacity: 1;
      }

      @include mq(md){
        @include props(t,470,750);
        height: inherit;
        max-height: inherit;
      }

      & *{
        height: 100%;
      }
      img{
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .indexNews{
    display: flex;
    flex-wrap: wrap;
    @include props(mb,240);

    @include mq(md){
      @include props(mb,150,750);
      @include props(pb,150,750);
      @include props(pt,200,750);
      background: #FAF6F4;
    }
    .indexNewsTtlArea{
      display: inline-block;
      width: 9.516%;
      @include props(h,300);

      @include mq(md){
        width: 100%;
        @include props(h,470,750);
        display: flex;
      }

      h2{
        @include fz_vw(40);
        font-weight: bold;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        height: 100%;

        @include mq(md){
          @include fz_vw(50);
          margin-left: auto;
          margin-right: auto;
          &:after{
            @include fz_vw(26);
            @include props(mt,30,750);
          }
        }

        &:after{
          content: "News";
          @include fz_vw(16);
          font-weight: bold;
          color: $keyColor;
          writing-mode: horizontal-tb;
          @include props(mt,30);
        }
      }
    }

    ol{
      width: 90.484%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include props(mb,90);
      @include props(mt,90);

      @include mq(md){
        width: 100%;
        @include props(mt,0);
        @include props(mb,150,750);
      }

      li{
        width: 30.769%;

        @include mq(md){
          width: 100%;
          &:not(:last-child){
            @include props(mb,80,750);
          }

          &:not(:nth-child(1)){
            a{
              display: flex;
              justify-content: space-between;

              .img,.txt{
                width: 46.26%;
              }
            }
          }
        }

        .img{
          @include props(mb,30);
          @include props(btlr,30);
          @include props(bbrr,30);

          @include mq(md){
            @include props(mb,30,750);
            @include props(btlr,30,750);
            @include props(bbrr,30,750);
          }

          .imgWrap {
            @include props(btlr,30);
            @include props(bbrr,30);
            position: relative;
            padding-top: 66.666%;

            img{
              @include props(btlr,30);
              @include props(bbrr,30);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            @include mq(md){
              @include props(btlr,30,750);
              @include props(bbrr,30,750);

              img{
                @include props(btlr,30,750);
                @include props(bbrr,30,750);
              }
            }
          }
        }

        .indexNewsInfoArea{
          display: flex;
          align-items: flex-start;
          
          time{
            @include fz_vw(16);
            line-height: 1.5;
            @include mq(md){
              @include fz_vw(28);
              line-height: 1.2;
            }
          }

          .indexNewsCat{
            display: flex;
            margin-left: auto;
            flex-wrap: wrap;
            justify-content: flex-end;
            
            span{
              letter-spacing: 0;
              @include props(w,90);
              text-align: center;
              display: inline-block;
              color: $basicWhiteColor;
              background: #f08707;
              font-weight: bold;
              @include fz_vw(14);
              line-height: 1;
              @include props(p,4);
              &:nth-of-type(n + 2){
                @include props(ml,10);
              }

              @include mq(pc){
                &:nth-of-type(n + 3){
                  @include props(mt,10);
                }
              }


              @include mq(md){
                @include props(w,140,750);
                @include fz_vw(26);
                @include props(p,2,750);
                letter-spacing: -.05em;
                margin-left: auto;

                &:nth-of-type(n + 2){
                  margin-left: auto;
                  @include props(mt,10,750);
                }
              }
            }
          }
        }
        
        .indexNewsPostTtl{
          @include props(mt,10);
          @include fz_vw(16);
          line-height: 2;

          @include mq(md){
            @include props(mt,30,750);
            @include fz_vw(28);
            line-height: 1.7;
          }
        }

        &:nth-child(1){
          @include mq(md){
            .indexNewsPostTtl{
              @include props(mt,25,750);
            }
          }
        }
      }
    }

    .btn01{
      text-align: right;
      width: 100%;

      @include mq(md){
        text-align: center;
      }
    }
  }


  .indexContact{
    @include props(mb,290);
    @include mq(md){
      @include props(mb,250,750);
    }
    .indexContactInner{
      background: url(/images/index/index_contact.jpg) center center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      text-align: right;
      @include props(btlr,80);
      @include props(pt,60);
      @include props(pb,60);
      @include props(pr,50);
      @include props(pl,50);

      @include mq(md){
        background: url(/images/index/index_contact_sp.jpg) center center no-repeat;
        background-size: cover;
        text-align: left;
        @include props(btlr,80,750);
        @include props(pt,60,750);
        @include props(pb,353,750);
        @include props(pr,40,750);
        @include props(pl,60,750);
      }

      .indexContactArea{
        display: inline-block;
        text-align: left;
        
        .indexContactTtl{
          @include fz_vw(40);
          line-height: 1.1;
          font-weight: bold;
          @include props(mb,50);

          @include mq(md){
            @include fz_vw(50);
            @include props(mb,60,750);
          }

          .indexContactTtlSub{
            display: block;
            @include fz_vw(16);
            font-weight: bold;
            @include props(mt,20);

            @include mq(md){
              @include fz_vw(26);
              @include props(mt,20,750);
            }
          }
        }

        .indexContactDesc{
          line-height: 2;
          @include fz_vw(18);

          @include mq(md){
            @include fz_vw(28);
            letter-spacing: .02em;
          }

          &:nth-of-type(1){
            @include props(mb,20);

            @include mq(md){
              @include props(mb,25,750);
            }
          }
          &:nth-of-type(2){
            @include props(mb,50);

            @include mq(md){
              @include props(mb,60,750);
            }
          }
        }

        .contactBtn{
          display: inline-block;
          text-align: center;
          @include props(w,350);

          @include mq(md){
            @include props(w,540,750);
          }
        
          a{
            @include fz_vw(18);
            display: block;
            line-height: 1;
            @include props(pt,26);
            @include props(pb,26);

            @include mq(md){
              @include fz_vw(28);
              font-weight: bold;
              @include props(pt,36,750);
              @include props(pb,36,750);
            }
          }
        
          &.whiteBtn{
            a{
              background: $basicWhiteColor;
              transition: all .5s;

              &:hover{
                cursor: pointer;
                color: $basicWhiteColor;
                background: $basicColor;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}