@charset "utf-8";

/* =====================================================
フッター
===================================================== */

#footer{
	.footerInner{
		background: #F5F5F5;
		@include props(pt,80);
		@include props(pb,105);

		@include mq(md){
			@include props(pt,150,750);
			@include props(pb,150,750);
			@include props(pr,40,750);
			@include props(pl,40,750);
		}
		.mainLogo{
			@include props(mb,54);
			img{
				@include props(w,210);
				margin: 0 auto;
			}

			@include mq(md){
				@include props(mb,140,750);
				img{
					@include props(w,300,750);
				}
			}
		}

		.blankLinkArea{
			@include props(mb,80);
			@include mq(md){
				@include props(mb,150,750);
			}
			ul{
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;

				li{
					&:nth-child(1){
						@include props(mr,35);
						text-align: center;

						@include mq(md){
							@include props(mr,0,750);
							width: 100%;
							@include props(mb,40,750);
						}
						a{
							background: #484848;
							display: inline-block;
							text-align: center;
							@include props(br,80);
							@include props(pl,43);
							@include props(pr,43);
							@include props(pt,14);
							@include props(pb,14);
							line-height: 1;
							transition: all .3s;

							&:hover{
							  background: $keyColor;
							}

							@include mq(md){
								@include props(br,80,750);
								@include props(pl,63,750);
								@include props(pr,63,750);
								@include props(pt,24,750);
								@include props(pb,24,750);
							}

							span{
								@include fz_vw(15);
								color: $basicWhiteColor;
								line-height: 1;
								font-weight: bold;

								@include mq(md){
									@include fz_vw(32);
								}

								&:before{
									content: "";
									background: url(/images/common/footer_cart_icon.svg) center center no-repeat;
									background-size: contain;
									width: 1.4em;
									height: 0.9375em;
									display: inline-block;
									margin-right: 0.66em;
								}

								&:after{
									content: "";
									background: url(/images/common/blank_white_icon.svg) center center no-repeat;
									background-size: contain;
									width: 0.86em;
									height: 0.8em;
									display: inline-block;
									margin-left: 0.66em;
								}
							}
						}
					}

					&:not(:nth-child(1)){
						@include props(ml,15);
						@include props(mr,15);

						@include mq(md){
							@include props(ml,25,750);
							@include props(mr,25,750);
						}
						a{
							img{
								@include props(w,44);
								@include props(h,44);
								@include mq(md){
									@include props(w,80,750);
									@include props(h,80,750);
								}
							}
						}
					}
				}
			}
		}

		.innerLinkArea{
			ul{
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				@include mq(md){
					justify-content: space-between
				}
				li{
					@include props(ml,17.5);
					@include props(mr,17.5);

					@include mq(md){
						@include props(ml,0);
						@include props(mr,0);

						&:nth-child(odd){
							@include props(w,365,750);
						}
						&:nth-child(even){
							@include props(w,225,750);
						}

						&:not(:nth-child(-n+2)){
							@include props(mt,50,750)
						}
					}
					a{
						@include fz_vw(16);
						font-weight: bold;

						@include mq(md){
							@include fz_vw(28);
						}
					}
				}
			}
		}
	}

	small{
		@include fz_vw(10);
		text-align: center;
		display: block;
		@include props(pt,30);
		@include props(pb,30);
		padding-left: 20px;
		padding-right: 20px;

		@include mq(md){
			@include fz_vw(24);
			@include props(pt,28,750);
			@include props(pb,28,750);
			@include props(pl,40,750);
			@include props(pr,40,750);
		}
	}
}