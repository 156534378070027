@charset "utf-8";
/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.btn01{
  a{
    @include fz_vw(22);
    @include props(pb,20);
    @include props(pr,85);
    font-weight: bold;
    display: inline-block;
    position: relative;
    overflow: hidden;

    @include mq(md){
      @include fz_vw(32);
      @include props(pb,20,750);
      @include props(pr,70,750);
    }

    &:before{
      content: "";
      width: 100%;
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background: $basicColor;
      animation: scroll 3s infinite normal;
    }

    &:after{
      content: "";
      background: url(/images/common/btn_head_arrow.svg) center center no-repeat;
      background-size: contain;
      width: 0.787em;
      height: 0.39em;
      position: absolute;
      bottom: 0px;
      left: 100%;
      transform: translateX(-100%);
      animation: arrowMove 3s infinite normal;

      @include mq(md){
        @include props(w,40,750);
        @include props(h,20,750);
      }
    }
  }
}

.arrow01{
  @include props(w,100);
  @include props(h,10);
  margin-left: auto;
  position: relative;
  overflow: hidden;
  @include fz_vw(22);

  @include mq(md){
    @include props(h,20,750);
    @include fz_vw(32);
    @include props(w,181,750);
  }

  &:before{
    content: "";
    width: 100%;
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    background: $basicColor;
    animation: scroll 3s infinite normal;
  }

  &:after{
    content: "";
    background: url(/images/common/btn_head_arrow.svg) center center no-repeat;
    background-size: contain;
    width: 0.787em;
    height: 0.39em;
    position: absolute;
    bottom: 0px;
    left: 100%;
    transform: translateX(-100%);
    animation: arrowMove 3s infinite normal;

    @include mq(md){
      @include props(w,40,750);
      @include props(h,20,750);
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  15%, 85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes arrowMove {
  0% {
    left: 0;
    visibility: hidden;
  }
  15%, 85%{
    left: 100%;
    visibility: visible;
  }90%{
    visibility: hidden;
    left: 110%;
  }100% {
    left: 0;
    visibility: hidden;
  }
}

.btn02{
  a{
    display: block;
    text-align: center;
    @include fz_vw(20);
    font-weight: bold;
    line-height: 1;
    @include props(pt,25);
    @include props(pb,25);
    background: #F6F0EC;
    transition: all .5s;

    &:hover{
      background: $keyColor;
      color: $basicWhiteColor;
      opacity: 1 !important;

      &.linkBlank{
        &:after{
          background: url(/images/common/blank_white_icon.svg) center center no-repeat;
          background-size: contain;
        }
      }
    }

    @include mq(md){
      @include fz_vw(32);
      @include props(pt,30,750);
      @include props(pb,30,750);
    }

    &.linkBlank{
      &:after{
        content: "";
        background: url(/images/common/blank_black_icon.svg) center center no-repeat;
        background-size: contain;
        width: 1em;
        height: 1em;
        display: inline-block;
        vertical-align: bottom;
        margin-left: .5em;
        transition: all .5s;
      }
    }
  }
}

.btn03{
  @include props(w,350);
  margin: 0 auto;
  text-align: center;

  @include mq(md){
    @include props(w,540,750);
  }

  a{
    display: block;
    width: 100%;
    line-height: 1;
    @include fz_vw(18);
    border: 1px solid #484848;
    padding: 1.44em;
    transition: all .5s;

    &:hover{
      background: #484848;
      color: $basicWhiteColor;
      opacity: 1 !important;
    }

    @include mq(md){
      @include fz_vw(32);
      font-weight: bold;
    }
  }
}

.btn04{
  a{
    display: block;
    text-align: center;
    @include fz_vw(18);
    font-weight: bold;
    line-height: 1;
    @include props(pt,25);
    @include props(pb,25);
    background: #484848;
    border: 1px solid #484848;
    color: $basicWhiteColor;
    transition: all .5s;

    &:hover{
      background: $basicWhiteColor;
      color: #484848;
      opacity: 1 !important;
    }

    @include mq(md){
      @include fz_vw(32);
      @include props(pt,30,750);
      @include props(pb,30,750);
    }
  }
}

.btn05{
  a{
    display: block;
    text-align: center;
    @include fz_vw(20);
    font-weight: bold;
    line-height: 1;
    @include props(pt,25);
    @include props(pb,25);
    background: #F6F0EC;
    transition: all .5s;

    &:hover{
      background: #484848;
      color: $basicWhiteColor;
      opacity: 1 !important;

      &.linkBlank{
        &:after{
          background: url(/images/common/blank_white_icon.svg) center center no-repeat;
          background-size: contain;
        }
      }
    }

    @include mq(md){
      @include fz_vw(32);
      @include props(pt,30,750);
      @include props(pb,30,750);
    }

    &.linkBlank{
      &:after{
        content: "";
        background: url(/images/common/blank_black_icon.svg) center center no-repeat;
        background-size: contain;
        width: 1em;
        height: 1em;
        display: inline-block;
        vertical-align: bottom;
        margin-left: .5em;
        transition: all .5s;
      }
    }
  }
}