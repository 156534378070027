@charset "utf-8";
/* ==============================================
NEWSページ
============================================== */

#companyTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
		  &:after{
			content: "Company";
		  }
		}
	}

	.companyMessageArea{
		@include props(mb,250);

		@include mq(md){
			@include props(mb,200,750);
		}

		.companyMessageTtlWrap{
			@include mq(pc){
				display: flex;
				justify-content: flex-end;
			}
			@include props(mb,-360);

			@include mq(md){
				@include props(mb,150,750);
			}

			.companyMessageTtl{
				@include fz_vw(40);
				font-weight: bold;
				@include mq(pc){
					writing-mode: vertical-rl;
					display: flex;
					align-items: center;
					@include props(h,360);
				}
	  
				@include mq(md){
					@include fz_vw(40);
					height: inherit;
					max-height: inherit;
					writing-mode: horizontal-tb;
					display: flex;
					flex-direction: column;
					&:after{
						@include fz_vw(24);
						@include props(mt,0,750);
					}
				}
	  
				&:after{
					content: "Message";
					@include fz_vw(20);
					font-weight: bold;
					color: $keyColor;
					writing-mode: horizontal-tb;
					@include props(mt,30);
				}
			}
		}

		.companyMessageFlex{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-start;
			.img01{
				position: relative;
				width: 50%;
				.inner{
					@include props(btrr,80);
					height: 100%;
					width: 100%;
					img{
						@include props(bttr,80);
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
				@include mq(md){
					@include props(w,710,750);
					@include props(ml,-40,750);
					.inner{
						@include props(btrr,80,750);

						img{
							@include props(bttr,80,750);
						}
					}
				}
				
				.companyMessageCopy{
					@include fz_vw(30);
					font-weight: bold;
					line-height: 2.33;
					position: absolute;
					@include props(t,140);
					@include props(r,-200);
					z-index: 5;
					@include mq(md){
						@include fz_vw(36);
						writing-mode: vertical-rl;
						text-orientation: upright;
						@include props(t,-130,750);
						@include props(r,40,750);
					}
				}
			}
			.txt01{
				width: 41.94%;
				@include props(mt,360);
				@include mq(md){
					width: 100%;
					@include props(mt,40,750);
				}
				.companyMessageCeo{
					@include fz_vw(30);
					@include props(mb, 70);
					font-weight: bold;
					text-align: right;
					span{
						@include fz_vw(18);
						display: inline-block;
						@include props(mr,30);
						&:before{
							content: "";
							@include props(w,50);
							height: 1px;
							background: $keyColor;
							display: inline-block;
							@include props(mr,30);
							vertical-align: middle;
						}
					}
					@include mq(md){
						@include fz_vw(36);
						@include props(mb, 80,750);
						span{
							@include fz_vw(28);
							@include props(mr,30,750);
							&:before{
								@include props(w,50,750);
								@include props(mr,30,750);
							}
						}
					}
				}
				.companyMessageDesc{
					@include fz_vw(18);
					line-height: 2.33;
					@include mq(md){
						@include fz_vw(32);
						line-height: 1.9;

						&:not(:nth-of-type(1)){
							@include props(mt,50,750);
						}
					}

					&:not(:nth-of-type(1)){
						@include props(mt,40);
					}
				}
			}
			.img02{
				position: absolute;
				bottom: 0;
				right: 0;
				width: 31.25vw;
				height: 31.25vw;
				max-height: 600px;
	
				.inner{
					@include props(btlr,80);
					width: 100%;
					height: 100%;
	
					img{
						@include props(btlr,80);
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
				@include mq(md){
					position: relative;
					@include props(w,670,750);
					height: inherit;
					max-height: inherit;
					transform: translate(5.3333vw, 0);
					@include props(mt,80,750);
					.inner{
						@include props(btlr,80,750);
		
						img{
							@include props(btlr,80,750);
						}
					}
				}
			}
			.txt02{
				width: 68.3%;
				@include props(mt,100);
				.companyMessageDesc{
					@include fz_vw(18);
					line-height: 2.33;

					&:not(:nth-of-type(1)){
						@include props(mt,40);
					}
				}
				@include mq(md){
					width: 100%;
					@include props(mt,80,750);

					.companyMessageDesc{
						@include fz_vw(32);
						line-height: 1.9;


						&:not(:nth-of-type(1)){
							@include props(mt,50,750);
						}
					}
				}
			}
		}
	}

	.companyInfoArea{

		@include props(mb,250);

		@include mq(md){
			@include props(mb,250,750);
		}
		.companyInfoMain{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			@include props(mb,100);

			@include mq(md){
				@include props(mb,140,750);
				justify-content: center;
				display: block;
			}

			.companyInfoTtlWrap{
				@include mq(pc){
					position: sticky;
					@include props(t,120);
					@include props(h,300);
				}
				@include mq(md){
					@include props(h,400,750);
					display: flex;
					justify-content: center;
				}
			}

			.companyInfoTtl{
				@include fz_vw(40);
				font-weight: bold;
				writing-mode: vertical-rl;
				display: flex;
				align-items: center;
				height: 100%;

				@include mq(md){
					@include fz_vw(40);
					&:after{
						@include fz_vw(24);
						@include props(mt,30,750);
					}
				}
	  
				&:after{
					content: "Company\Ainfo";
					white-space: pre;
					text-align: center;
					@include fz_vw(20);
					font-weight: bold;
					color: $keyColor;
					writing-mode: horizontal-tb;
					@include props(mt,30);
				}
			}

			.companyInfoList{
				width: 76.79%;
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;

				@include mq(md){
					width: 100%;
				}

				dt{
					width: 18.39%;
					@include props(mb,50);
					.companyInfoListTtl{
						@include fz_vw(18);
						font-weight: bold;
						line-height: 1;
						@include props(pl,35);
						@include props(pt,13);
						@include props(pb,13);
						border-left: 1px solid $keyColor;
					}

					@include mq(md){
						width: 100%;
						@include props(mb,20,750);

						.companyInfoListTtl{
							@include fz_vw(32);
							@include props(pl,40,750);
							@include props(pt,8,750);
							@include props(pb,8,750);
						}
					}
				}

				dd{
					width: 81.61%;
					@include props(mb,50);

					.companyInfoListDesc{
						@include fz_vw(18);
					}

					iframe{
						@include props(mt,40);
						width: 100%;
						@include props(h,460);
					}

					@include mq(md){
						width: 100%;
						@include props(pl,40,750);
						@include props(mb,60,750);

						.companyInfoListDesc{
							@include fz_vw(32);
						}
	
						iframe{
							@include props(mt,30,750);
							@include props(h,460,750);
						}
					}
				}
			}
		}

		.companyInfoAreaImg{
			position: relative;
			@include props(h,410);
	  
			.inner{
			  position: absolute;
			  top: 0;
			  left: 0;
			  width: 100%;
			  height: 100%;
			  clip-path: inset(0);
		   }
		
			.bgImg{
			  position: fixed;
			  top: 0;
			  left: 0;
			  width: 100vw;
			  height: 100vh;
			  background-image: url(/images/company/company_info_anime_img.jpg);
			  background-size: cover;
			  background-position: center;
			  z-index: -1;
			}
		
	  
			@include mq(md){
			  @include props(h,750,750);
			  .bgImg{
				background-image: url(/images/company/company_info_anime_img_sp.jpg);
				background-size: cover;
			  }
			}
		  }

		.contactLink{
			@include props(mt,80);

			@include mq(md){
				@include props(mt,80,750);
			}
			.contactLinkTtl{
				text-align: center;
				@include fz_vw(30);
				font-weight: bold;
				@include props(mb,60);

				@include mq(md){
					@include fz_vw(40);
					@include props(mb,80,750);
				}
			}

			.contactLinkDesc{
				@include fz_vw(18);
				line-height: 2.33;
				@include props(mb,50);
				text-align: center;

				@include mq(md){
					@include fz_vw(32);
					text-align: left;
					@include props(mb,80,750);
				}
			}

			.btn04{
				@include props(w,350);
				margin: 0 auto;

				@include mq(md){
					@include props(w,540,750);
				}
			}
		}


		.companyStoryListArea{
			@include props(mt,200);
			position: relative;

			@include mq(md){
				@include props(mt,220,750);
			}

			.companyStoryListAreaTtlArea{
				position: relative;
				text-align: center;
				@include props(pt,71);

				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%,0);
					@include props(w,1004);
					@include props(h,1004);
					background: #FAF6F4;
					border-radius: 50%;
					z-index: -10;
				}

				@include mq(md){
					@include props(pt,60,750);
					@include props(pb,70,750);
					&:before{
						@include props(w,626,750);
						@include props(h,626,750);
					}

					&:after{
						content: "";
						background: #FAF6F4;
						position: absolute;
						@include props(t,160,750);
						@include props(l,-40,750);
						width: 100vw;
						height: 100%;
						z-index: -1;
					}
				}

				.companyStoryListAreaTtl{
					@include fz_vw(40);
					color: $keyColor;
					font-weight: bold;
					@include props(mb,30);

					@include mq(md){
						@include fz_vw(50);
						@include props(mb,50,750);

						&:before{
							@include props(w,211,750);
							@include props(h,88,750);
							@include props(mb,50,750);
						}
					}

					&:before{
						content: "";
						background: url(/images/company/company_red_icon.png) center center no-repeat;
						background-size: contain;
						display: block;
						margin-left: auto;
						margin-right: auto;
						@include props(w,145);
						@include props(h,61);
						@include props(mb,30);
					}
				}

				.companyStoryListAreaDesc{
					@include fz_vw(20);
					font-weight: bold;
					line-height: 2;

					@include mq(md){
						@include fz_vw(34);
						line-height: 1.6;
					}
				}
			}

			@include mq(md){
				.subNavListWrap{
					position: sticky;
					@include props(t,100,750);
					@include props(p,40,750);
					left: 0;
					width: 100%;
					&:before{
						content: "";
						background: #EDE1D9;
						position: absolute;
						top: 0;
						left: 0;
						width: 100vw;
						height: 100%;
						@include props(l,-40,750);
						z-index: 0;
					}

					nav{
						overflow-x: hidden;
					}
					.subNavList{
						overflow-x: hidden;
						display: flex;

						.pageLinkList{
							position: relative;
							z-index: 1;
							display: inline-block;
							@include props(pl,15,750);
							@include props(pr,15,750);

							a{
								@include fz_vw(50);
							}

							&.active{
								a{
									color: $keyColor;
								}
							}
						}
						
					}

					.buttons{
						.button{
							position: absolute;
							top: 50%;
							transform: translate(0,-50%);
							&.prev{
								left: 0;
								background: url(/images/company/company_story_prev_arrow.svg) center center no-repeat;
								background-size: contain;
								@include props(w,20,750);
								@include props(h,40,750);
								
							}
							&.next{
								right: 0;
								background: url(/images/company/company_story_next_arrow.svg) center center no-repeat;
								background-size: contain;
								@include props(w,20,750);
								@include props(h,40,750);
								
							}
						}
					}
				}
			}

			.listWrap{
				position: relative;
				z-index: -10;

				&:before{
					content: "";
					background: #FAF6F4;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%,0);
					width: 100vw;
					height: 100%;
					z-index: -2;
				}
			}

			.aboutStoryListWrap{
				@include props(pt,100);
				@include props(pb,100);
				@include props(pl,140);
				@include props(pr,140);

				@include mq(md){
					z-index: -1;
					@include props(pt,0);
					@include props(pb,100,750);
					@include props(pl,40,750);
					@include props(pr,0);
				}

				.list{
					@include mq(md){
						.monthInner{
							opacity: .3;
							transition: opacity .3s;
						}

						&.current{
							.monthInner{
								opacity: 1;
							}
						}
					}
					&:not(:last-child){
						dl{
							.month{
								@include props(pb,70);

								@include mq(md){
									@include props(pb,60,750);
								}
							}
						}
					}
					&:first-child{
						dl{
							.month{
								@include props(pr,20);
								@include mq(md){
									@include props(pt,70,750);
								}
							}
						}
					}
					&:last-child{
						dl{
							.month{
								@include props(pb,20);
								@include mq(md){
									@include props(pb,90,750);
								}
							}
						}
					}

					&#index4{
						@include mq(pc){
							position: relative;

							&:before{
								content: "";
								background: url(/images/company/company_story_list_icon01.svg) center center no-repeat;
								background-size: contain;
								@include props(w,119);
								@include props(h,98);
								position: absolute;
								@include props(r,-119);
								@include props(t,-98);
							}
							&:after{
								content: "";
								background: url(/images/company/company_story_list_icon02.svg) center center no-repeat;
								background-size: contain;
								@include props(w,114);
								@include props(h,144);
								position: absolute;
								@include props(l,-140);
								@include props(t,120);
							}
						}
					}

					&#index7{
						@include mq(pc){
							position: relative;
							&:before{
								content: "";
								background: url(/images/company/company_story_list_icon03.svg) center center no-repeat;
								background-size: contain;
								@include props(w,101);
								@include props(h,133);
								position: absolute;
								@include props(r,0);
								@include props(b,0);
							}
						}
					}
					dl{
						display: flex;
						align-items: baseline;
						
						.age{
							@include mq(pc){
								width: 8.839%;
								@include fz_vw(30);
								font-weight: bold;
								line-height: 1;
							}
						}

						.month{
							width: 91.161%;
							border-left: 1px solid #707070;
							@include props(pt,20);

							@include mq(md){
								width: 100%;
								@include props(pt,0);
							}

							.monthInner{
								display: flex;
								align-items: flex-start;
								padding-left: 2.52%;
								flex-wrap: wrap;
								position: relative;
								&:not(:nth-of-type(1)){
									@include props(mt,30);
								}

								&:before{
									content: "";
									position: absolute;
									left: 0;
									@include props(t,18);
									transform: translate(-50%,-50%);
									@include props(w,10);
									@include props(h,10);
									background: $keyColor;
									border-radius: 50%;
								}

								@include mq(md){
									@include props(pl,40,750);

									&:not(:nth-of-type(1)){
										@include props(mt,30,750);
									}

									&:before{
										@include props(t,32,750);
										@include props(w,15,750);
										@include props(h,15,750);
									}
								}

								.monthTtl{
									width: 6.53%;
									@include fz_vw(18);
									font-weight: bold;
									line-height: 2;

									@include mq(md){
										@include fz_vw(32);
										width: 100%;
										@include props(mb,30);
									}
								}

								.monthDescWrap{
									width: 93.47%;

									.monthDesc{
										@include fz_vw(18);
										line-height: 2;

										&:not(:nth-of-type(1)){
											@include props(mt,18);
										}
									}

									@include mq(md){
										width: 100%;

										.monthDesc{
											@include fz_vw(32);
											line-height: 1.75;
	
											&:not(:nth-of-type(1)){
												@include props(mt,32,750);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

}
