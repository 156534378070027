@charset "utf-8";
/* ==============================================
お問合せページ
============================================== */

#contactTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
			&:after{
				content: "Contact Us";

				@include mq(md){
					@include props(w,150,750);
				}
			}
		}
	}

	.contactForm{
		@include props(mb,250);

		@include mq(md){
			@include props(mb,250,750);
		}

		.contactFormTit{
			@include fz_vw(40);
			font-weight: bold;
			@include props(mb,100);
			text-align: center;

			@include mq(md){
				@include fz_vw(36);
				@include props(mb,100,750);
			}
		}

		.contactFormTitSub{
			@include fz_vw(30);
			font-weight: bold;
			@include props(mb,100);
			text-align: center;

			@include mq(md){
				@include fz_vw(32);
				@include props(mb,100,750);
			}
		}

		.contactFormTxt{
			@include fz_vw(18);
			line-height: 2.33;
			@include props(mb,100);

			@include mq(md){
				@include fz_vw(32);
				line-height: 1.9;
				@include props(mb,150,750);
			}

			&.centerTxt{
				text-align: center;

				@include mq(md){
					text-align: left;
				}
			}
		}

		.contactFormTxtAttention{
			@include fz_vw(18);
			@include props(mb,60);
			color: $keyColor;
			
			@include mq(md){
				@include fz_vw(32);
				@include props(mb,100,750);
			}
		}

		.attention{
			color: $keyColor;
			@include fz_vw(16);
			margin-top: .62em;

			@include mq(md){
				@include fz_vw(28);
				padding-left: 1em;
				text-indent: -1em
			}
		}

		.errorTxt{
			@include fz_vw(16);
			color: $keyColor;
			margin-top: .7em;

			@include mq(md){
				@include fz_vw(28);
			}
		}

		dl{
			display: flex;
			flex-wrap: wrap;
			@include props(mb, 100);
	  
			@include mq(md) {
			  display: block;
			  @include props(mb, 120, 750);
			}
		}

		dt{
			width: 19.47%;
			@include fz_vw(18);
			font-weight: bold;
			@include props(mb, 60);
  
			&:first-of-type{
				@include props(mb, 40);
			}

			&:last-of-type{
				margin-bottom: 0;
			}

			label{
				@include fz_vw(18);
				font-weight: bold;
				@include props(pt,18);
			}

			span{
				color: $keyColor;
				@include fz_vw(16);
				padding-left: .5em;
			}
  
			@include mq(md) {
				width: 100%;
				@include fz_vw(32);
				@include props(mb, 30, 750);

				&:first-of-type{
					@include props(mb, 30,750);
				}

				&:last-of-type{
					@include props(mb, 30, 750);
				}

				label{
					@include fz_vw(32);
				}

				span{
					@include fz_vw(32);
				}
			}
		}

		dd{
			width: 80.53%;
			@include fz_vw(18);
			@include props(mb, 60);
  
			&:first-of-type{
				@include props(mb, 40);
			}

			&:last-of-type{
				margin-bottom: 0;
			}
  
			@include mq(md) {
				width: 100%;
				@include fz_vw(32);
				@include props(mb, 80, 750);

				&:first-of-type{
					@include props(mb, 80,750);
				}

				&:last-of-type{
					@include props(mb, 0, 750);
				}
			}
		}

		input,
		textarea{
		  display: block;
		  max-width: 100%;
		  width: 100%;
		  @include fz_vw(18);
		  @include placeholder(#AFB3B7);
		  border: solid 1px #DDDDDD;
		  outline: none;
		  @include props(pt, 18);
		  @include props(pb, 18);
		  @include props(pr, 30);
		  @include props(pl, 30);
		  appearance: none;
	
		  &::placeholder {
			font-weight: normal;
		  }
	
		  &.error {
			background-color: #F7E1E2;
		  }
	
		  @include mq(md) {
			@include fz_vw(32);
			@include props(pt, 25, 750);
			@include props(pb, 25, 750);
			@include props(pr, 40, 750);
			@include props(pl, 40, 750);
		  }
		}

		textarea{
			resize: vertical;
			@include props(h,270);

			@include mq(md){
				@include props(h,550,750);
			}
		}

		
		input[type="radio"],
		input[type="checkbox"] {
		  opacity: 0.01;
		  position: absolute;
		  width: 20px;
		  padding: 9px;
		  margin-top: 2px;
		  &:checked {
			&+span {
			  &::after {
				transition: .3s;
				transform: scale(1);
				background: #4d4d4d;
			  }
			}
			&+.radioTit{
			  &::after {
				transition: .3s;
				transform: scale(1);
				background: #4d4d4d;
			  }
			}
			&+.checkboxTxt{
			  &::after {
				transition: .3s;
				transform: scale(1);
				background: #4d4d4d;
			  }
			}
		  }
		}

		.radioArea{
			display: flex;
			flex-wrap: wrap;
			@include props(pt, 18);
			@include props(pb, 18);
			@include props(pl, 30);
			&.error {
			  background-color: #F7E1E2;
			}

			@include mq(md){
				@include props(p, 10,750);
				label{
					&:nth-of-type(1){
						width: 50%;
					}
	
					&:nth-of-type(2){
						width: 50%;
					}

					&:nth-of-type(3){
						width: 100%;
					}
	
					&:nth-of-type(n + 3){
						@include props(mt,20,750);
					}
				}
			}
		}

		  label{
			display: block;
		  }
		  .radioTit{
			position: relative;
			cursor: pointer;
			padding-left: 35px;
			@include props(mr,50);
			
			@include mq(tab){
				padding-left: 28px;
			}
			
			@include mq(md){
			  @include props(pl, 50, 750);
			  @include props(mr,0);
			}
	  
			&::before {
			  content: '';
			  position: absolute;
			  top: 0;
			  bottom: 0;
			  left: 0;
			  margin: auto 0;
			  width: 25px;
			  height: 25px;
			  border: 1px solid #707070;
			  background: #fff;
			  border-radius: 50%;
	  
			  @include mq(tab){
				width: 18px;
				height: 18px;
			  }
	  
			  @include mq(md){
				margin: auto 0;
				top: 0;
				bottom: 0;
				@include props(w,40,750);
				@include props(h,40,750);
			  }
			}
			&::after {
			  content: '';
			  position: absolute;
			  top: 0;
			  bottom: 0;
			  left: 4px;
			  margin: auto 0;
			  width: 19px;
			  height: 19px;
			  transform: scale(.5);
			  border-radius: 50%;
	  
			  @include mq(tab){
				width: 12px;
				height: 12px;
			  }
	  
			  @include mq(md){
				margin: auto 0;
				top: 0;
				bottom: 0;
				@include props(w,32,750);
				@include props(h,32,750);
				@include props(l,6,750);
			  }
			}
		}

		.privacyPolicy{
			text-align: center;
			@include props(mb,100);

			@include mq(md){
				text-align: left;
				@include props(mb,150,750);
			}

			.checkboxArea {
				label {
				  align-items: center;
				  position: relative;
		
				  &:not(:last-of-type) {
					@include props(mb, 30);
		
					@include mq(md) {
					  @include props(mb, 40, 750);
					}
				}
		
				span:not([class]) {
					flex-shrink: 0;
					position: relative;
					@include fz_vw(18);
					font-weight: bold;
					cursor: pointer;
					padding-left: 30px;
		
					@include mq(md) {
					  @include fz_vw(32);
					  padding-left: 30px;
					}
		
					&::before {
					  content: '';
					  position: absolute;
					  top: 0;
					  bottom: 0;
					  left: 0;
					  margin: auto 0;
					  width: 16px;
					  height: 16px;
					  border: 1px solid #95989A;
					  background: #fff;

					  @include mq(md) {
						width: 20px;
						height: 20px;
					  }
					}
					&::after {
					  content: '';
					  position: absolute;
					  top: 0;
					  bottom: 0;
					  left: 3px;
					  margin: auto 0;
					  width: 12px;
					  height: 12px;
					  transform: scale(.5);
					  z-index: 1;
					  border: none;
					  appearance: none;
		
					  @include mq(md) {
						width: 16px;
						height: 16px;
					  }
					}

					a{
						@include fz_vw(18);
						text-decoration: underline;

						@include mq(md) {
							@include fz_vw(32);
						}
					}
				}

				.stamp{
					@include props(mt,30);
					@include fz_vw(18);

					@include mq(md){
						@include props(mt,40,750);
						@include fz_vw(28);
					}
				}
			}

			input[type="checkbox"] {

				opacity: 0.01;
				position: absolute;
				width: 20px;
				padding: 9px;
				margin-top: 2px;
				&:checked {
					&+span {
					  &::after {
						transition: .3s;
						transform: scale(1);
						background: #333;
					  }
					}
				  }
				}
			}
		}

		&.contactFormConf{
			dl{
				dt{
					&:nth-of-type(1){
						@include mq(pc){
							@include props(mb,60);
						}
					}
				}
				dd{
					&:nth-of-type(1){
						@include mq(pc){
							@include props(mb,60);
						}
					}
				}
			}
		}
	}



	//入力ページのボタン
	.sendBtn{
		@include props(w,350);
		margin: 0 auto;

		button{
			display: block;
			width: 100%;
			height: 100%;
			@include fz_vw(18);
			line-height: 1;
			@include props(pt,26);
			@include props(pb,26);
			background: #484848;
			color: $basicWhiteColor;
			border: 1px solid #484848;
			transition: all .5s;

			&:disabled{
				background: #ccc !important;
				color: $basicWhiteColor !important;
				border: 1px solid #ccc !important;
			}

			&:hover{
				cursor: pointer;
				background: $basicWhiteColor;
				color: $basicColor;
				border: 1px solid $basicColor;
			}
		}

		@include mq(md){
			@include props(w,540,750);

			button{
				font-weight: bold;
				@include fz_vw(28);
				@include props(pt,36,750);
				@include props(pb,36,750);

				&:hover{
					cursor: pointer;
				 }
			}
		}
	}

	//確認ページのボタン

	.contactConfirmBtn{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;

		@include mq(md){
			justify-content: space-between;
		}

		li{
			@include props(w,350);
			@include props(ml,40);
			@include props(mr,40);

			@include mq(md){
				@include props(w,320,750);
				@include props(ml,0);
				@include props(mr,0);
			}

			button,a{
				display: block;
				width: 100%;
				height: 100%;
				text-align: center;
				@include props(pt,26);
				@include props(pb,26);
				@include fz_vw(18);
				line-height: 1;
				transition: all .5s;

				@include mq(md){
					@include props(pt,36,750);
					@include props(pb,36,750);
					@include fz_vw(28);
				}
			}

			&.contactConfirmBtnBack{
				a{
					color: $basicWhiteColor;
					background: #9A9A9A;
					border: 1px solid #9A9A9A;

					&:hover{
						color: $basicColor;
						background: $basicWhiteColor;
						border: 1px solid $basicColor;
					}
				}
			}

			&.contactConfirmBtnSubmit{
				button{
					border: 1px solid #484848;
					color: $basicWhiteColor;
					background: #484848;

					 &:hover{
						cursor: pointer;
						color: $basicColor;
						background: $basicWhiteColor;
						border: 1px solid $basicColor;
					 }
				}
			}
		}
	}


	//完了ページ
	.btn01{
		text-align: center;
	}


	//お電話によるお問い合わせ
	.telContactArea{
		background: #FAF6F4;
		@include props(btlr,80);
		@include props(bbrr,80);
		@include props(pt,80);
		@include props(pb,90);
		@include props(pl,80);
		@include props(pr,80);
		@include props(mt,200);

		@include mq(md){
			@include props(btlr,80,750);
			@include props(bbrr,80,750);
			@include props(pt,80,750);
			@include props(pb,80,750);
			@include props(pl,40,750);
			@include props(pr,40,750);
			@include props(mt,150,750);
		}

		.telContactAreaTtl{
			text-align: center;
			@include fz_vw(40);
			font-weight: bold;
			@include props(mb,60);

			@include mq(md){
				@include fz_vw(36);
				@include props(mb,80,750);
			}
		}

		.conInner{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: center;

			.tel{
				width: 50%;
				@include props(pr,45);
				text-align: right;
				a{
					@include fz_vw(40);
					font-weight: bold;
					&:before{
						content: "";
						background: url(/images/contact/contact_tel_icon.svg) center center no-repeat;
						background-size: contain;
						width: .875em;
						height: .875em;
						display: inline-block;
						margin-right: .375em;
					}
				}
				@include mq(pc){
					a[href^="tel:"] {
						pointer-events: none;
					}
				}

				@include mq(md){
					width: 100%;
					padding-right: 0;
					text-align: center;
					@include props(mb,80,750);

					a{
						@include fz_vw(48);
					}
				}
			}

			.info{
				width: 50%;
				@include props(pl,45);
				border-left: 1px solid $basicColor;

				@include mq(md){
					width: inherit;
					padding-left: 0;
					border-left: none;
					display: inline-block;
				}
				.list{
					display: flex;

					.ttl{
						width: 6.5em;
						@include fz_vw(18);
						display: flex;
						justify-content: space-between;

						span{
							@include fz_vw(18);
						}
					}

					.desc{
						@include fz_vw(18);
					}

					@include mq(md){
						.ttl{
							@include fz_vw(32);
							span{
								@include fz_vw(32);
							}
						}

						.desc{
							@include fz_vw(32);
						}
					}
				}
			}
		}
	}
}