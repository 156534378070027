@charset "utf-8";
/* ==============================================
NEWSページ
============================================== */

#aboutTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
		  &:after{
			content: "About Us";
		  }
		}
	}

	.aboutDescArea{
		@include props(mb,150);
		@include mq(md){
			@include props(mb,200,750);
		}
		.flex01{
			position: relative;

			@include mq(md){
				@include props(mb,60,750);
			}

			.companyPhilosophyTtlWrap{
				display: flex;
				justify-content: flex-end;

				.companyPhilosophyTtl{
					@include fz_vw(40);
					font-weight: bold;
					writing-mode: vertical-rl;
					display: flex;
					align-items: center;
					@include props(h,300);
		  
					@include mq(md){
					  @include fz_vw(40);
					  @include props(h,560,750);
					  @include props(mr,65,750);
					  &:after{
						@include fz_vw(24);
						@include props(mt,30,750);
					  }
					}
		  
					&:after{
					  content: "Philosophy";
					  @include fz_vw(20);
					  font-weight: bold;
					  color: $keyColor;
					  writing-mode: horizontal-tb;
					  @include props(mt,30);
					}
				}
			}
	
			.txt01{
				@include props(pb,120);
				text-align: right;
				@include props(w,711);
				margin-left: auto;
				@include props(mr,130);
				@include props(mt,-230);
				@include mq(md){
					text-align: left;
					width: 100%;
					max-width: inherit;
					margin-left: 0;
					margin-right: 0;
					@include props(pb,0,750);
					@include props(mt,90,750);
				}
	
				.txtInner{
					display: inline-block;
					text-align: left;
					display: flex;
					flex-direction: column;
				}
				.logo{
					@include props(w,231);

					@include mq(md){
						@include props(w,270,750);
						margin: 0 auto;
						order: 2;
						@include props(mb,70,750);
					}
				}
	
				.aboutDescAreaTtl{
					@include fz_vw(44);
					font-weight: bold;
					line-height: 2;
					@include props(mt,90);
					@include props(mb,50);
					letter-spacing: .1em;

					@include mq(md){
						@include fz_vw(40);
						text-align: center;
						line-height: 1.6;
						@include props(mt,0,750);
						@include props(mb,40,750);
						order: 1;
					}
				}
	
				.aboutDescAreaRead{
					@include fz_vw(22);
					line-height: 2.25;
					order: 3;
					letter-spacing: .08em;

					&:nth-of-type(2){
						@include props(mt,40);
						order: 4;
					}

					@include mq(md){
						@include fz_vw(32);
						line-height: 1.9;
						letter-spacing: .07em;

						&:nth-of-type(2){
							@include props(mt,50,750);
						}
					}
				}
			}

			.img01{
				position: absolute;
				top: 0;
				left: 0;
				width: 33.33vw;
				@include props(h,770);
				@include props(btrr,80);
	
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					@include props(btrr,80);
				}

				@include mq(md){
					@include props(w,418,750);
					@include props(h,561,750);
					@include props(btrr,80,750);

					img{
						@include props(btrr,80,750);
					}
				}
			}
		}

		.flex02{
			position: relative;

			@include mq(pc){
				@include props(pb,100);
			}
		
			.txt02{
				@include props(w,637);
				@include props(ml,110);

				.aboutDescAreaRead{
					@include fz_vw(22);
					line-height: 2.25;
					letter-spacing: .045em;

					&:not(:nth-of-type(1)){
						@include props(mt,40);
					}
				}

				@include mq(md){
					@include props(ml,0);
					width: 100%;
					max-width: inherit;
					.aboutDescAreaRead{
						@include fz_vw(32);
						line-height: 1.9;
						letter-spacing: .07em;
	
						&:not(:nth-of-type(1)){
							@include props(mt,60,750);
						}
					}
				}
			}

			.img02{
				position: absolute;
				bottom: 0;
				right: 0;
				width: 38.22vw;
				@include props(btlr,80);

				@include mq(pc){
					@include props(h,440);
				}
	
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					@include props(btlr,80);
				}

				@include mq(md){
					position: relative;
					@include props(mt,80,750);
					@include props(w,710,750);
					@include props(btlr,80,750);
					margin-left: auto;

					img{
						@include props(btlr,80,750);
					}
				}
			}
		}
	}

	.aboutKnowledge{
		@include props(mb,300);
		overflow-x: hidden;
		@include mq(md){
			@include props(mb,200,750);
		}
		
		.aboutKnowledgeTtl{
			writing-mode: vertical-rl;
			text-orientation: upright;
			@include fz_vw(26);
			font-weight: bold;
			@include props(pl,60);
			@include props(pr,60);
			position: relative;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			@include mq(md){
				writing-mode: horizontal-tb;
				text-orientation: inherit;
				display: inline-block;
				@include fz_vw(40);
				@include props(pl,40,750);
				@include props(pr,80,750);
			}

			&:before{
				content: "";
				position: absolute;
				@include props(t,125);
				left: 0;
				width: 100vw;
				height: 1px;
				background: $keyColor;
				transform-origin: top left;
				transform: scaleX(-1);

				@include mq(md){
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
			}

			&:after{
				content: "";
				position: absolute;
				@include props(t,125);
				right: 0;
				@include props(w,480);
				height: 1px;
				background: $keyColor;
				transform-origin: top right;
				transform: scaleX(-1);

				@include mq(md){
					width: 100vw;
					max-width: 100vw;
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
			}
			
			span{
				display: block;
				@include fz_vw(30);
				@include props(mt,50);
				position: relative;
				&:before{
					content: "“";
					position: absolute;
					top: -.3em;
					right: -.3em;
				}
				&:after{
					content: "”";
					position: absolute;
					bottom: -1em;
					left: -.3em;
				}

				@include mq(md){
					@include fz_vw(40);
					@include props(mt,0);
					@include props(ml,50,750);

					&:before{
						content: "“";
						position: relative;
						top: inherit;
						right: inherit;
					}
					&:after{
						content: "”";
						position: relative;
						bottom: inherit;
						left: inherit;
					}
				}
			}
		}

		.aboutKnowledgeTxt{
			@include props(w,912);
			@include props(ml,300);
			@include props(mt,-70);
			@include fz_vw(18);
			line-height: 2.33;
			display: inline-block;
			vertical-align: top;

			@include mq(md){
				display: block;
				width: 100%;
				max-width: 100%;
				@include props(ml,0);
				@include props(mt,80,750);
				@include fz_vw(32);
				line-height: 1.75;
			}
		}

		.slider01{
			@include mq(pc){
				@include props(mt,300);
				.inner{
					@include props(pl,10);
					@include props(pr,10);

					img{
						width: 100%;
					}
				}
			}
		}

		.sliderArea{
			@include mq(md){
				@include props(mt,200,750);
				.inner{
					@include props(pl,10,750);
					@include props(pr,10,750);

					img{
						width: 100%;
					}
				}

				.slider02{
					@include props(mb,100,750);
				}
			}
		}
	}

	.aboutStory{
		@include props(mb,250);

		@include mq(md){
			@include props(mb,250,750);
		}

		.storyIntro{
			position: relative;
		}

		.aboutStoryTtlArea{
			width: 38.54vw;
			@include props(h,800);
			position: sticky;
			@include props(t,120);
			left: 0;

			@include mq(md){
				width: 100%;
				@include props(h,750,750);
				position: inherit;
				@include props(mb,100,750);
			}

			.aboutStoryTtlAreaInner{
				position: relative;
				height: 100%;
				.aboutStoryTtl{
					position: absolute;
					height: 100%;
					text-align: center;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					writing-mode: vertical-rl;
					text-orientation: upright;
					@include fz_vw(40);
					font-weight: bold;
					color: $basicWhiteColor;

					@include mq(md){
						@include fz_vw(50);
					}
	
					&:after{
						content: "Story";
						letter-spacing: .05em;
						display: inline-block;
						text-align: center;
						writing-mode: horizontal-tb;
						text-orientation: unset;
						@include fz_vw(16);
						color: $basicWhiteColor;
						@include props(mt,20);
					
						@include mq(md){
							@include fz_vw(24);
							@include props(mt,30,750);
						}
					}
				}

				img{
					width: 100%;
					object-fit: cover;
					height: 100%;
				}
			}
		}

		.scrollPoint{
			@include props(w,730);
			@include props(mt,-800);
			margin-left: auto;

			.txt{
				@include fz_vw(18);
				line-height: 2.33;
			}

			@include mq(md){
				width: 100%;
				height: inherit;
				max-height: inherit;
				@include props(mt,0);

				.txt{
					@include fz_vw(32);
					line-height: 1.75;
				}
			}
		}


	}

}
