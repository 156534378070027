@charset "utf-8";
/* ==============================================
NEWSページ
============================================== */

#newsTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
		  &:after{
			content: "News";
		  }
		}
	}
	.newsArea{
		.navArea{
			display: flex;
			align-items: flex-start;
			position: relative;
			flex-wrap: wrap;

			.secSubTit{
				@include fz_vw(22);
				font-weight: bold;
				@include props(pr,50);
				@include props(mr,50);
				border-right: 1px solid $basicColor;

				@include mq(md){
					width: 100%;
					@include props(pr,0);
					@include props(mr,0);
					border-right: none;
					@include fz_vw(36);
					text-align: center;
					@include props(mb,60,750);
				}
			}

			.newsSubNav{
				@include mq(md){
					display: flex;
					justify-content: space-between;
					width: 100%;
				}
				.newsCategoryList{
					display: flex;
			
					@include mq(md) {
					  position: relative;
					  display: block;
					  z-index: 10;
					  @include props(w, 310, 750);
					}
	
					&.listTop{
						li{
							&:not(:last-child){
								@include props(mr,50);

								@include mq(md){
									@include props(mr,0);
								}
							}
	
							a{
								@include fz_vw(20);
								font-weight: bold;
								@include mq(md){
									display: block;
									text-align: center;
									@include fz_vw(32);
									@include props(pt, 16, 750);
									@include props(pb, 16, 750);
									@include props(pr, 40, 750);
									@include props(pl, 40, 750);
								}
							}
						}
					}

					.activeSp{
					  @include mq(md) {
						@include fz_vw(32);
						font-weight: bold;
						text-align: center;
						cursor: pointer;
						border-bottom: 1px solid $basicColor;
						@include props(pt, 3, 750);
						@include props(pb, 7, 750);
			  
						&::after{
						  content: "";
						  display: block;
						  position: absolute;
						  top: 0;
						  bottom: 0;
						  @include props(r, 0, 750);
						  margin: auto;
						  background-image: url(/images/news/news_archives_icon.svg);
						  background-repeat: no-repeat;
						  background-position: center;
						  background-size: contain;
						  @include props(w, 28, 750);
						  @include props(h, 14, 750);
						  transition: .5s;
						}
				
						&.categoryOpen{
						  &::after{
							transform: rotate(180deg);
						  }
						}
					  }
					}
					.newsCategoryListSp{
					  @include mq(md) {
						display: none;
						position: absolute;
						max-width: 100%;
						width: 100%;
						@include props(pt, 20, 750);
						@include props(pb, 20, 750);
						box-shadow: 3px 3px 10px rgba(0,0,0,.2);
						background: rgba(255,255,255,.95);
					  }
					}
				  }
			  
				  .newsArchivesColumn{
					@include props(pr, 165);
			
					@include mq(md) {
					  @include props(pr, 0);
					}
			  
					.newsArchivesList{
					  position: absolute;
					  top: 0;
					  right: 0;
					  z-index: 10;
					  cursor: pointer;
			
					  @include mq(md) {
						position: relative;
						@include props(w , 310 , 750);
					  }
					}
					.archivesBtn{
					  position: relative;
					  cursor: pointer;
					  @include props(pt, 5);
					  @include props(pb, 5);
					  font-weight: bold;
			
					  @include mq(md) {
						border-bottom: solid 1px $basicColor;
						@include props(pt, 5, 750);
						@include props(pb, 5, 750);
					  }
			  
					  &::after{
						content: "";
						display: block;
						position: absolute;
						top: 0;
						bottom: 0;
						@include props(r, 10);
						margin: auto;
						background-image: url(/images/news/news_archives_icon.svg);
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
						@include props(w, 29);
						@include props(h, 14);
						transition: .5s;
			
						@include mq(md) {
						  @include props(r, 0, 750);
						  @include props(w, 28, 750);
						  @include props(h, 14, 750);
						}
					  }
			  
					  &.open{
						&::after{
						  transform: rotate(180deg);
						}
					  }
					}
					.archives{
					  @include fz_vw(20);
					  text-align: center;
					  @include props(pr, 50);
					  @include props(pl, 40);
			
					  @include mq(md) {
						@include fz_vw(32);
						@include props(pr, 0);
						@include props(pl, 0);
					  }
					}
					.archiveEra{
					  display: none;
					  @include props(pt, 10);
					  box-shadow: 3px 3px 10px rgba(0,0,0,.2);
					  background: rgba(255,255,255,.95);
			
					  @include mq(md) {
						position: absolute;
						max-width: 100%;
						width: 100%;
						@include props(pt, 20, 750);
						@include props(pb, 20, 750);
					  }
			  
					  ul{
						
						.archives{
						  @include props(pr, 0);
						  @include props(pl, 0);
						}
						a{
						  display: block;
						  @include fz_vw(18);
						  @include props(pt, 10);
						  @include props(pb, 10);
						  @include props(pr, 40);
						  @include props(pl, 40);
						  transition: background-color .6s;
			
						  @include mq(md) {
							@include fz_vw(32);
							@include props(pt, 16, 750);
							@include props(pb, 16, 750);
							@include props(pr, 40, 750);
							@include props(pl, 40, 750);
						  }
						}
					  }
					}
				}
			}

			&.catePage{
				.secSubTit{
					text-align: left;
				}
				@include mq(md){
					.newsSubNav{
						align-items: center;
						.listTop{
							display: flex;
							width: inherit;
	
	
							li{
								a{
									@include props(p,0);
								}
	
								&:not(:last-child){
									@include props(mr,20,750);
								}
							}
						}
	
						.newsArchivesColumn{
							.newsArchivesList{
								width: inherit;
	
								.archives{
									@include fz_vw(28);
								}
	
								.archivesBtn{
									@include props(pr,60,750);
								}
							}
						}
					}
				}
			}

		}



		.newsAreaList{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mb,50);
			@include props(mt,90);
	  
			@include mq(md){
			  width: 100%;
			  @include props(mt,100,750);
			  @include props(mb,150,750);

			  &:after{
				content: none;
			  }
			}

			&:after{
				content: "";
				display: block;
				width: 30.769%;
			}
	  
			.list{
			  width: 30.769%;
			  @include props(mb,100);
	  
			  @include mq(md){
				width: 100%;
				&:not(:last-child){
				  @include props(mb,80,750);
				}
	
			}

			a{
				@include mq(md){
					display: flex;
					justify-content: space-between;
				}

				.img{
					@include props(mb,30);
					@include props(btlr,30);
					@include props(bbrr,30);
		  
					@include mq(md){
					  @include props(w,306,750);
					  @include props(mb,0,750);
					  @include props(btlr,30,750);
					  @include props(bbrr,30,750);
					}

					.imgWrap{
						@include props(btlr,30);
						@include props(bbrr,30);
						position: relative;
						padding-top: 66.66%;

						img{
							@include props(btlr,30);
							@include props(bbrr,30);
							width: 100%;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						@include mq(md){
							@include props(btlr,30,750);
							@include props(bbrr,30,750);

							img{
								@include props(btlr,30,750);
								@include props(bbrr,30,750);
							}
						}
					}
				}

				.txt{
					@include mq(md){
						@include props(w,320,750);
					}

					.indexNewsInfoArea{
						display: flex;
						align-items: center;

						@include mq(md){
							justify-content: space-between;
							align-items: flex-start;
						}
						
						time{
						  @include fz_vw(16);
						  line-height: 1;
						  @include mq(md){
							@include fz_vw(28);
						  }
						}
			  
						.indexNewsCat{
						  display: flex;
						  margin-left: auto;
						  flex-wrap: wrap;
						  
						  span{
							letter-spacing: 0;
							@include props(w,90);
							text-align: center;
							display: inline-block;
							color: $basicWhiteColor;
							background: #f08707;
							font-weight: bold;
							@include fz_vw(14);
							line-height: 1;
							@include props(p,4);
							&:nth-of-type(n + 2){
								@include props(ml,10);
							}
			  
							@include mq(md){
							  @include props(w,150,750);
							  @include fz_vw(28);
							  @include props(p,2,750);
							  letter-spacing: -.1em;
							  margin-left: auto;
		
							  &:nth-of-type(n + 2){
								margin-left: auto;
								@include props(mt,10,750);
							  }
							}
						  }
						}
					  }
					  
					  .indexNewsPostTtl{
						@include props(mt,10);
						@include fz_vw(16);
						line-height: 2;
			  
						@include mq(md){
						  @include props(mt,25,750);
						  @include fz_vw(28);
						  line-height: 1.7;
						}
					  }
				}
			}
			}
		}
	}
}

/* ==============================================
NEWS詳細ページ
============================================== */
#newsSingle{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.newsMainVisual{
		position: relative;
		@include props(h,350);
		@include props(mb,100);

		@include mq(md){
			@include props(h,310,750);
			@include props(mb,100,750);
		}

		.img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&.imgFilter{
				&::before{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					background:rgba(255,255,255,.7);
					top: 0;
					left: 0;
					z-index: 0;
				}
			}

			@include mq(md){
				@include props(h,280,750);
				top: inherit;
				bottom: 0;
			}
		}
		.mainVisualTxt{
			height: 100%;
			@include props(pt,60);

			@include mq(md){
				@include props(pt,0,750);
			}
	  
			.mainVisualTxtTtl{
			  display: inline;
			  @include fz_vw(50);
			  font-weight: bold;
			  writing-mode: vertical-rl;
			  text-orientation: upright;
			  display: flex;
			  align-items: center;
			  letter-spacing: .07em;
			  height: 100%;
	  
			  @include mq(md){
				@include fz_vw(50);
				@include props(ml,40,750);
			  }
		
			  &:after{
				content: "News";
				display: inline-block;
				text-align: center;
				writing-mode: horizontal-tb;
				text-orientation: unset;
				@include fz_vw(16);
				color: $keyColor;
				@include props(mt,20);
				letter-spacing: .05em;
	  
				@include mq(md){
				  @include fz_vw(26);
				  @include props(mt,30,750);
				}
			  }
			}
		}
	}
	.newsArea{
		@include props(mb,250);
		@include mq(md){
			@include props(mb,250,750);
		}
		.postTtlContent{
			@include props(mb,80);

			@include mq(md){
				@include props(mb,30,750);
			}
			
			.postInfo{
				display: flex;
				justify-content: flex-start;
				time{
					@include fz_vw(20);
					@include mq(md){
						@include fz_vw(32);
					}
				}

				.indexNewsCat{
					display: inline-block;
					@include props(ml,45);
					display: flex;
					align-items: center;

					@include mq(md){
						@include props(ml,30,750);
					}

					span{
						letter-spacing: 0;
						@include props(w,90);
						text-align: center;
						display: inline-block;
						color: $basicWhiteColor;
						background: #f08707;
						font-weight: bold;
						@include fz_vw(14);
						line-height: 1;
						@include props(p,4);
						&:nth-of-type(n + 2){
							@include props(ml,10);
						}
		  
						@include mq(md){
						  @include props(w,150,750);
						  @include fz_vw(28);
						  @include props(p,2);
						}
					}
				}
			}

			.indexNewsPostTtl{
				@include props(mt,20);
				@include fz_vw(40);
				font-weight: bold;
				line-height: 1.7;

				@include mq(md){
					@include props(mt,30,750);
					@include fz_vw(40);
				}
			}
		}

		.postContent{
			max-width: 1024px;
			margin: 0 auto;

			.postEyeCatch{
				@include props(mb,80);
				img{
					width: 100%;
				}

				@include mq(md){
					@include props(mb,50,750);
				}
			}

			h2{
				@include fz_vw(30);
				font-weight: bold;
				@include props(mb,40);

				@include mq(md){
					@include fz_vw(36);
					@include props(mb,50,750);
				}
			}

			p{
				@include fz_vw(18);
				line-height: 2;

				@include mq(md){
					@include fz_vw(32);
				}

				em{
					font-style: italic;
				}

				a{
					text-decoration: underline;
				}

				.aligncenter{
					margin: 0 auto;
					text-align: center;
				}

				.alignright{
					margin: 0 0 0 auto;
					text-align: right;
				}

				.alignleft{
					margin: 0 auto 0 0;
					text-align: left;
				}
			}

			h3{
				@include fz_vw(26);
				font-weight: bold;
				@include props(mb,40);

				@include mq(md){
					@include fz_vw(32);
					@include props(mb,40,750);
				}
			}

			h4{
				@include props(pl,45);
				@include fz_vw(20);
				color: $keyColor;
				font-weight: bold;
				@include props(mb,40);
				position: relative;

				&:before{
					content: "";
					@include props(w,30);
					height: 1px;
					background: $keyColor;
					position: absolute;
					top: .75em;
					left: 0;
				}

				@include mq(md){
					@include props(pl,60,750);
					@include fz_vw(32);
					@include props(mb,40,750);

					&:before{
						@include props(w,40,750);
					}
				}
			}

			ul{
				li{
					@include fz_vw(18);
					line-height: 2;
					position: relative;
					padding-left: 1em;

					&:before{
						content: "";
						width: .3em;
						height: .3em;
						border-radius: 50%;
						background: $basicColor;
						position: absolute;
						top: .75em;
						left: 0;
					}

					@include mq(md){
						@include fz_vw(32);
					}
				}
			}

			ol{
				counter-reset: count 0;
				li{
					@include fz_vw(18);
					line-height: 2;
					position: relative;
					padding-left: 1em;
					&:before{
						content: counter(count) ". ";
						counter-increment: count 1;
						position: absolute;
						top: 0;
						left: 0
					}

					@include mq(md){
						@include fz_vw(32);
					}
				}
			}

			table{
				border-left: 1px solid #b7b7b7;
				border-top: 1px solid #b7b7b7;

				td,th{
					border-right: 1px solid #b7b7b7;
					border-bottom: 1px solid #b7b7b7;
					padding: .27777em;
					@include fz_vw(18);
					line-height: 2;

					@include mq(md){
						@include fz_vw(32);
					}
				}
			}

			.personArea{
				@include props(p,60);
				@include props(mt,100);
				border: 1px solid #707070;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				flex-wrap: wrap;

				.img{
					width: 27.65%;
					@include props(btrr,40);

					img{
						@include props(btrr,40);
					}
				}

				.personName{
					@include fz_vw(26);
					font-weight: bold;
					@include props(mb,30);
				}

				.txt{
					width: 65.7%;

					.personDesc{
						p{
							@include fz_vw(18);
							line-height: 2;
			
							@include mq(md){
								@include fz_vw(28);
							}
			
							em{
								font-style: italic;
							}
			
							a{
								text-decoration: underline;
							}
			
							.aligncenter{
								margin: 0 auto;
								text-align: center;
							}
			
							.alignright{
								margin: 0 0 0 auto;
								text-align: right;
							}
			
							.alignleft{
								margin: 0 auto 0 0;
								text-align: left;
							}
						}
			
						ul{
							li{
								@include fz_vw(18);
								line-height: 2;
								position: relative;
								padding-left: 1em;
			
								&:before{
									content: "";
									width: .3em;
									height: .3em;
									border-radius: 50%;
									background: $basicColor;
									position: absolute;
									top: .75em;
									left: 0;
								}
			
								@include mq(md){
									@include fz_vw(28);
								}
							}
						}
			
						ol{
							counter-reset: count 0;
							li{
								@include fz_vw(18);
								line-height: 2;
								position: relative;
								padding-left: 1em;
								&:before{
									content: counter(count) ". ";
									counter-increment: count 1;
									position: absolute;
									top: 0;
									left: 0
								}
			
								@include mq(md){
									@include fz_vw(28);
								}
							}
						}
					}
				}

				@include mq(md){
					@include props(mt,100,750);
					@include props(pt,60,750);
					@include props(pb,60,750);
					@include props(pl,40,750);
					@include props(pr,40,750);
					align-items: center;

					.img{
						@include props(w,250,750);
						@include props(btrr,40,750);

						img{
							@include props(btrr,40,750);
						}
					}

					.personName{
						@include fz_vw(32);
						@include props(w,300,750);
					}

					.txt{
						width: 100%;
						@include props(mt,40,750);
					}
				}
			}
		}

		.pageLink{
			border-top: 1px solid #B7B7B7;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			@include props(mt,200);
			@include props(mb,100);
			@include props(pt,150);

			@include mq(md){
				@include props(pt,150,750);
				@include props(mt,150,750);
				@include props(mb,150,750);
			}

			li{
				@include props(w,150);
				@include props(ml,105);
				@include props(mr,105);

				@include mq(md){
					@include props(w,230,750);

					&.prevLink{
						@include props(mr,40,750);
					}

					&.nextLink{
						@include props(ml,40,750);
					}
				}

				&.prevLink{
					text-align: right;
					a{
						&:before{
							background: url(/images/common/single_arrow_prev.svg) center center no-repeat;
							background-size: contain;

							@include mq(md){
								background: url(/images/common/single_arrow_prev_sp.svg) center center no-repeat;
								background-size: contain;
							}
						}
					}
				}

				&.nextLink{
					text-align: left;

					a{
						&:before{
							background: url(/images/common/single_arrow_next.svg) center center no-repeat;
							background-size: contain;

							@include mq(md){
								background: url(/images/common/single_arrow_next_sp.svg) center center no-repeat;
								background-size: contain;
							}
						}
					}
				}

				a{
					display: block;
					width: 100%;
					@include fz_vw(16);
					position: relative;
					padding-bottom: 1.25em;
					line-height: 1;

					@include mq(md){
						@include fz_vw(32);
					}
					&:before{
						content: "";
						width: 100%;
						height: .6em;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0
					}
				}
			}
		}
	}
}