@charset "utf-8";
/* ==============================================
recipeページ
============================================== */

#recipeTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.recipeFilterArea{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		position: relative;
		flex-wrap: wrap;
		&:not(.filterResult){
			@include props(pb,30);
			border-bottom: 1px solid $basicColor;

			@include mq(md){
				@include props(pb,0);
				border-bottom: none;
			}
		}

		.searchResult{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			.searchResultTtl{
				@include fz_vw(28);
				font-weight: bold;

				@include mq(md){
					@include fz_vw(36);
				}
			}

			span{
				@include fz_vw(24);

				&:not(.num){
					margin-left: 1.2em;
					margin-right: 2em;
				}

				@include mq(md){
					@include fz_vw(32);
				}
			}
		}
		
		.recipeNav{
			@include mq(md){
				transition: all .3s ease-in-out;
				&.searchOn{
					opacity: 0.01;
					visibility: hidden;
				}
			}
			.recipeCategoryColumn{
				.recipeCategoryList{
					display: flex;

					@include mq(md) {
						position: relative;
						display: block;
						z-index: 10;
						@include props(w, 390, 750);
					}

					.category{
						@include props(pl,10);
						@include mq(pc){
							display: flex;
						}

						&.active{
							writing-mode: vertical-rl;
							text-orientation: upright;
							position: relative;
							&:before{
								content: "";
								background: url(/images/recipe/recipe_current_icon.svg) center center no-repeat;
								background-size: contain;
								@include props(w,56);
								@include props(h,23);
								position: absolute;
								top: 0;
								left: 0;
								transform: translate(0%,-100%);
							}

							@include mq(md){
								writing-mode: horizontal-tb;
								text-orientation: inherit;
								@include props(pb,10,750);
								border-bottom: 1px solid $basicColor;
								&:before{
									@include props(w,103,750);
									@include props(h,43,750);
									transform: none;
								}

								&:after{
									content: "";
									display: block;
									position: absolute;
									top: 0;
									bottom: 0;
									@include props(r, 0, 750);
									margin: auto;
									background-image: url(/images/news/news_archives_icon.svg);
									background-repeat: no-repeat;
									background-position: center;
									background-size: contain;
									@include props(w, 28, 750);
									@include props(h, 14, 750);
									transition: .5s;
								}

								&.categoryOpen{
									&::after{
									  transform: rotate(180deg);
									}
								  }
							}
							.activeTxt{
								color: $keyColor;
								@include fz_vw(22);
								@include props(pt,0);
								text-align: center;
								font-weight: bold;

								@include mq(pc){
									@include props(pt,15);
								}

								@include mq(md){
									display: block;
									width: 100%;
									@include fz_vw(36);
									@include props(pt,15);
								}
							}
						}
					}

					.recipeCategoryListSp{
						.listTop{
							li{
								@include props(ml,50);
								writing-mode: vertical-rl;
								text-orientation: upright;

								a{
									display: block;
									width: 100%;
									font-weight: bold;
									@include fz_vw(22);
									@include props(pt,15);
								}
							}
						}

						@include mq(md) {
							display: none;
							position: absolute;
							max-width: 100%;
							width: 100%;
							left: 0;
							width: 100%;
							@include props(pt, 20, 750);
							@include props(pb, 20, 750);
							z-index: 1;
							box-shadow: 3px 3px 10px rgba(0,0,0,.2);
							background: rgba(255,255,255,.95);

							.listTop{
								li{
									width: 100%;
									margin: 0;
									writing-mode: horizontal-tb;
									text-orientation: inherit;

									a{
										display: block;
										text-align: center;
										@include props(pt, 16, 750);
										@include props(pb, 16, 750);
										@include props(pr, 40, 750);
										@include props(pl, 40, 750);
										@include fz_vw(36);
									}
								}
							}
						}

						&.catePage{
							@include mq(pc){
								.listTop{
									li{
										&:first-child{
											@include props(ml,10);
										}
	
										&.current{
											a{
												color: $keyColor;
											}
											position: relative;
											&:before{
												content: "";
												background: url(/images/recipe/recipe_current_icon.svg) center center no-repeat;
												background-size: contain;
												@include props(w,56);
												@include props(h,23);
												position: absolute;
												top: 0;
												left: 50%;
												transform: translate(-50%,-100%);
											}
										}
									}
								}
							}

							@include mq(md){
								.listTop{
									li{
										&.current{
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		#searchWrap{
			text-align: center;
			@include props(bw,3);
			border-color: rgba($color: $basicWhiteColor, $alpha: .4);
			transition: border-color .5s;
			border-style: solid;
			border-radius: 100px;
			&:hover{
				cursor: pointer;
			}

			@include mq(md){
				@include props(bw,5,750);
			}

			&.searchOn{
				border-color: rgba($color: $keyColor, $alpha: .4);
			}

			&:not(.searchResultFilter){
				position: absolute;
				@include props(b,40);
				right: 0;

				@include mq(md){
					bottom: inherit;
					top: 0;
				}
			}

			&.searchResultFilter{
				margin-left: auto;

				@include mq(md){
					@include props(mt,80,750);
					.box{
						@include props(w,670,750);

						#searchSubmit{
							@include props(pr,25,750);
							@include props(pl,25,750);
						}
						#contentsSearch{
							flex: 1;
							@include props(pl,10,750);
							@include props(pr,10,750);
						}
					}
				}
			}

			.box {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				border: 1px solid $basicColor;
				@include props(pl,20);
				@include props(pr,20);
				@include props(pt,7);
				@include props(pb,7);
				border-radius: 100px;
				@include fz_vw(16);
				@include props(w,116);
				transition: all .3s ease-in-out;
				@include mq(md){
					@include props(w,220,750);
					@include props(pl,40,750);
					@include props(pr,40,750);
					@include props(pt,14,750);
					@include props(pb,14,750);
					@include fz_vw(32);
				}
				&:before{
					content: "";
					background: url(/images/recipe/recipe_search_icon.svg) center center no-repeat;
					background-size: contain;
					width: 1.25em;
					height: 1.25em;
					display: inline-block;
				}
	
				#contentsSearch{
					outline: none;
					border: none;
					transition: all 0.3s ease-in-out;
					display: inline-block;
					width: 0;
					transition: all .3s ease-in-out;
				}
	
				#searchSubmit{
					border: none;
					outline: none;
					background: $basicWhiteColor;
					line-height: 1;
					display: inline-block;
					@include props(pt,9);
					@include props(pb,9);
					@include props(pr,6);
					@include props(pl,6);
					border-radius: 100px;
					transition: all .3s ease-in-out;
					@include fz_vw(16);
					color: $basicColor;

					@include mq(md){
						@include props(pt,8,750);
						@include props(pb,8,750);
						@include props(pr,18,750);
						@include props(pl,18,750);
						@include fz_vw(32);
					}

					&:hover{
						cursor: pointer;
					}
				}
				&.searchOn {
					@include props(w,505);

					@include mq(md){
						@include props(w,670,750);
					}
					#searchSubmit{
						background: $keyColor;
						color: $basicWhiteColor;
						@include props(pr,12);
						@include props(pl,12);

						@include mq(md){
							@include props(pr,25,750);
							@include props(pl,25,750);
						}
					}
					#contentsSearch{
						@include props(pl,10);
						@include props(pr,10);
						flex: 1;

						@include mq(md){
							@include props(pl,10,750);
							@include props(pr,10,750);
						}
					}
				}
			}
		}
	}


	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
		  &:after{
			content: "Recipe";
		  }
		}
	}

	.recipeArea{
		.recipeAreaList{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mb,90);
			@include props(mt,90);
	  
			@include mq(md){
			  width: 100%;
			  @include props(mt,100,750);
			  @include props(mb,150,750);

			  &:after{
				content: none;
			  }
			}

			&:after{
				content: "";
				display: block;
				width: 30.769%;
			}
	  
			.list{
			  width: 30.769%;
			  @include props(mb,100);
	  
			  @include mq(md){
				width: 100%;
				&:not(:last-child){
				  @include props(mb,100,750);
				}
	
			  }
	  
			  a{
				&:hover{
					img{
						transform: scale(1.1);
					}
					.recipeSubName,.recipeName{
						opacity: .6;
					}
				}
			  }
			  .img{
				@include props(mb,30);
				position: relative;
				@include props(btlr,30);
				@include props(bbrr,30);
				padding-top: 66.666%;
	  
				@include mq(md){
				  @include props(mb,40,750);
				  @include props(btlr,60,750);
				  @include props(bbrr,60,750);
				}

				img{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: all .8s;
					@include props(btlr,30);
					@include mq(md){
						@include props(btlr,60,750);
						@include props(bbrr,60,750);
					  }
				}

				  .recipeCate{
					position: absolute;
					@include props(t,10);
					@include props(r,10);
					text-align: center;

					@include mq(md){
						@include props(t,10,750);
						@include props(r,10,750);
					}

					span{
						@include props(w,120);
						@include fz_vw(18);
						display: inline-block;
						position: relative;
						z-index: 1;
						font-weight: bold;
						&:before{
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(255,255,255,.85);
							z-index: -1;
						}

						@include mq(md){
							@include props(w,190,750);
							@include fz_vw(28);
						}
					}
				  }
			  }

			  .recipeSubName{
				@include fz_vw(20);
				@include props(mb,10);
				transition: all .3s;

				@include mq(md){
					@include fz_vw(30);
					@include props(mb,10,750);
				}
			  }
			  .recipeName{
				@include props(mb,20);
				@include fz_vw(24);
				font-weight: bold;
				transition: all .3s;
	  
				@include mq(md){
				  @include props(mb,25,750);
				  @include fz_vw(36);
				}
			  }
			}
		}

		.notCon{
			@include props(mt,100);
			@include props(mb,250);
			width: 100%;
			&:before{
				content: "";
				background: url(/images/recipe/recipe_not_con_icon.svg) center center no-repeat;
				background-size: contain;
				display: block;
				@include props(w,200);
				@include props(h,177);
				margin: 0 auto;
			}

			@include mq(md){
				@include props(mt,150,750);
				@include props(mb,150,750);

				&:before{
					@include props(w,200,750);
					@include props(h,177,750);
				}
			}
			.notTopics{
				text-align: center;
				margin-left: auto;
				margin-right: auto;
				@include props(mb,70);
				@include props(mt,50);
				@include fz_vw(24);
				line-height: 2;

				@include mq(md){
					@include props(mb,70,750);
					@include props(mt,50,750);
					@include fz_vw(28);
				}
			}
			.notConListTtl{
				@include fz_vw(30);
				font-weight: bold;
				@include props(mb,40);
				@include props(mt,100);

				@include mq(md){
					@include fz_vw(40);
					@include props(mb,40,750);
					@include props(mt,100,750);
				}
			}

			.itemRecommendList{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				&:after{
					content: "";
					width: 30.769%;
					display: block;

					@include mq(md){
						content: none;
					}
				}

				.list{
					width: 30.769%;

					@include mq(md){
						width: 100%;
						&:not(:last-child){
							@include props(mb,50,750);
						}
						a{
							display: flex;
							justify-content: space-between;
						}
					}

					a{
						&:hover{
							img{
								transform: scale(1.1);
							}
							.recipeSubName,.recipeName{
								opacity: .6;
							}
						}
					}

					.img{
						position: relative;
						@include props(mb,30);
						position: relative;
						padding-top: 66.666%;
						overflow: hidden;

						@include mq(md){
							width: 46.2%;
							@include props(mb,0);
							padding-top: 30.89%;
						}

						img{
							position: absolute;
							transition: all .8s;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						@include mq(pc){
							.recipeCate{
								position: absolute;
								@include props(t,10);
								@include props(r,10);
				
								span{
									display: flex;
									align-items: center;
									justify-content: center;
									letter-spacing: 0;
									@include props(w,120);
									text-align: center;
									display: inline-block;
									color: $basicColor;
									background: rgba(255,255,255,.85);
									font-weight: bold;
									@include fz_vw(18);
									line-height: 1;
									@include props(p,6);
					  
									@include mq(md){
									  @include props(w,140,750);
									  @include fz_vw(28);
									  @include props(p,2);
									}
								}
							}
						}
					}

					.txt{
						@include mq(md){
							width: 49.2%;
							.recipeCate{
								span{
									display: flex;
									align-items: center;
									justify-content: center;
									letter-spacing: 0;
									@include props(w,160,750);
									text-align: center;
									display: inline-block;
									color: $basicWhiteColor;
									background: $keyColor;
									font-weight: bold;
									@include fz_vw(24);
									line-height: 1;
									@include props(p,8,750);
								}
							}
						}
						.recipeSubName{
							@include fz_vw(20);
							transition: all .3s;

							@include mq(md){
								@include props(mt,20,750);
								@include fz_vw(28);
							}
						}
						.recipeName{
							@include fz_vw(24);
							transition: all .3s;
							font-weight: bold;
							@include mq(md){
								@include fz_vw(32);
							}
						}
					}
				}
			}
		}
	}
}


/* ==============================================
recipe詳細ページ
============================================== */

#recipeSingle{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.recipePostMainVisual{
		position: relative;
		@include props(h,580);

		@include mq(md){
			@include props(h,525,750);
		}

		.img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			@include props(h,350);
			z-index: -1;

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&.imgFilter{
				&::before{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					background:rgba(255,255,255,.3);
					top: 0;
					left: 0;
					z-index: 0;
				}
			}

			@include mq(md){
				@include props(h,330,750);
				@include props(t,30,750);
			}
		}
		.mainVisualTxt{
			height: 100%;
			@include props(pt,60);
			@include mq(md){
			  @include props(pt,0);
			}
	  
			.mainVisualTxtTtl{
			  display: inline;
			  @include fz_vw(50);
			  font-weight: bold;
			  writing-mode: vertical-rl;
			  text-orientation: upright;
			  display: flex;
			  align-items: center;
			  letter-spacing: .1em;
			  height: 100%;
	  
			  @include mq(md){
				@include fz_vw(50);
				@include props(pl,40,750);
			  }
		
			  &:after{
				content: "Recipe";
				display: inline-block;
				text-align: center;
				writing-mode: horizontal-tb;
				text-orientation: unset;
				@include fz_vw(16);
				color: $keyColor;
				@include props(mt,20);
				letter-spacing: .05em;
	  
				@include mq(md){
				  @include fz_vw(26);
				  @include props(mt,30,750);
				}
			  }
			}
		}
	}

	.recipePostArea{
		@include props(mb,250);

		@include mq(md){
			@include props(mb,250,750);
		}
		.mainArea{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-start;
			@include props(mb,100);
			max-width: 1406px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 20px;

			@include mq(md){
				@include props(mb,150,750);
				@include props(pl,40,750);
				@include props(pr,0,750);
			}

			.img{
				width: 54.9%;
				@include props(btlr,80);

				img{
					@include props(btlr,80);
				}

				@include mq(md){
					@include props(btlr,80,750);
					width: 100%;

					img{
						@include props(btlr,80,750);
					}
				}
			}

			.txt{
				width: 37.77%;

				@include mq(md){
					width: 100%;
					@include props(pr,40,750);
				}

				.cateList{
					display: flex;
					align-items: center;
					@include props(mb,50);

					span{
						letter-spacing: 0;
						@include props(w,120);
						text-align: center;
						display: inline-block;
						color: $basicWhiteColor;
						background: $keyColor;
						font-weight: bold;
						@include fz_vw(18);
						line-height: 1;
						@include props(p,6);
		  
						@include mq(md){
						  @include props(w,160,750);
						  @include fz_vw(24);
						  @include props(p,8,750);
						}
					}

					@include mq(md){
						@include props(mb,40,750);
					}
				}

				.recipeSubName{
					@include fz_vw(20);
					font-weight: bold;

					@include mq(md){
						@include fz_vw(32);
					}
				}

				.recipeName{
					@include fz_vw(40);
					font-weight: bold;
					@include props(mt,10);
					@include props(mb,20);

					@include mq(md){
						@include fz_vw(45);
						@include props(mt,10,750);
						@include props(mb,30,750);
					}
				}

				.recipePostDesc{
					@include props(mb,40);
					@include fz_vw(18);
					line-height: 2;

					@include mq(md){
						@include props(mt,60,750);
						@include props(mb,70,750);
						@include fz_vw(32);
						line-height: 1.9;
					}
					p{
						@include fz_vw(18);
						line-height: 2;

						@include mq(md){
							@include fz_vw(32);
							line-height: 1.9;
						}
		
						em{
							font-style: italic;
						}
		
						a{
							text-decoration: underline;
						}
		
						.aligncenter{
							margin: 0 auto;
							text-align: center;
						}
		
						.alignright{
							margin: 0 0 0 auto;
							text-align: right;
						}
		
						.alignleft{
							margin: 0 auto 0 0;
							text-align: left;
						}
					}

					ul{
						li{
							@include fz_vw(18);
							line-height: 2;
							position: relative;
							padding-left: 1em;
							
							@include mq(md){
								@include fz_vw(32);
								line-height: 1.9;
							}
		
							&:before{
								content: "";
								width: .3em;
								height: .3em;
								border-radius: 50%;
								background: $basicColor;
								position: absolute;
								top: .75em;
								left: 0;
							}
						}
					}
		
					ol{
						counter-reset: count 0;
						li{
							@include fz_vw(18);
							line-height: 2;
							position: relative;
							padding-left: 1em;
							&:before{
								content: counter(count) ". ";
								counter-increment: count 1;
								position: absolute;
								top: 0;
								left: 0
							}

							@include mq(md){
								@include fz_vw(32);
								line-height: 1.9;
							}
						}
					}
				}

				.recipeTime{
					@include fz_vw(18);
					&:before{
						content: "";
						background: url(/images/recipe/recipe_time_icon.svg) center center no-repeat;
						background-size: contain;
						display: inline-block;
						width: 1.66em;
						height: 1.66em;
						vertical-align: bottom;
						margin-right: 0.722em;
					}
					
					@include mq(md){
						@include fz_vw(32);
					}
				}
			}
		}

		.twoColumnArea{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-start;
			@include props(mb,100);

			@include mq(md){
				@include props(mb,100,750);
			}

			.foodListArea{
				width: 45%;

				@include mq(md){
					width: 100%;
				}

				.foodListTtl{
					@include fz_vw(30);
					@include props(mb,20);
					font-weight: bold;
					span{
						@include fz_vw(22);
					}

					@include mq(md){
						@include fz_vw(36);
						@include props(mb,30,750);
						span{
							@include fz_vw(32);
						}
					}
				}

				.foodList{
					border-top: 1px dashed #D3D3D3;
					li{
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px dashed #D3D3D3;
						@include props(pt,9);
						@include props(pb,9);

						@include mq(md){
							@include props(pt,25,750);
							@include props(pb,25,750);
						}

						p{
							@include fz_vw(18);
							a{
								@include fz_vw(18);
								text-decoration: underline;
								color: $keyColor;
								font-weight: bold;
							}

							@include mq(md){
								@include fz_vw(32);

								a{
									@include fz_vw(32);
								}
							}
						}

						.foodCapa{
							@include fz_vw(18);
							width: 35%;
							text-align: right;

							@include mq(md){
								@include fz_vw(32);
								font-weight: bold;
								width: 100%;
							}
						}

						&.foodListMain{
							p{
								padding-left: 1em;
								text-indent: -1em;
								width: 60%;
								&::before{
									content: "・";
								}

								@include mq(md){
									width: 100%;
								}
							}

							.foodCapa{
								font-weight: bold;
							}
						}

						&.foodListSub{
							&:not(.groupTtl){
								p{
									padding-left: 1em;
									text-indent: -1em;
									width: 60%;
									&::before{
										content: "・";
									}
	
									@include mq(md){
										width: 100%;
									}
								}
							}

							&.groupTtl{
								@include props(pt,35);
								@include mq(md){
									@include props(pt,70,750);
								}
								p{
									width:100%;
								}

								.foodCapa{
									display: none;
								}
							}
						}
					}
				}
			}

			.trickCreateArea{
				width: 50%;
				background: #FAF6F4;
				@include props(btrr,80);
				@include props(p,60);
				position: relative;

				@include mq(md){
					@include props(mt,200,750);
					width: 100%;
					@include props(btrr,80,750);
					@include props(pt,70,750);
					@include props(pb,70,750);
					@include props(pl,40,750);
					@include props(pr,40,750);
				}

				&:before{
					content: "";
					background: url(/images/recipe/trick_create_icon.svg) center center no-repeat;
					background-size: contain;
					@include props(w,130);
					@include props(h,135);
					position: absolute;
					@include props(r,90);
					@include props(t,-60);

					@include mq(md){
						@include props(w,188,750);
						@include props(h,206,750);
						@include props(r,40,750);
						@include props(t,-108,750);
					}
				}

				.trickCreateTtl{
					@include fz_vw(26);
					font-weight: bold;
					@include props(mb,40);
					text-align: center;

					@include mq(md){
						@include fz_vw(36);
						@include props(mb,70,750);
						text-align: left;
						@include props(pl,30,750);
					}
				}

				.trickCreateList{
					li{
						position: relative;
						&:not(:last-child){
							@include props(mb,40);
						}
						&::before{
							content: "";
							width: .22em;
							height: .22em;
							background: $basicColor;
							position: absolute;
							top: .75em;
							left: 0;
							@include fz_vw(18);
							border-radius: 50%;

							@include mq(md){
								@include fz_vw(32);
							}
						}
						.createCon{
							padding-left: 1.5em;
							@include fz_vw(18);
							letter-spacing: 0;
							@include mq(md){
								@include fz_vw(32);
								@include props(pl,30,750);
							}
							p{
								@include fz_vw(18);
								line-height: 2;
								letter-spacing: 0;
								@include mq(md){
									@include fz_vw(32);
								}
				
								em{
									font-style: italic;
								}
				
								a{
									text-decoration: underline;
								}
				
								.aligncenter{
									margin: 0 auto;
									text-align: center;
								}
				
								.alignright{
									margin: 0 0 0 auto;
									text-align: right;
								}
				
								.alignleft{
									margin: 0 auto 0 0;
									text-align: left;
								}
							}
		
							ul{
								li{
									@include fz_vw(18);
									line-height: 2;
									position: relative;
									padding-left: 1em;
									@include mq(md){
										@include fz_vw(32);
										@include props(pl,30,750);
									}
				
									&:before{
										content: "";
										width: .3em;
										height: .3em;
										border-radius: 50%;
										background: $basicColor;
										position: absolute;
										top: .75em;
										left: 0;
									}
								}
							}
				
							ol{
								counter-reset: count 0;
								li{
									@include fz_vw(18);
									line-height: 2;
									position: relative;
									padding-left: 1em;
									@include mq(md){
										@include fz_vw(32);
										@include props(pl,30,750);
									}
									&:before{
										content: counter(count) ". ";
										counter-increment: count 1;
										position: absolute;
										top: 0;
										left: 0
									}
								}
							}
						}
					}
				}
			}
		}


		.recipeListDesc{
			@include props(mb,50);

			@include mq(md){
				@include props(mb,150,750);
			}
			.recipeListTtl{
				@include fz_vw(30);
				@include props(mb,60);
				font-weight: bold;

				@include mq(md){
					@include fz_vw(36);
					@include props(mb,30,750);
				}
			}
			.recipeListArea{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				counter-reset: count 0;
				&:after{
					content: "";
					display: block;
					width: 32.21%;

					@include mq(md){
						content: none;
					}
				}
				.list{
					width: 32.21%;
					@include props(mb,100);
					position: relative;
					display: flex;
					flex-direction: column;

					@include mq(md){
						width: 100%;
						@include props(mb,60,750);
						@include props(pl,85,750);
						flex-direction: column-reverse;
						&:last-child{
							@include props(mb,0);
						}
					}

					&:before{
						content: counter(count);
						counter-increment: count 1;
						position: absolute;
						@include props(t,-29);
						left: 0;
						color: $basicWhiteColor;
						background: $keyColor;
						@include props(w,57);
						@include props(h,57);
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						@include fz_vw(22);
						font-weight: bold;
						z-index: 5;

						@include mq(md){
							@include props(t,0);
							@include props(w,60,750);
							@include props(h,60,750);
							@include fz_vw(32);
						}
					}

					.img{
						position: relative;
						padding-top: 68.18%;
						@include props(mb,30);

						@include mq(md){
							@include props(mb,0);
							@include props(mt,30,750);
						}

						img{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.txt{
						.recipeDescContent{
							p{
								@include fz_vw(18);
								line-height: 2;
								letter-spacing: 0;

								@include mq(md){
									@include fz_vw(28);
									line-height: 1.57;
								}
				
								em{
									font-style: italic;
								}
				
								a{
									text-decoration: underline;
								}
				
								.aligncenter{
									margin: 0 auto;
									text-align: center;
								}
				
								.alignright{
									margin: 0 0 0 auto;
									text-align: right;
								}
				
								.alignleft{
									margin: 0 auto 0 0;
									text-align: left;
								}
							}
		
							ul{
								li{
									@include fz_vw(18);
									line-height: 2;
									position: relative;
									padding-left: 1em;

									@include mq(md){
										@include fz_vw(28);
										line-height: 1.57;
									}
				
									&:before{
										content: "";
										width: .3em;
										height: .3em;
										border-radius: 50%;
										background: $basicColor;
										position: absolute;
										top: .75em;
										left: 0;
									}
								}
							}
				
							ol{
								counter-reset: count 0;
								li{
									@include fz_vw(18);
									line-height: 2;
									position: relative;
									padding-left: 1em;
									&:before{
										content: counter(count) ". ";
										counter-increment: count 1;
										position: absolute;
										top: 0;
										left: 0
									}

									@include mq(md){
										@include fz_vw(28);
										line-height: 1.57;
									}
								}
							}
						}
					}
				}
			}
		}


		.itemRecommend{
			@include props(mb,150);

			@include mq(md){
				@include props(mb,150,750);
			}

			.itemRecommendTtl{
				@include fz_vw(30);
				@include props(mb,40);
				font-weight: bold;

				@include mq(md){
					@include fz_vw(36);
					@include props(mb,30,750);
				}
			}

			.itemRecommendList{
				.list{
					border: 1px solid #CECECE;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					align-items: flex-start;
					@include props(pt,60);
					@include props(pb,60);
					@include props(pl,110);
					@include props(pr,110);
					@include props(btrr,40);

					&:nth-child(n + 2){
						@include props(mt,60);
					}

					@include mq(md){
						@include props(p,65,750);
						@include props(btrr,80,750);

						&:nth-child(n + 2){
							@include props(mt,60,750);
						}
					}

					.img{
						width: 37.52%;
						@include props(btrr,40);

						img{
							@include props(btrr,40);
						}

						@include mq(md){
							@include props(w,500,750);
							@include props(btrr,40,750);
							margin: 0 auto;
	
							img{
								@include props(btrr,40,750);
							}
						}
					}

					.txt{
						width: 54.97%;

						@include mq(md){
							width: 100%;
							@include props(mt,40,750);
						}

						.listItemTtl{
							@include fz_vw(32);
							font-weight: bold;
							@include props(mb,40);

							@include mq(md){
								@include fz_vw(36);
								@include props(mb,40,750);
								text-align: center;
							}
						}

						.itemRecommendDesc{
							@include props(mb,40);
							@include fz_vw(18);
							line-height: 2;
							letter-spacing: 0;

							@include mq(md){
								@include props(mb,40,750);
								@include fz_vw(28);
								line-height: 1.57;
							}
							p{
								@include fz_vw(18);
								line-height: 2;
								letter-spacing: 0;

								@include mq(md){
									@include fz_vw(28);
									line-height: 1.57;
								}
				
								em{
									font-style: italic;
								}
				
								a{
									text-decoration: underline;
								}
				
								.aligncenter{
									margin: 0 auto;
									text-align: center;
								}
				
								.alignright{
									margin: 0 0 0 auto;
									text-align: right;
								}
				
								.alignleft{
									margin: 0 auto 0 0;
									text-align: left;
								}
							}
		
							ul{
								li{
									@include fz_vw(18);
									line-height: 2;
									position: relative;
									padding-left: 1em;

									@include mq(md){
										@include fz_vw(28);
										line-height: 1.57;
									}
				
									&:before{
										content: "";
										width: .3em;
										height: .3em;
										border-radius: 50%;
										background: $basicColor;
										position: absolute;
										top: .75em;
										left: 0;
									}
								}
							}
				
							ol{
								counter-reset: count 0;
								li{
									@include fz_vw(18);
									line-height: 2;
									position: relative;
									padding-left: 1em;
									&:before{
										content: counter(count) ". ";
										counter-increment: count 1;
										position: absolute;
										top: 0;
										left: 0
									}

									@include mq(md){
										@include fz_vw(28);
										line-height: 1.57;
									}
								}
							}
						}

						.linkArea{
							margin-top: auto;
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;
		
							.btn02{
								width: 47.61%;

								@include mq(md){
									width: 100%;
								}
							}

							.btn05{
								width: 47.61%;

								@include mq(md){
									width: 100%;
									@include props(mt,40,750);
								}
							}
						}
					}
				}
			}
		}

		.otherArea{
			@include props(mb,200);

			@include mq(md){
				@include props(mb,150,750);
			}
			.otherAreaTtl{
				@include fz_vw(30);
				@include props(mb,40);
				font-weight: bold;

				@include mq(md){
					@include fz_vw(36);
					@include props(mb,40,750);
				}
			}

			.otherAreaColumn{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				align-items: flex-start;

				@include mq(md){
					align-items: center;
				}

				.img{
					width: 25.62%;
					@include props(btrr,40);

					img{
						@include props(btrr,40);
					}

					@include mq(md){
						@include props(w,280,750);
						@include props(btrr,80,750);

						img{
							@include props(btrr,80,750);
						}
					}
				}

				.otherAreaConTtl{
					@include fz_vw(26);
					font-weight: bold;
					@include props(mb,30);

					@include mq(md){
						@include props(w,350,750);
						@include fz_vw(32);
						@include props(mb,0);
					}
				}

				.txt{
					width: 69.25%;

					@include mq(md){
						width: 100%;
					}

					.otherAreaConDesc{
						@include props(mb,60);
						@include fz_vw(18);
						line-height: 2;
						letter-spacing: 0;

						@include mq(md){
							@include props(mt,50,750);
							@include props(mb,70,750);
							@include fz_vw(28);
							line-height: 1.7;
						}

						p{
							@include fz_vw(18);
							line-height: 2;
							letter-spacing: 0;

							@include mq(md){
								@include fz_vw(28);
								line-height: 1.7;
							}
			
							em{
								font-style: italic;
							}
			
							a{
								text-decoration: underline;
							}
			
							.aligncenter{
								margin: 0 auto;
								text-align: center;
							}
			
							.alignright{
								margin: 0 0 0 auto;
								text-align: right;
							}
			
							.alignleft{
								margin: 0 auto 0 0;
								text-align: left;
							}
						}
	
						ul{
							li{
								@include fz_vw(18);
								line-height: 2;
								position: relative;
								padding-left: 1em;

								@include mq(md){
									@include fz_vw(28);
									line-height: 1.7;
								}
			
								&:before{
									content: "";
									width: .3em;
									height: .3em;
									border-radius: 50%;
									background: $basicColor;
									position: absolute;
									top: .75em;
									left: 0;
								}
							}
						}
			
						ol{
							counter-reset: count 0;
							li{
								@include fz_vw(18);
								line-height: 2;
								position: relative;
								padding-left: 1em;
								&:before{
									content: counter(count) ". ";
									counter-increment: count 1;
									position: absolute;
									top: 0;
									left: 0
								}

								@include mq(md){
									@include fz_vw(28);
									line-height: 1.7;
								}
							}
						}
					}

					.btn05{
						@include props(w,380);

						@include mq(md){
							@include props(w,540,750);
							margin: 0 auto;
						}
					}
				}
			}
		}

		.cateRecommend{
			@include props(mb,170);

			@include mq(md){
				@include props(mb,150,750);
			}
			.cateRecommendTtl{
				@include fz_vw(30);
				font-weight: bold;
				@include props(mb,40);

				@include mq(md){
					@include fz_vw(36);
					@include props(mb,40,750);
				}
			}

			.cateRecommendList{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				&:after{
					content: "";
					width: 30.769%;
					display: block;

					@include mq(md){
						content: none;
					}
				}

				.list{
					width: 30.769%;

					@include mq(md){
						width: 100%;
						&:not(:last-child){
							@include props(mb,50,750);
						}
						a{
							display: flex;
							justify-content: space-between;
						}
					}

					a{
						&:hover{
							img{
								transform: scale(1.1);
							}
							.cateRecommendHead,.cateRecommendListTtl{
								opacity: .6;
							}
						}
					}

					.img{
						position: relative;
						padding-top: 66.666%;
						@include props(mb,30);

						@include mq(md){
							width: 46.2%;
							@include props(mb,0);
							padding-top: 30.89%;
						}

						img{
							position: absolute;
							transition: all .8s;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						@include mq(pc){
							.cateList{
								position: absolute;
								@include props(t,10);
								@include props(r,10);
				
								span{
									display: flex;
									align-items: center;
									justify-content: center;
									letter-spacing: 0;
									@include props(w,120);
									text-align: center;
									display: inline-block;
									color: $basicColor;
									background: rgba(255,255,255,.85);
									font-weight: bold;
									@include fz_vw(18);
									line-height: 1;
									@include props(p,6);
					  
									@include mq(md){
									  @include props(w,140,750);
									  @include fz_vw(28);
									  @include props(p,2);
									}
								}
							}
						}
					}

					.txt{
						@include mq(md){
							width: 49.2%;
							.cateList{
								span{
									display: flex;
									align-items: center;
									justify-content: center;
									letter-spacing: 0;
									@include props(w,160,750);
									text-align: center;
									display: inline-block;
									color: $basicWhiteColor;
									background: $keyColor;
									font-weight: bold;
									@include fz_vw(24);
									line-height: 1;
									@include props(p,8,750);
								}
							}
						}
						.cateRecommendHead{
							@include fz_vw(20);
							transition: all .3s;

							@include mq(md){
								@include props(mt,20,750);
								@include fz_vw(28);
							}
						}
						.cateRecommendListTtl{
							@include fz_vw(24);
							transition: all .3s;
							font-weight: bold;
							@include mq(md){
								@include fz_vw(32);
							}
						}
					}
				}
			}
		}
	}
}