@charset "utf-8";
/* アニメーション関連---------------------- */
.fadeIn {
  $fadeIn: &;
  opacity: 0.01;
  visibility:hidden;
  transform: translateY(100px);
  transition: opacity 1s .5s, transform 1s .5s;
  backface-visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
    #{$fadeIn}_second {
      opacity: 1;
      visibility: visible;
    }
    #{$fadeIn}_third {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
  #{$fadeIn}_second {
    opacity: 0.01;
    visibility:hidden;
    transition: opacity .8s 1s;
  }
  #{$fadeIn}_third {
    opacity: 0.01;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity .8s .7s, transform .8s .7s;
  }
}

.imgFadeIn{
  overflow: hidden;
  position: relative;
  z-index: 3;

  &:before{
    content:"";
    display:block;
    background:$keyColor;
    z-index:2;
    width:130%;
    height:120%;
    left: -200%;
    top: 0;
    position: absolute;
    transform: skewX(15deg);
  }

  *{
    opacity: 0.01;
    transition: opacity .3s .5s;
  }

  @keyframes imgFadeIn {
    0% {
      width:130%;
      left:-200%;
      opacity: 1;
    }
    30% {
      width:130%;
      left:-12.5%;
      opacity: 1;
    }
    60% {
      width:130%;
      left:130%;
      opacity: 1;
    }
    100% {
      width:130%;
      left:130%;
      opacity: 0;
    }
  }

  &.active{
    &:before{
      animation: imgFadeIn 2s ease forwards;
      animation-delay: 0;
    }

    *{
      opacity: 1;
    }
  }
}
