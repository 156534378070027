@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*tab設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #000;
  font-size: 62.5%;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #000;
  letter-spacing: .05em;
  overflow-x: hidden;
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.maxContainer {
  max-width: 1600px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .maxContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .maxContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .maxContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

.container {
  max-width: 1520px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .container {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .container {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-left: 2.92826vw;
  }
}

.minContainer {
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .minContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .minContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .minContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

a {
  color: #000;
}

/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  width: 100%;
  padding-bottom: 1.04167vw;
  clear: both;
}

@media screen and (min-width: 1367px) {
  .breadcrumb {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    padding-top: 4vw;
    padding-bottom: 6.66667vw;
    padding-right: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-right: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-right: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-right: 10.2489vw;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  margin: 0 auto;
}

.breadcrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
  }
}

.breadcrumb ol > li {
  display: inline;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1.75;
  letter-spacing: 0.9px;
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 3.73333vw;
  }
}

.breadcrumb ol > li a {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1.75;
  letter-spacing: 0.9px;
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li a {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 3.73333vw;
  }
}

.breadcrumb ol > li:first-child a {
  text-decoration: none;
}

.breadcrumb ol > li + li::before {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  padding-right: 0.36458vw;
  padding-left: 0.36458vw;
  content: "＞";
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.51245vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.51245vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-left: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.51245vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

/* =====================================
オープニング
===================================== */
.introWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #c73640;
  z-index: 100000;
  opacity: 0.01;
}

.introWrap.introFinish {
  background: none;
  transition: all 1s;
}

.introWrap.introFinish .introAnime::before {
  width: 1000vw;
  height: 1000vh;
}

.introWrap.introFinish .introCon {
  transition: all 1s;
  opacity: 0.01;
  visibility: hidden;
}

.introWrap .introAnime {
  position: relative;
  width: 100%;
  height: 100%;
}

.introWrap .introAnime::before {
  content: "";
  background: url(/images/index/index_intro_mask.svg) top center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
  transition: all 2s;
}

.introWrap .introAnime .introCon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.introWrap .introAnime .introCon .txt {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.introWrap .introAnime .introCon .txt svg {
  height: 31.75vh;
}

.introWrap .introAnime .introCon .txt svg path {
  transition: all .3s;
  opacity: 0.01;
}

.introWrap .introAnime .introCon .txt svg path:nth-of-type(n + 6):nth-child(-n+13) {
  transition: all .3s .5s;
}

.introWrap .introAnime .introCon .txt svg path:nth-of-type(n + 14) {
  transition: all .3s 1s;
}

.introWrap .introAnime .introCon .txt svg path.active {
  opacity: 1;
}

.introWrap .introAnime .introCon .txt .introLogo {
  opacity: 0.01;
  height: 16.66vh;
  transition: all .3s 1.5s;
}

.introWrap .introAnime .introCon .txt .introLogo.active {
  opacity: 1;
}

/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
}

#header .headerContents {
  position: relative;
  display: flex;
  align-items: center;
  transition: .5s;
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 2.08333vw;
  padding-right: 1.5625vw;
  height: 6.25vw;
  max-height: 120px;
}

@media screen and (min-width: 1367px) {
  #header .headerContents {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .headerContents {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .headerContents {
    height: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    height: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    height: 8.78477vw;
  }
}

@media screen and (min-width: 1920px) {
  #header .headerContents {
    padding-left: 4.6875vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1367px) {
  #header .headerContents {
    padding-left: 90px;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 6.58858vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #header .headerContents {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    height: 13.33333vw;
    max-height: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerContents {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerContents {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerContents {
    height: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    height: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    height: 7.32064vw;
  }
}

#header .headerLogo {
  width: 6.30208vw;
  max-width: 121px;
  flex-shrink: 0;
  transition: .5s;
  margin-right: auto;
}

@media screen and (min-width: 1367px) {
  #header .headerLogo {
    width: 121px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerLogo {
    width: 8.85798vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerLogo {
    width: 8.85798vw;
  }
}

@media screen and (max-width: 767px) {
  #header .headerLogo {
    width: 15.33333vw;
    max-width: 115px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerLogo {
    width: 115px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerLogo {
    width: 8.41874vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerLogo {
    width: 8.41874vw;
  }
}

#header .headerLogo a {
  display: block;
  max-width: 100%;
  width: 100%;
}

#header .headerLogo img {
  width: 100%;
}

#header .navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  padding-right: 5.33333vw;
  width: 13.33333vw;
  max-width: 100px;
  height: 5.33333vw;
  max-height: 40px;
  cursor: pointer;
}

@media screen and (min-width: 1367px) {
  #header .navBtn {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn {
    width: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    width: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    width: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn {
    height: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    height: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    height: 2.92826vw;
  }
}

#header .navBtn span {
  display: inline-block;
  position: absolute;
  right: 5.33333vw;
  width: 8vw;
  max-width: 60px;
  height: 0.8vw;
  max-height: 6px;
  background-color: #000;
  transition: transform .5s ease-in-out , opacity .5s ease-in-out;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    right: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn span {
    width: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    width: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    width: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn span {
    height: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    height: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    height: 0.43924vw;
  }
}

#header .navBtn span:nth-of-type(1) {
  top: 0vw;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span:nth-of-type(1) {
    top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(1) {
    top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(1) {
    top: 0vw;
  }
}

#header .navBtn span:nth-of-type(2) {
  top: 45%;
}

#header .navBtn span:nth-of-type(3) {
  bottom: 0vw;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 0vw;
  }
}

#header .navBtn.isOpen span:nth-of-type(1), #header .navBtn.isOpen span:nth-of-type(3) {
  top: 50%;
  background-color: #000;
}

#header .navBtn.isOpen span:nth-of-type(1) {
  width: 8vw;
  max-width: 60px;
  transform: rotate(-150deg);
  z-index: 1;
}

@media screen and (min-width: 1367px) {
  #header .navBtn.isOpen span:nth-of-type(1) {
    width: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn.isOpen span:nth-of-type(1) {
    width: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn.isOpen span:nth-of-type(1) {
    width: 4.39239vw;
  }
}

#header .navBtn.isOpen span:nth-of-type(2) {
  opacity: 0.01;
}

#header .navBtn.isOpen span:nth-of-type(3) {
  transform: rotate(150deg);
}

@media screen and (min-width: 768px) {
  #header .navArea .content {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  #header .navArea {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 76.26%;
    width: 100%;
    height: 100%;
    z-index: 998;
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    transition: visibility .7s .8s;
  }
  #header .navArea.isShow {
    visibility: visible;
    transition: visibility .7s;
  }
  #header .navArea.isShow::after {
    transition: transform .7s;
    transform: translateX(0);
  }
  #header .navArea.isShow .content {
    transition: opacity .8s .8s, transform .7s .8s;
    opacity: 1;
    transform: translateY(0);
  }
  #header .navArea::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: -5px 0 20px 0 #0000000d;
    background: #fff;
    z-index: -1;
    transform: translateX(100%);
    transition: transform .7s .9s;
  }
  #header .navArea .content {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity .8s, transform .7s;
    padding-top: 22.26667vw;
    padding-bottom: 17.33333vw;
    padding-right: 5.33333vw;
    padding-left: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navArea .content {
    padding-top: 167px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-top: 12.22548vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-top: 12.22548vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navArea .content {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-bottom: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-bottom: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navArea .content {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navArea .content {
    padding-left: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-left: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-left: 8.05271vw;
  }
}

#header .pcGlobalNav {
  transition: .5s;
}

#header .pcGlobalNav ul {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul {
    display: block;
  }
}

#header .pcGlobalNav ul .pcGlobalNavList {
  margin-right: 1.82292vw;
}

@media screen and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 1.30208vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 1.83016vw;
  }
}

#header .pcGlobalNav ul .pcGlobalNavList a {
  display: block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  transition: opacity .3s;
}

@media screen and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 768px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-top: 0.52083vw;
    padding-bottom: 0.52083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #header .pcGlobalNav ul .pcGlobalNavList a.current {
    border-bottom: 1px solid #c73640;
    color: #c73640;
  }
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 9.33333vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 4.53333vw;
  }
}

#header .externalLink {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #header .externalLink {
    flex-wrap: wrap;
  }
}

#header .externalLink .externalLinkBtn {
  flex-shrink: 0;
  margin-left: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 1.04167vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10.66667vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0vw;
  }
}

#header .externalLink .externalLinkBtn.store a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #484848;
  border-radius: 50px;
  font-size: 1.5rem;
  font-size: 0.78125vw;
  padding-top: 0.67708vw;
  padding-bottom: 0.67708vw;
  padding-left: 1.30208vw;
  padding-right: 1.30208vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.28px;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 2vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-top: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-right: 1.83016vw;
  }
}

#header .externalLink .externalLinkBtn.store a:hover {
  background: #c73640;
}

#header .externalLink .externalLinkBtn.store a::before {
  content: "";
  display: inline-block;
  background-image: url(/images/common/footer_cart_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.4em;
  height: 1.1em;
  margin-right: .66em;
}

#header .externalLink .externalLinkBtn.store a::after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/blank_white_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .86em;
  height: .8em;
  margin-left: .66em;
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 3.2vw;
    padding-bottom: 3.2vw;
    padding-left: 4.4vw;
    padding-right: 4.4vw;
    letter-spacing: 0.65px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-top: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-top: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-bottom: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-bottom: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-left: 33px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-left: 2.41581vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-left: 2.41581vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-right: 33px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-right: 2.41581vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    padding-right: 2.41581vw;
  }
}

#header .externalLink .externalLinkBtn.twitter {
  width: 1.19792vw;
  max-width: 23px;
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 23px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 1.68375vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 1.68375vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 6.4vw;
    max-width: 48px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 48px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.twitter img {
    width: 100%;
  }
}

#header .externalLink .externalLinkBtn.instagram {
  width: 1.25vw;
  max-width: 24px;
}

@media screen and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 24px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 6.53333vw;
    max-width: 49px;
    margin-right: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 49px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 3.58712vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 3.58712vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .externalLink .externalLinkBtn.instagram {
    margin-right: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.instagram {
    margin-right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.instagram {
    margin-right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.instagram img {
    width: 100%;
  }
}

#header #productsSubNav {
  display: none;
}

/* =====================================================
フッター
===================================================== */
#footer .footerInner {
  background: #F5F5F5;
  padding-top: 4.16667vw;
  padding-bottom: 5.46875vw;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner {
    padding-bottom: 105px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner {
    padding-bottom: 7.68668vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner {
    padding-bottom: 7.68668vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner {
    padding-top: 20vw;
    padding-bottom: 20vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner {
    padding-left: 2.92826vw;
  }
}

#footer .footerInner .mainLogo {
  margin-bottom: 2.8125vw;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 54px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 3.95315vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 3.95315vw;
  }
}

#footer .footerInner .mainLogo img {
  width: 10.9375vw;
  max-width: 210px;
  margin: 0 auto;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .mainLogo img {
    width: 210px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .mainLogo img {
    width: 15.37335vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .mainLogo img {
    width: 15.37335vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .mainLogo {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .mainLogo img {
    width: 40vw;
    max-width: 300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .mainLogo img {
    width: 300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .mainLogo img {
    width: 21.96193vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .mainLogo img {
    width: 21.96193vw;
  }
}

#footer .footerInner .blankLinkArea {
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea {
    margin-bottom: 10.98097vw;
  }
}

#footer .footerInner .blankLinkArea ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#footer .footerInner .blankLinkArea ul li:nth-child(1) {
  margin-right: 1.82292vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 0vw;
    width: 100%;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) {
    margin-bottom: 2.92826vw;
  }
}

#footer .footerInner .blankLinkArea ul li:nth-child(1) a {
  background: #484848;
  display: inline-block;
  text-align: center;
  border-radius: 4.16667vw;
  padding-left: 2.23958vw;
  padding-right: 2.23958vw;
  padding-top: 0.72917vw;
  padding-bottom: 0.72917vw;
  line-height: 1;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-left: 43px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-left: 3.14788vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-left: 3.14788vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-right: 43px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-right: 3.14788vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-right: 3.14788vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-top: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-top: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-top: 1.02489vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-bottom: 1.02489vw;
  }
}

#footer .footerInner .blankLinkArea ul li:nth-child(1) a:hover {
  background: #c73640;
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 10.66667vw;
    padding-left: 8.4vw;
    padding-right: 8.4vw;
    padding-top: 3.2vw;
    padding-bottom: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    border-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-left: 63px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-left: 4.61201vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-left: 4.61201vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-right: 63px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-right: 4.61201vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-right: 4.61201vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-top: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-top: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-bottom: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a {
    padding-bottom: 1.75695vw;
  }
}

#footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
  font-size: 1.5rem;
  font-size: 0.78125vw;
  color: #fff;
  line-height: 1;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 2vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:nth-child(1) a span {
    font-size: 4.26667vw;
  }
}

#footer .footerInner .blankLinkArea ul li:nth-child(1) a span:before {
  content: "";
  background: url(/images/common/footer_cart_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.4em;
  height: 0.9375em;
  display: inline-block;
  margin-right: 0.66em;
}

#footer .footerInner .blankLinkArea ul li:nth-child(1) a span:after {
  content: "";
  background: url(/images/common/blank_white_icon.svg) center center no-repeat;
  background-size: contain;
  width: 0.86em;
  height: 0.8em;
  display: inline-block;
  margin-left: 0.66em;
}

#footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
  margin-left: 0.78125vw;
  margin-right: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 1.0981vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-right: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 3.33333vw;
    margin-right: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) {
    margin-right: 1.83016vw;
  }
}

#footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
  width: 2.29167vw;
  max-width: 44px;
  height: 2.29167vw;
  max-height: 44px;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 44px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 3.22108vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 3.22108vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    height: 44px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    height: 3.22108vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    height: 3.22108vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 10.66667vw;
    max-width: 80px;
    height: 10.66667vw;
    max-height: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    width: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    height: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    height: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .blankLinkArea ul li:not(:nth-child(1)) a img {
    height: 5.85652vw;
  }
}

#footer .footerInner .innerLinkArea ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul {
    justify-content: space-between;
  }
}

#footer .footerInner .innerLinkArea ul li {
  margin-left: 0.91146vw;
  margin-right: 0.91146vw;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 17.5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 1.28111vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 1.28111vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-right: 17.5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-right: 1.28111vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-right: 1.28111vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 0vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(odd) {
    width: 48.66667vw;
    max-width: 365px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(odd) {
    width: 365px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(odd) {
    width: 26.72035vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(odd) {
    width: 26.72035vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(even) {
    width: 30vw;
    max-width: 225px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(even) {
    width: 225px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(even) {
    width: 16.47145vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li:nth-child(even) {
    width: 16.47145vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li:not(:nth-child(-n+2)) {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li:not(:nth-child(-n+2)) {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li:not(:nth-child(-n+2)) {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li:not(:nth-child(-n+2)) {
    margin-top: 3.66032vw;
  }
}

#footer .footerInner .innerLinkArea ul li a {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerInner .innerLinkArea ul li a {
    font-size: 3.73333vw;
  }
}

#footer small {
  font-size: 1rem;
  font-size: 0.52083vw;
  text-align: center;
  display: block;
  padding-top: 1.5625vw;
  padding-bottom: 1.5625vw;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 1367px) {
  #footer small {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    font-size: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    font-size: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    font-size: 1.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer small {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer small {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    font-size: 2.4rem;
    font-size: 1.25vw;
    padding-top: 3.73333vw;
    padding-bottom: 3.73333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer small {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer small {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer small {
    padding-top: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    padding-top: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    padding-top: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer small {
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    padding-bottom: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    padding-bottom: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer small {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer small {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer small {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer small {
    padding-right: 2.92826vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0.01;
  visibility: hidden;
  transform: translateY(100px);
  transition: opacity 1s .5s, transform 1s .5s;
  backface-visibility: hidden;
}

.fadeIn.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.active .fadeIn_second {
  opacity: 1;
  visibility: visible;
}

.fadeIn.active .fadeIn_third {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn .fadeIn_second {
  opacity: 0.01;
  visibility: hidden;
  transition: opacity .8s 1s;
}

.fadeIn .fadeIn_third {
  opacity: 0.01;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .7s, transform .8s .7s;
}

.imgFadeIn {
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.imgFadeIn:before {
  content: "";
  display: block;
  background: #c73640;
  z-index: 2;
  width: 130%;
  height: 120%;
  left: -200%;
  top: 0;
  position: absolute;
  transform: skewX(15deg);
}

.imgFadeIn * {
  opacity: 0.01;
  transition: opacity .3s .5s;
}

@keyframes imgFadeIn {
  0% {
    width: 130%;
    left: -200%;
    opacity: 1;
  }
  30% {
    width: 130%;
    left: -12.5%;
    opacity: 1;
  }
  60% {
    width: 130%;
    left: 130%;
    opacity: 1;
  }
  100% {
    width: 130%;
    left: 130%;
    opacity: 0;
  }
}

.imgFadeIn.active:before {
  animation: imgFadeIn 2s ease forwards;
  animation-delay: 0;
}

.imgFadeIn.active * {
  opacity: 1;
}

/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.btn01 a {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  padding-bottom: 1.04167vw;
  padding-right: 4.42708vw;
  font-weight: bold;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1367px) {
  .btn01 a {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a {
    padding-right: 85px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-right: 6.22255vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-right: 6.22255vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-bottom: 2.66667vw;
    padding-right: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn01 a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a {
    padding-right: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-right: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-right: 5.12445vw;
  }
}

.btn01 a:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  background: #000;
  animation: scroll 3s infinite normal;
}

.btn01 a:after {
  content: "";
  background: url(/images/common/btn_head_arrow.svg) center center no-repeat;
  background-size: contain;
  width: 0.787em;
  height: 0.39em;
  position: absolute;
  bottom: 0px;
  left: 100%;
  transform: translateX(-100%);
  animation: arrowMove 3s infinite normal;
}

@media screen and (max-width: 767px) {
  .btn01 a:after {
    width: 5.33333vw;
    max-width: 40px;
    height: 2.66667vw;
    max-height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a:after {
    width: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a:after {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a:after {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a:after {
    height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a:after {
    height: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a:after {
    height: 1.46413vw;
  }
}

.arrow01 {
  width: 5.20833vw;
  max-width: 100px;
  height: 0.52083vw;
  max-height: 10px;
  margin-left: auto;
  position: relative;
  overflow: hidden;
  font-size: 2.2rem;
  font-size: 1.14583vw;
}

@media screen and (min-width: 1367px) {
  .arrow01 {
    width: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01 {
    width: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .arrow01 {
    width: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  .arrow01 {
    height: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01 {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .arrow01 {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  .arrow01 {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01 {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .arrow01 {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .arrow01 {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  .arrow01 {
    height: 2.66667vw;
    max-height: 20px;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    width: 24.13333vw;
    max-width: 181px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .arrow01 {
    height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01 {
    height: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .arrow01 {
    height: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .arrow01 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01 {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .arrow01 {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .arrow01 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .arrow01 {
    width: 181px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01 {
    width: 13.25037vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .arrow01 {
    width: 13.25037vw;
  }
}

.arrow01:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  background: #000;
  animation: scroll 3s infinite normal;
}

.arrow01:after {
  content: "";
  background: url(/images/common/btn_head_arrow.svg) center center no-repeat;
  background-size: contain;
  width: 0.787em;
  height: 0.39em;
  position: absolute;
  bottom: 0px;
  left: 100%;
  transform: translateX(-100%);
  animation: arrowMove 3s infinite normal;
}

@media screen and (max-width: 767px) {
  .arrow01:after {
    width: 5.33333vw;
    max-width: 40px;
    height: 2.66667vw;
    max-height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .arrow01:after {
    width: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01:after {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .arrow01:after {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .arrow01:after {
    height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .arrow01:after {
    height: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .arrow01:after {
    height: 1.46413vw;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  15%, 85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes arrowMove {
  0% {
    left: 0;
    visibility: hidden;
  }
  15%, 85% {
    left: 100%;
    visibility: visible;
  }
  90% {
    visibility: hidden;
    left: 110%;
  }
  100% {
    left: 0;
    visibility: hidden;
  }
}

.btn02 a {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  line-height: 1;
  padding-top: 1.30208vw;
  padding-bottom: 1.30208vw;
  background: #F6F0EC;
  transition: all .5s;
}

@media screen and (min-width: 1367px) {
  .btn02 a {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .btn02 a {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-bottom: 1.83016vw;
  }
}

.btn02 a:hover {
  background: #c73640;
  color: #fff;
  opacity: 1 !important;
}

.btn02 a:hover.linkBlank:after {
  background: url(/images/common/blank_white_icon.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .btn02 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn02 a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-bottom: 2.19619vw;
  }
}

.btn02 a.linkBlank:after {
  content: "";
  background: url(/images/common/blank_black_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: bottom;
  margin-left: .5em;
  transition: all .5s;
}

.btn03 {
  width: 18.22917vw;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  .btn03 {
    width: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn03 {
    width: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn03 {
    width: 25.62225vw;
  }
}

@media screen and (max-width: 767px) {
  .btn03 {
    width: 72vw;
    max-width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn03 {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn03 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn03 {
    width: 39.53148vw;
  }
}

.btn03 a {
  display: block;
  width: 100%;
  line-height: 1;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  border: 1px solid #484848;
  padding: 1.44em;
  transition: all .5s;
}

@media screen and (min-width: 1367px) {
  .btn03 a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn03 a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn03 a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .btn03 a {
    font-size: 2.4vw;
  }
}

.btn03 a:hover {
  background: #484848;
  color: #fff;
  opacity: 1 !important;
}

@media screen and (max-width: 767px) {
  .btn03 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn03 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn03 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn03 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn03 a {
    font-size: 4.26667vw;
  }
}

.btn04 a {
  display: block;
  text-align: center;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1;
  padding-top: 1.30208vw;
  padding-bottom: 1.30208vw;
  background: #484848;
  border: 1px solid #484848;
  color: #fff;
  transition: all .5s;
}

@media screen and (min-width: 1367px) {
  .btn04 a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn04 a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn04 a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .btn04 a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn04 a {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn04 a {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn04 a {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn04 a {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn04 a {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn04 a {
    padding-bottom: 1.83016vw;
  }
}

.btn04 a:hover {
  background: #fff;
  color: #484848;
  opacity: 1 !important;
}

@media screen and (max-width: 767px) {
  .btn04 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn04 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn04 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn04 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn04 a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn04 a {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn04 a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn04 a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn04 a {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn04 a {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn04 a {
    padding-bottom: 2.19619vw;
  }
}

.btn05 a {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  line-height: 1;
  padding-top: 1.30208vw;
  padding-bottom: 1.30208vw;
  background: #F6F0EC;
  transition: all .5s;
}

@media screen and (min-width: 1367px) {
  .btn05 a {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn05 a {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn05 a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .btn05 a {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn05 a {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn05 a {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn05 a {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn05 a {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn05 a {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn05 a {
    padding-bottom: 1.83016vw;
  }
}

.btn05 a:hover {
  background: #484848;
  color: #fff;
  opacity: 1 !important;
}

.btn05 a:hover.linkBlank:after {
  background: url(/images/common/blank_white_icon.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .btn05 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn05 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn05 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn05 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn05 a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn05 a {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn05 a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn05 a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn05 a {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn05 a {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn05 a {
    padding-bottom: 2.19619vw;
  }
}

.btn05 a.linkBlank:after {
  content: "";
  background: url(/images/common/blank_black_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: bottom;
  margin-left: .5em;
  transition: all .5s;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

.introAnimeStart #header, .introAnimeStart #indexTop {
  opacity: 0.01;
  transition: opacity .3s 1s;
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: #ffffff;
  opacity: 1;
  z-index: 100000;
}

.pageTopBox {
  position: relative;
  bottom: -2.08333vw;
  left: 0%;
  width: 100%;
  display: flex;
}

@media screen and (min-width: 1367px) {
  .pageTopBox {
    bottom: -40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    bottom: -2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    bottom: -2.92826vw;
  }
}

.pageTopBox:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .pageTopBox {
    bottom: -8.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox {
    bottom: -67px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    bottom: -4.90483vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    bottom: -4.90483vw;
  }
}

.pageTopBox .pageTop {
  writing-mode: vertical-rl;
  margin-left: auto;
  position: relative;
  overflow: hidden;
}

.pageTopBox .pageTop p {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  padding-left: 2.60417vw;
  height: 4.73958vw;
  max-height: 91px;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop p {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop p {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop p {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop p {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop p {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop p {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop p {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop p {
    height: 91px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop p {
    height: 6.66179vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop p {
    height: 6.66179vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop p {
    font-size: 2.4rem;
    font-size: 1.25vw;
    padding-left: 7.46667vw;
    height: 17.86667vw;
    max-height: 134px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .pageTopBox .pageTop p {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop p {
    padding-left: 56px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop p {
    padding-left: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop p {
    padding-left: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop p {
    height: 134px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop p {
    height: 9.80966vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop p {
    height: 9.80966vw;
  }
}

.pageTopBox .pageTop .pageTopFish {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -10;
  transform: translate(0, 100%);
  width: 1.97917vw;
  max-width: 38px;
  height: 4.73958vw;
  max-height: 91px;
  animation: pageTopFish 10s infinite normal;
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 38px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 2.78184vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 2.78184vw;
  }
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop .pageTopFish {
    height: 91px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop .pageTopFish {
    height: 6.66179vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop .pageTopFish {
    height: 6.66179vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 7.46667vw;
    max-width: 56px;
    height: 17.86667vw;
    max-height: 134px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 56px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop .pageTopFish {
    width: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop .pageTopFish {
    height: 134px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop .pageTopFish {
    height: 9.80966vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop .pageTopFish {
    height: 9.80966vw;
  }
}

.pageTopBox .pageTop:after {
  content: "";
  width: 1px;
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 1.14583vw;
  background: #000;
  animation: pageTopLine 5s infinite normal;
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop:after {
    left: 22px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop:after {
    left: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop:after {
    left: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop:after {
    left: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop:after {
    left: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop:after {
    left: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop:after {
    left: 2.63543vw;
  }
}

@keyframes pageTopLine {
  0% {
    transform: translateY(100%);
  }
  15%, 70% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes pageTopFish {
  0% {
    transform: translateY(100%);
  }
  7.5%, 35% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(0%);
    opacity: 1;
  }
  53% {
    opacity: 0.01;
    transform: translateY(0%);
  }
  100% {
    opacity: 0.01;
    transform: translateY(0%);
  }
}

.nonIndexMainVisual {
  overflow-x: hidden;
  margin-bottom: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .minContainer {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 1920px) {
  .nonIndexMainVisual .nonIndexMainVisualInner {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualInner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    position: relative;
    height: inherit;
    max-height: inherit;
    width: inherit;
    max-width: inherit;
    width: 67.86667vw;
    max-width: 509px;
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    width: 509px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    width: 37.26208vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    width: 37.26208vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 5.85652vw;
  }
}

.nonIndexMainVisual .nonIndexMainVisualInner .img {
  position: absolute;
  top: 0;
  right: 0;
  height: 31.25vw;
  max-height: 600px;
  border-top-left-radius: 4.16667vw;
  width: 80vw;
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    height: 600px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    height: 43.92387vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    height: 43.92387vw;
  }
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 1920px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    width: 75vw;
    left: 11.61458vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    left: 223px;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    left: 16.32504vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img {
    left: 16.32504vw;
  }
}

.nonIndexMainVisual .nonIndexMainVisualInner .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualInner .img img {
    border-top-left-radius: 5.85652vw;
  }
}

.nonIndexMainVisual .nonIndexMainVisualTtl {
  padding-top: 3.02083vw;
  height: 31.25vw;
  max-height: 600px;
  letter-spacing: .05em;
  display: inline;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 58px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 4.24597vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 4.24597vw;
  }
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    height: 600px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    height: 43.92387vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    height: 43.92387vw;
  }
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 0vw;
    height: inherit;
    max-height: inherit;
    font-size: 5rem;
    font-size: 2.60417vw;
    margin-left: 5.33333vw;
    margin-right: auto;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    margin-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl {
    margin-left: 2.92826vw;
  }
}

.nonIndexMainVisual .nonIndexMainVisualTtl:after {
  letter-spacing: .05em;
  display: inline-block;
  text-align: center;
  writing-mode: horizontal-tb;
  text-orientation: unset;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  color: #c73640;
  margin-top: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    position: absolute;
    width: 20vw;
    max-width: 150px;
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 0vw;
    left: 50%;
    bottom: -4vw;
    transform: translate(-50%, 100%);
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    bottom: -30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    bottom: -2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    bottom: -2.19619vw;
  }
}

.topProductsBnr {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .topProductsBnr .topProductsBnrDesc {
    width: 78.66667vw;
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .topProductsBnr .topProductsBnrDesc {
    width: 590px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .topProductsBnr .topProductsBnrDesc {
    width: 43.1918vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .topProductsBnr .topProductsBnrDesc {
    width: 43.1918vw;
  }
}

.topProductsBnr .topProductsBnrDesc span {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  color: #c73640;
  margin-bottom: 1.5625vw;
  position: relative;
  display: inline-block;
}

@media screen and (min-width: 1367px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  .topProductsBnr .topProductsBnrDesc span {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .topProductsBnr .topProductsBnrDesc span {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .topProductsBnr .topProductsBnrDesc span {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .topProductsBnr .topProductsBnrDesc span {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .topProductsBnr .topProductsBnrDesc span {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .topProductsBnr .topProductsBnrDesc span {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .topProductsBnr .topProductsBnrDesc span {
    margin-bottom: 2.19619vw;
  }
}

.topProductsBnr .topProductsBnrDesc span:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #c73640;
  position: absolute;
  transform: rotate(-30deg);
  left: -1em;
  bottom: 0;
}

.topProductsBnr .topProductsBnrDesc span:after {
  content: "";
  width: 2px;
  height: 100%;
  background: #c73640;
  position: absolute;
  transform: rotate(30deg);
  right: -1em;
  bottom: 0;
}

.topProductsBnr .img img {
  margin: 0 auto;
}

.newsPaging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1367px) {
  .newsPaging {
    margin-bottom: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging {
    margin-bottom: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging {
    margin-bottom: 17.56955vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging {
    margin-bottom: 18.30161vw;
  }
}

.newsPaging .page {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  .newsPaging .page {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 1367px) {
  .newsPaging .page {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page {
    padding-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page {
    padding-left: 0vw;
    padding-right: 0vw;
    margin-left: 1.33333vw;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page {
    margin-right: 0.73206vw;
  }
}

.newsPaging .page a {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  .newsPaging .page a {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page a {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page a {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page a {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsPaging .page a {
    font-size: 4.26667vw;
  }
}

.newsPaging .page.active {
  border-bottom: 1px solid #c73640;
}

.newsPaging .page.active a {
  color: #c73640;
}

.newsPaging .page.skipPage {
  display: flex;
  align-items: center;
}

.newsPaging .page.skipPage:before {
  content: "…";
  display: block;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  margin-right: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  .newsPaging .page.skipPage:before {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.skipPage:before {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.skipPage:before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page.skipPage:before {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1367px) {
  .newsPaging .page.skipPage:before {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.skipPage:before {
    margin-right: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.skipPage:before {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page.skipPage:before {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page.skipPage:before {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.skipPage:before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.skipPage:before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsPaging .page.skipPage:before {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page.skipPage:before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.skipPage:before {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.skipPage:before {
    margin-right: 1.46413vw;
  }
}

.newsPaging .page.backSkipPage {
  display: flex;
  align-items: center;
}

.newsPaging .page.backSkipPage:after {
  content: "…";
  display: block;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  margin-left: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1367px) {
  .newsPaging .page.backSkipPage:after {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.backSkipPage:after {
    margin-left: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.backSkipPage:after {
    margin-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsPaging .page.backSkipPage:after {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .page.backSkipPage:after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .page.backSkipPage:after {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .page.backSkipPage:after {
    margin-left: 1.46413vw;
  }
}

.newsPaging .pageArrow {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  .newsPaging .pageArrow {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 1367px) {
  .newsPaging .pageArrow {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow {
    padding-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .pageArrow {
    padding-left: 0vw;
    padding-right: 0vw;
    margin-left: 1.33333vw;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .pageArrow {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .pageArrow {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .pageArrow {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .pageArrow {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow {
    margin-right: 0.73206vw;
  }
}

.newsPaging .pageArrow img {
  width: 3.48958vw;
  max-width: 67px;
  height: 0.44792vw;
  max-height: 8.6px;
}

@media screen and (min-width: 1367px) {
  .newsPaging .pageArrow img {
    width: 67px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow img {
    width: 4.90483vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow img {
    width: 4.90483vw;
  }
}

@media screen and (min-width: 1367px) {
  .newsPaging .pageArrow img {
    height: 8.6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow img {
    height: 0.62958vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow img {
    height: 0.62958vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPaging .pageArrow img {
    width: 10vw;
    max-width: 75px;
    height: 2.66667vw;
    max-height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .pageArrow img {
    width: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow img {
    width: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow img {
    width: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .newsPaging .pageArrow img {
    height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsPaging .pageArrow img {
    height: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsPaging .pageArrow img {
    height: 1.46413vw;
  }
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop .indexMainVisual {
  padding-top: 9.53125vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual {
    padding-top: 183px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual {
    padding-top: 13.39678vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual {
    padding-top: 13.39678vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual {
    padding-top: 17.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual {
    padding-top: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual {
    padding-top: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual {
    padding-top: 9.51684vw;
  }
}

#indexTop .indexMainVisual .indexMainVisualCopy {
  margin-left: auto;
  margin-right: 11.5625vw;
  width: 12.86vw;
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual .indexMainVisualCopy {
    margin-right: auto;
    width: 24.8vw;
    max-width: 186px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .indexMainVisualCopy {
    width: 186px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .indexMainVisualCopy {
    width: 13.6164vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .indexMainVisualCopy {
    width: 13.6164vw;
  }
}

#indexTop .indexMainVisual .imgWrap {
  position: relative;
  width: 100%;
  height: 100%;
  height: 44.11458333vw;
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual .imgWrap {
    height: inherit;
    max-height: inherit;
  }
}

#indexTop .indexMainVisual .slide01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 65.625vw;
  height: 36.40625vw;
  border-top-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual .slide01 {
    position: relative;
    width: 100%;
    height: 80vw;
    max-height: 600px;
    margin-top: 5.33333vw;
    border-top-right-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 {
    height: 600px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 {
    height: 43.92387vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 {
    height: 43.92387vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 {
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 {
    border-top-right-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 {
    border-top-right-radius: 0vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner {
    border-top-right-radius: 4.16667vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner {
    border-top-right-radius: 5.85652vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner .slide {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -10;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide {
    border-top-right-radius: 4.16667vw;
    padding-right: 1px;
    padding-top: 1px;
    background: #fff;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide {
    border-top-right-radius: 5.85652vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner .slide img {
  height: 100%;
  max-width: inherit;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide img {
    border-top-right-radius: 4.16667vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide img {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slide img {
    border-top-right-radius: 5.85652vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
  position: absolute;
  bottom: 0;
  right: -2.08333vw;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    right: -40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    right: -2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    right: -2.92826vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
  width: 0.625vw;
  max-width: 12px;
  height: 0.78125vw;
  max-height: 15px;
  background: #EDE1D9;
  border-radius: 1.04167vw;
  margin-top: 0.78125vw;
  transition: all .5s .5s;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 0.87848vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    height: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    height: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    height: 1.0981vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    border-radius: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    border-radius: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    border-radius: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-top: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-top: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-top: 1.0981vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
  background: #c73640;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    height: 1.5625vw;
    max-height: 30px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    height: 30px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    height: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    height: 2.19619vw;
  }
}

#indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    right: 50%;
    bottom: -4vw;
    transform: translate(50%, 0);
    flex-direction: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    bottom: -30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    bottom: -2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons {
    bottom: -2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 2vw;
    max-width: 15px;
    height: 1.6vw;
    max-height: 12px;
    border-radius: 2.66667vw;
    margin-top: 0vw;
    margin-left: 0.93333vw;
    margin-right: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    height: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    height: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    height: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    border-radius: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    border-radius: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    border-radius: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-right: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-right: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button {
    margin-right: 0.51245vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    width: 4vw;
    max-width: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    width: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    width: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide01 .slide01Inner .slideButtons button.current {
    width: 2.19619vw;
  }
}

#indexTop .indexMainVisual .slide02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 27.395833333vw;
  height: 15.208333333vw;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide02 {
    border-top-left-radius: 4.16667vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide02 {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide02 {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide02 {
    border-top-left-radius: 5.85652vw;
  }
}

#indexTop .indexMainVisual .slide02 .slide02Inner {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner {
    border-top-left-radius: 4.16667vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner {
    border-top-left-radius: 5.85652vw;
  }
}

#indexTop .indexMainVisual .slide02 .slide02Inner .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -10;
  padding-left: 1px;
  padding-top: 1px;
  background: #fff;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide {
    border-top-left-radius: 4.16667vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide {
    border-top-left-radius: 5.85652vw;
  }
}

#indexTop .indexMainVisual .slide02 .slide02Inner .slide img {
  height: 100%;
  max-width: inherit;
}

@media screen and (min-width: 768px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide img {
    border-top-left-radius: 4.16667vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexMainVisual .slide02 .slide02Inner .slide img {
    border-top-left-radius: 5.85652vw;
  }
}

#indexTop .topProductsBnr {
  margin-top: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .topProductsBnr {
    margin-top: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .topProductsBnr {
    margin-top: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .topProductsBnr {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topProductsBnr {
    margin-top: 17.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .topProductsBnr {
    margin-top: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .topProductsBnr {
    margin-top: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .topProductsBnr {
    margin-top: 9.51684vw;
  }
}

#indexTop .indexAbout {
  margin-top: 13.02083vw;
  margin-bottom: 11.45833vw;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout {
    margin-top: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout {
    margin-top: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout {
    margin-top: 18.30161vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout {
    margin-bottom: 220px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout {
    margin-bottom: 16.10542vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout {
    margin-bottom: 16.10542vw;
  }
}

#indexTop .indexAbout:after {
  content: "";
  background: #fff;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  transition: all .6s ease-in-out;
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout {
    margin-top: 20vw;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout {
    margin-bottom: 14.64129vw;
  }
}

#indexTop .indexAbout.active:after {
  width: 55.72917vw;
  max-width: 1070px;
  height: 55.72917vw;
  max-height: 1070px;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout.active:after {
    width: 1070px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout.active:after {
    width: 78.33089vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout.active:after {
    width: 78.33089vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout.active:after {
    height: 1070px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout.active:after {
    height: 78.33089vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout.active:after {
    height: 78.33089vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout.active:after {
    width: 173.33333vw;
    max-width: 1300px;
    height: 173.33333vw;
    max-height: 1300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout.active:after {
    width: 1300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout.active:after {
    width: 95.16837vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout.active:after {
    width: 95.16837vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout.active:after {
    height: 1300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout.active:after {
    height: 95.16837vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout.active:after {
    height: 95.16837vw;
  }
}

#indexTop .indexAbout.active .indexAboutCon {
  opacity: 1;
  visibility: visible;
  transform: none;
}

#indexTop .indexAbout .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(0);
}

#indexTop .indexAbout .bgImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(/images/index/index_about_main.jpg);
  background-size: cover;
  background-position: center;
  z-index: -1;
}

#indexTop .indexAbout .indexAboutCon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  opacity: 0.01;
  visibility: hidden;
  padding-top: 6.25vw;
  padding-bottom: 6.25vw;
  transform: translateY(100px);
  transition: opacity 1s .6s, transform 1s .6s;
  backface-visibility: hidden;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 8.78477vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 30.66667vw;
    padding-bottom: 43.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 230px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 16.83748vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-top: 16.83748vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-bottom: 325px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-bottom: 23.79209vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon {
    padding-bottom: 23.79209vw;
  }
}

#indexTop .indexAbout .indexAboutCon .img {
  width: 13.9%;
  margin-right: 3.48958vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 67px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 4.90483vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 4.90483vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 0vw;
    width: 25.33333vw;
    max-width: 190px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .img {
    width: 190px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .img {
    width: 13.90922vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .img {
    width: 13.90922vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .img {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    width: 100%;
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt {
    padding-right: 2.19619vw;
  }
}

#indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  line-height: 1.75;
  letter-spacing: .1em;
  font-weight: bold;
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.6;
    letter-spacing: .01em;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConTtl {
    margin-bottom: 5.85652vw;
  }
}

#indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.3;
  letter-spacing: 0.07em;
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .indexAboutConread {
    margin-bottom: 5.85652vw;
  }
}

#indexTop .indexAbout .indexAboutCon .txt .btn01 {
  margin-left: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .indexAbout .indexAboutCon .txt .btn01 {
    width: 54.8vw;
    max-width: 411px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexAbout .indexAboutCon .txt .btn01 {
    width: 411px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexAbout .indexAboutCon .txt .btn01 {
    width: 30.08785vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexAbout .indexAboutCon .txt .btn01 {
    width: 30.08785vw;
  }
}

#indexTop .indexProducts {
  margin-bottom: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts {
    margin-bottom: 14.64129vw;
  }
}

#indexTop .indexProducts .indexProductsMainList {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10.10417vw;
  position: relative;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 194px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 14.20205vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 14.20205vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList {
    margin-bottom: 10.98097vw;
  }
}

#indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea {
  display: inline-block;
  width: 14.12%;
}

@media screen and (min-width: 768px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea {
    position: sticky;
    top: 6.25vw;
    left: 0;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea {
    top: 120px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea {
    top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

#indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  height: 28.125vw;
  max-height: 540px;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    height: 540px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    height: 39.53148vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    height: 39.53148vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 5rem;
    font-size: 2.60417vw;
    height: 77.33333vw;
    max-height: 580px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    height: 580px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    height: 42.45974vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2 {
    height: 42.45974vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

#indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
  content: "Products";
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  color: #c73640;
  writing-mode: horizontal-tb;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList .indexProductsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul {
  width: 85.88%;
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul {
    width: 100%;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
  margin-bottom: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:not(:last-child) {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(odd) a {
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(odd) a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(odd) a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(odd) a {
    padding-right: 2.92826vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(even) a {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(even) a {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(even) a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(even) a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(even) a {
    padding-left: 2.92826vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 5.85652vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 0vw;
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-left-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 0vw;
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-left-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(1) a .img img {
    border-top-right-radius: 5.85652vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
  border-top-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 5.85652vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
  border-top-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 0vw;
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-right-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 0vw;
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-right-radius: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(2) a .img img {
    border-top-left-radius: 5.85652vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
  border-bottom-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 5.85652vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
  border-bottom-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li:nth-child(3) a .img img {
    border-bottom-right-radius: 5.85652vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#indexTop .indexProducts .indexProductsMainList ul li a .img {
  width: 46.03%;
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .img {
    width: 76.9%;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
  font-size: 3.2rem;
  font-size: 1.66667vw;
  color: #c73640;
  font-weight: bold;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 5.5rem;
    font-size: 2.86458vw;
    line-height: 1;
    margin-bottom: 0vw;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 5.5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    font-size: 7.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    font-size: 4.2rem;
    font-size: 2.1875vw;
    margin-top: 20.66667vw;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    margin-top: 155px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    margin-top: 11.347vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    margin-top: 11.347vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .indexProductsTtl p {
    margin-right: 1.46413vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li a .txt {
  width: 43.3%;
  padding-left: 2.98%;
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt {
    width: 100%;
    padding-left: 0;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt {
    margin-top: 4.39239vw;
  }
}

#indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainList ul li a .txt .indexProductsDesc {
    margin-bottom: 3.66032vw;
  }
}

#indexTop .indexProducts .btn01 {
  text-align: right;
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .btn01 {
    text-align: center;
  }
}

#indexTop .indexProducts .indexProductsMainImg {
  margin-top: 10.41667vw;
  position: relative;
  height: 28.64583vw;
  max-height: 550px;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainImg {
    height: 550px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainImg {
    height: 40.26354vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainImg {
    height: 40.26354vw;
  }
}

#indexTop .indexProducts .indexProductsMainImg .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(0);
}

#indexTop .indexProducts .indexProductsMainImg .bgImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(/images/index/index_products_main.jpg);
  background-size: cover;
  background-position: center;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 26.66667vw;
    height: 120vw;
    max-height: 900px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainImg {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexProducts .indexProductsMainImg {
    height: 900px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexProducts .indexProductsMainImg {
    height: 65.8858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexProducts .indexProductsMainImg {
    height: 65.8858vw;
  }
}

#indexTop .indexRecipe {
  position: relative;
  margin-bottom: 12.5vw;
  overflow-x: hidden;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe {
    margin-bottom: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe {
    margin-bottom: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe {
    margin-bottom: 17.56955vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (min-width: 768px) {
  #indexTop .indexRecipe .minContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  #indexTop .indexRecipe .minContainer .btn01 {
    width: 100%;
  }
}

#indexTop .indexRecipe .indexRecipeTxtArea {
  position: relative;
  margin-left: auto;
  margin-bottom: 4.6875vw;
  writing-mode: vertical-rl;
  text-orientation: upright;
  z-index: 1;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 6.58858vw;
  }
}

#indexTop .indexRecipe .indexRecipeTxtArea:before {
  content: "";
  width: 17.70833vw;
  max-width: 340px;
  height: 100%;
  background: #fff;
  position: absolute;
  left: -4.6875vw;
  top: 0;
  z-index: -1;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    width: 340px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    width: 24.89019vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    width: 24.89019vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    left: -90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    left: -6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    left: -6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 18.66667vw;
    writing-mode: horizontal-tb;
    text-orientation: inherit;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea:before {
    content: none;
  }
}

#indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 5rem;
    font-size: 2.60417vw;
    height: 62.66667vw;
    max-height: 470px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    height: 470px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    height: 34.40703vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl {
    height: 34.40703vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

#indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
  content: "Recipe";
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  color: #c73640;
  writing-mode: horizontal-tb;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

#indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.5;
  margin-top: 7.29167vw;
  margin-right: 3.64583vw;
  height: 23.4375vw;
  max-height: 450px;
  letter-spacing: .07em;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-top: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-top: 10.2489vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-right: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-right: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-right: 5.12445vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    height: 450px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    height: 32.9429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    height: 32.9429vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    height: 100%;
    max-height: inherit;
    line-height: 1.7;
    letter-spacing: .05em;
    margin-top: 80vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-top: 600px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-top: 43.92387vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-top: 43.92387vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeTxtArea .indexRecipeTxtDesc {
    margin-right: 0vw;
  }
}

#indexTop .indexRecipe .btn01 {
  text-align: right;
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .btn01 {
    text-align: center;
  }
}

#indexTop .indexRecipe .indexRecipeSlide {
  position: absolute;
  left: 0;
  right: 0;
  top: 7.29167vw;
  z-index: -1;
  height: 23.4375vw;
  max-height: 450px;
  opacity: 0.01;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 10.2489vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    height: 450px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    height: 32.9429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    height: 32.9429vw;
  }
}

#indexTop .indexRecipe .indexRecipeSlide.slick-initialized {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 62.66667vw;
    height: inherit;
    max-height: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 470px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 34.40703vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexRecipe .indexRecipeSlide {
    top: 34.40703vw;
  }
}

#indexTop .indexRecipe .indexRecipeSlide * {
  height: 100%;
}

#indexTop .indexRecipe .indexRecipeSlide img {
  width: 100%;
  object-fit: cover;
}

#indexTop .indexNews {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews {
    margin-bottom: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews {
    margin-bottom: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews {
    margin-bottom: 17.56955vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews {
    margin-bottom: 20vw;
    padding-bottom: 20vw;
    padding-top: 26.66667vw;
    background: #FAF6F4;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews {
    padding-top: 14.64129vw;
  }
}

#indexTop .indexNews .indexNewsTtlArea {
  display: inline-block;
  width: 9.516%;
  height: 15.625vw;
  max-height: 300px;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea {
    height: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea {
    height: 21.96193vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea {
    height: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea {
    width: 100%;
    height: 62.66667vw;
    max-height: 470px;
    display: flex;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea {
    height: 470px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea {
    height: 34.40703vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea {
    height: 34.40703vw;
  }
}

#indexTop .indexNews .indexNewsTtlArea h2 {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 5rem;
    font-size: 2.60417vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea h2 {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

#indexTop .indexNews .indexNewsTtlArea h2:after {
  content: "News";
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  color: #c73640;
  writing-mode: horizontal-tb;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews .indexNewsTtlArea h2:after {
    margin-top: 2.19619vw;
  }
}

#indexTop .indexNews ol {
  width: 90.484%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4.6875vw;
  margin-top: 4.6875vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol {
    margin-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol {
    width: 100%;
    margin-top: 0vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol {
    margin-bottom: 10.98097vw;
  }
}

#indexTop .indexNews ol li {
  width: 30.769%;
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li {
    width: 100%;
  }
  #indexTop .indexNews ol li:not(:last-child) {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li:not(:nth-child(1)) a {
    display: flex;
    justify-content: space-between;
  }
  #indexTop .indexNews ol li:not(:nth-child(1)) a .img, #indexTop .indexNews ol li:not(:nth-child(1)) a .txt {
    width: 46.26%;
  }
}

#indexTop .indexNews ol li .img {
  margin-bottom: 1.5625vw;
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 4vw;
    border-top-left-radius: 4vw;
    border-bottom-right-radius: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img {
    border-top-left-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

#indexTop .indexNews ol li .img .imgWrap {
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
  position: relative;
  padding-top: 66.666%;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

#indexTop .indexNews ol li .img .imgWrap img {
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 4vw;
    border-bottom-right-radius: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 4vw;
    border-bottom-right-radius: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

#indexTop .indexNews ol li .indexNewsInfoArea {
  display: flex;
  align-items: flex-start;
}

#indexTop .indexNews ol li .indexNewsInfoArea time {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea time {
    font-size: 3.73333vw;
  }
}

#indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  justify-content: flex-end;
}

#indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
  letter-spacing: 0;
  width: 4.6875vw;
  max-width: 90px;
  text-align: center;
  display: inline-block;
  color: #fff;
  background: #f08707;
  font-weight: bold;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1;
  padding: 0.20833vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    padding: 4px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    padding: 0.29283vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    padding: 0.29283vw;
  }
}

#indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
  margin-left: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 3) {
    margin-top: 0.52083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 3) {
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 3) {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 3) {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 18.66667vw;
    max-width: 140px;
    font-size: 2.6rem;
    font-size: 1.35417vw;
    padding: 0.26667vw;
    letter-spacing: -.05em;
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    width: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    padding: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: auto;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-top: 0.73206vw;
  }
}

#indexTop .indexNews ol li .indexNewsPostTtl {
  margin-top: 0.52083vw;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 4vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexNews ol li .indexNewsPostTtl {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews ol li:nth-child(1) .indexNewsPostTtl {
    margin-top: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexNews ol li:nth-child(1) .indexNewsPostTtl {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexNews ol li:nth-child(1) .indexNewsPostTtl {
    margin-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexNews ol li:nth-child(1) .indexNewsPostTtl {
    margin-top: 1.83016vw;
  }
}

#indexTop .indexNews .btn01 {
  text-align: right;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .indexNews .btn01 {
    text-align: center;
  }
}

#indexTop .indexContact {
  margin-bottom: 15.10417vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact {
    margin-bottom: 290px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact {
    margin-bottom: 21.22987vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact {
    margin-bottom: 21.22987vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact {
    margin-bottom: 18.30161vw;
  }
}

#indexTop .indexContact .indexContactInner {
  background: url(/images/index/index_contact.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  text-align: right;
  border-top-left-radius: 4.16667vw;
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  padding-right: 2.60417vw;
  padding-left: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner {
    background: url(/images/index/index_contact_sp.jpg) center center no-repeat;
    background-size: cover;
    text-align: left;
    border-top-left-radius: 10.66667vw;
    padding-top: 8vw;
    padding-bottom: 47.06667vw;
    padding-right: 5.33333vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-bottom: 353px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-bottom: 25.84187vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-bottom: 25.84187vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner {
    padding-left: 4.39239vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea {
  display: inline-block;
  text-align: left;
}

#indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  line-height: 1.1;
  font-weight: bold;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 5rem;
    font-size: 2.60417vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
  display: block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  margin-top: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactTtl .indexContactTtlSub {
    margin-top: 1.46413vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
  line-height: 2;
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .02em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc {
    font-size: 3.73333vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
  margin-bottom: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(1) {
    margin-bottom: 1.83016vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .indexContactDesc:nth-of-type(2) {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
  display: inline-block;
  text-align: center;
  width: 18.22917vw;
  max-width: 350px;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 25.62225vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 72vw;
    max-width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn {
    width: 39.53148vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  display: block;
  line-height: 1;
  padding-top: 1.35417vw;
  padding-bottom: 1.35417vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-top: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-top: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-top: 1.90337vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-bottom: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-bottom: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-bottom: 1.90337vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: bold;
    padding-top: 4.8vw;
    padding-bottom: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-top: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-top: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-top: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-bottom: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .indexContact .indexContactInner .indexContactArea .contactBtn a {
    padding-bottom: 2.63543vw;
  }
}

#indexTop .indexContact .indexContactInner .indexContactArea .contactBtn.whiteBtn a {
  background: #fff;
  transition: all .5s;
}

#indexTop .indexContact .indexContactInner .indexContactArea .contactBtn.whiteBtn a:hover {
  cursor: pointer;
  color: #fff;
  background: #000;
  opacity: 1;
}

/* ==============================================
productsページ
============================================== */
#productsTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #productsTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop {
    padding-top: 14.64129vw;
  }
}

#productsTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "Products";
}

#productsTop .productsWrap {
  position: relative;
  margin-top: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap {
    margin-top: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap {
    margin-top: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap {
    margin-top: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap {
    margin-top: 7.32064vw;
  }
}

#productsTop .productsWrap .subNav {
  position: sticky;
  height: 6.25vw;
  max-height: 120px;
  top: 5.72917vw;
  margin-bottom: 5.20833vw;
  left: 0;
  z-index: 10;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav {
    height: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav {
    height: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav {
    height: 8.78477vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav {
    top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav {
    top: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav {
    top: 8.05271vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav {
    top: 12.66667vw;
    margin-bottom: 13.33333vw;
    height: 16vw;
    max-height: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav {
    top: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav {
    top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav {
    top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav {
    height: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav {
    height: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav {
    height: 8.78477vw;
  }
}

#productsTop .productsWrap .subNav dl {
  background: #FAF6F4;
  position: absolute;
  top: 0;
  width: 100%;
}

#productsTop .productsWrap .subNav dl dt .navTtl {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1;
  padding-top: 2.65625vw;
  padding-bottom: 2.65625vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-top: 51px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-top: 3.73353vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-top: 3.73353vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-bottom: 51px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-bottom: 3.73353vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-bottom: 3.73353vw;
  }
}

#productsTop .productsWrap .subNav dl dt .navTtl:after {
  content: "";
  background: url(/images/products/products_nav_arrow.svg) center center no-repeat;
  background-size: contain;
  margin-left: .8em;
  width: 1.66em;
  height: .55em;
  display: inline-block;
  transition: .5s;
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    padding-top: 5.6vw;
    padding-bottom: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-top: 42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-top: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-top: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-bottom: 42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dt .navTtl {
    padding-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dt .navTtl:after {
    margin-left: .5em;
  }
}

#productsTop .productsWrap .subNav dl dt:hover {
  cursor: pointer;
}

#productsTop .productsWrap .subNav dl dt.open .navTtl:after {
  transform: rotate(180deg);
}

#productsTop .productsWrap .subNav dl dd {
  display: none;
  padding-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd {
    padding-bottom: 4.39239vw;
  }
}

#productsTop .productsWrap .subNav dl dd ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1.82292vw;
  padding-bottom: 1.82292vw;
  padding-right: 4.32292vw;
  padding-left: 4.32292vw;
  background: #fff;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-right: 83px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-right: 6.07613vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-right: 6.07613vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-left: 83px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-left: 6.07613vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-left: 6.07613vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    background: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) {
  #productsTop .productsWrap .subNav dl dd ul:after {
    content: "";
    display: block;
    width: 31.6666%;
  }
}

#productsTop .productsWrap .subNav dl dd ul li {
  width: 31.6666%;
  height: 3.64583vw;
  max-height: 70px;
  text-align: center;
  margin-top: 0.78125vw;
  margin-bottom: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    height: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    height: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    height: 5.12445vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-top: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-top: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-top: 1.0981vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-bottom: 1.0981vw;
  }
}

#productsTop .productsWrap .subNav dl dd ul li a {
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 768px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    transition: all .5s;
  }
  #productsTop .productsWrap .subNav dl dd ul li a:hover {
    border: 1px solid #484848;
    background: #484848;
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    width: 100%;
    height: inherit;
    max-height: inherit;
    margin-top: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #D3C5BC;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 3.33333vw;
    padding-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    padding-top: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .subNav dl dd ul li a {
    padding-bottom: 1.83016vw;
  }
}

#productsTop .productsWrap .productsCat {
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat {
    margin-bottom: 14.64129vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  color: #c73640;
  text-align: center;
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatTtl {
    margin-bottom: 3.66032vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.3;
  text-align: center;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    line-height: 1.9;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 16.66667vw;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    margin-bottom: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    margin-bottom: 9.15081vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatDesc {
    margin-bottom: 9.15081vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#productsTop .productsWrap .productsCat .productsCatList .list {
  display: flex;
  flex-direction: column;
  width: 46.12%;
  margin-bottom: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    width: 100%;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list:last-child {
    margin-bottom: 0vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
  position: relative;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea {
    margin-bottom: 3.66032vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
  height: 20.83333vw;
  max-height: 400px;
  width: 100%;
  opacity: 0.01;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 400px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 29.28258vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 29.28258vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .slick-list, #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .slick-track {
  height: 100%;
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 56.66667vw;
    max-height: 425px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 425px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 31.11274vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg {
    height: 31.11274vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg.slick-initialized {
  opacity: 1;
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
  width: 100%;
  position: relative;
  padding-top: 63.49%;
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner {
    border-top-right-radius: 2.92826vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsCatListMainImg .mainInner img {
    border-top-right-radius: 2.92826vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
  position: absolute;
  left: 1.04167vw;
  bottom: 0vw;
  transform: translate(0, 50%);
  width: 5.88542vw;
  max-width: 113px;
  height: 5.88542vw;
  max-height: 113px;
  z-index: 1;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    bottom: 0vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    width: 113px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    width: 8.27233vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    width: 8.27233vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    height: 113px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    height: 8.27233vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    height: 8.27233vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 2.66667vw;
    width: 21.33333vw;
    max-width: 160px;
    height: 21.33333vw;
    max-height: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    width: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    height: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    height: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListMainImgArea .productsItemIcon {
    height: 11.71303vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
  height: 2.91667vw;
  max-height: 56px;
  margin-bottom: 2.60417vw;
  opacity: 0.01;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 4.09956vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 4.09956vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-bottom: 3.66032vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .slick-list, #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .slick-track {
  height: 100%;
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 13.13333vw;
    max-height: 98.5px;
    margin-bottom: 5.33333vw;
    margin-right: -2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 98.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 7.21083vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    height: 7.21083vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-right: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-right: -1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg {
    margin-right: -1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .slick-track {
    margin: 0 0 0 auto;
  }
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg * {
    height: 100%;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg.slick-initialized {
  opacity: 1;
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .slick-current .thumInner img {
  border-color: #c73640;
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-right: 1.0981vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner:hover {
  cursor: pointer;
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner .inner {
  position: relative;
  padding-top: 65.44%;
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner img {
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 2.4vw;
    padding-right: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-left: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-right: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-right: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner {
    padding-right: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListThumImg .thumInner img {
    border-width: 2px;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListTtl {
    margin-bottom: 2.92826vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
  margin-bottom: 2.60417vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 2.4vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 2.4vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p em {
  font-style: italic;
}

#productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p a {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 8vw;
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .productsCatListDesc p {
    font-size: 4.26667vw;
  }
}

#productsTop .productsWrap .productsCat .productsCatList .list .linkArea {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02, #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
  width: 47.61%;
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea {
    justify-content: center;
  }
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02 {
    width: 72vw;
    max-width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02 {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02:last-of-type {
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02:last-of-type {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02:last-of-type {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn02:last-of-type {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    width: 72vw;
    max-width: 540px;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsWrap .productsCat .productsCatList .list .linkArea .btn05 {
    margin-top: 2.92826vw;
  }
}

/* ==============================================
NEWSページ
============================================== */
#newsTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #newsTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop {
    padding-top: 14.64129vw;
  }
}

#newsTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "News";
}

#newsTop .newsArea .navArea {
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap;
}

#newsTop .newsArea .navArea .secSubTit {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
  padding-right: 2.60417vw;
  margin-right: 2.60417vw;
  border-right: 1px solid #000;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .secSubTit {
    width: 100%;
    padding-right: 0vw;
    margin-right: 0vw;
    border-right: none;
    font-size: 3.6rem;
    font-size: 1.875vw;
    text-align: center;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .navArea .secSubTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .secSubTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsCategoryList {
  display: flex;
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList {
    position: relative;
    display: block;
    z-index: 10;
    width: 41.33333vw;
    max-width: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList {
    width: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList {
    width: 22.694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList {
    width: 22.694vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
  margin-right: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li:not(:last-child) {
    margin-right: 0vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    display: block;
    text-align: center;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList.listTop li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #000;
    padding-top: 0.4vw;
    padding-bottom: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    padding-top: 3px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    padding-top: 0.21962vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    padding-top: 0.21962vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    padding-bottom: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0vw;
    margin: auto;
    background-image: url(/images/news/news_archives_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 3.73333vw;
    max-width: 28px;
    height: 1.86667vw;
    max-height: 14px;
    transition: .5s;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    width: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    width: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    width: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    height: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    height: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp::after {
    height: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .activeSp.categoryOpen::after {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    display: none;
    position: absolute;
    max-width: 100%;
    width: 100%;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.95);
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsCategoryList .newsCategoryListSp {
    padding-bottom: 1.46413vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
  padding-right: 8.59375vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 165px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 12.07906vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 12.07906vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn {
    padding-right: 0vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .newsArchivesList {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .newsArchivesList {
    position: relative;
    width: 41.33333vw;
    max-width: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .newsArchivesList {
    width: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .newsArchivesList {
    width: 22.694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .newsArchivesList {
    width: 22.694vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
  position: relative;
  cursor: pointer;
  padding-top: 0.26042vw;
  padding-bottom: 0.26042vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-top: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-top: 0.36603vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-top: 0.36603vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-bottom: 0.36603vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-bottom: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    border-bottom: solid 1px #000;
    padding-top: 0.66667vw;
    padding-bottom: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-top: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-top: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-top: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-bottom: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn {
    padding-bottom: 0.36603vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.52083vw;
  margin: auto;
  background-image: url(/images/news/news_archives_icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1.51042vw;
  max-width: 29px;
  height: 0.72917vw;
  max-height: 14px;
  transition: .5s;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    width: 29px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    width: 2.12299vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    width: 2.12299vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    height: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    height: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    height: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 0vw;
    width: 3.73333vw;
    max-width: 28px;
    height: 1.86667vw;
    max-height: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    width: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    width: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    width: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    height: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    height: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn::after {
    height: 1.02489vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archivesBtn.open::after {
  transform: rotate(180deg);
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
  font-size: 2rem;
  font-size: 1.04167vw;
  text-align: center;
  padding-right: 2.60417vw;
  padding-left: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archives {
    padding-left: 0vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
  display: none;
  padding-top: 0.52083vw;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.95);
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    position: absolute;
    max-width: 100%;
    width: 100%;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra {
    padding-bottom: 1.46413vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
  padding-right: 0vw;
  padding-left: 0vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
    padding-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul .archives {
    padding-left: 0vw;
  }
}

#newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
  display: block;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  padding-top: 0.52083vw;
  padding-bottom: 0.52083vw;
  padding-right: 2.08333vw;
  padding-left: 2.08333vw;
  transition: background-color .6s;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea .newsSubNav .newsArchivesColumn .archiveEra ul a {
    padding-left: 2.92826vw;
  }
}

#newsTop .newsArea .navArea.catePage .secSubTit {
  text-align: left;
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav {
    align-items: center;
  }
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop {
    display: flex;
    width: inherit;
  }
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li a {
    padding: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li:not(:last-child) {
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li:not(:last-child) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li:not(:last-child) {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .listTop li:not(:last-child) {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList {
    width: inherit;
  }
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archives {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archives {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archives {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archives {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archives {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archivesBtn {
    padding-right: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archivesBtn {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archivesBtn {
    padding-right: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .navArea.catePage .newsSubNav .newsArchivesColumn .newsArchivesList .archivesBtn {
    padding-right: 4.39239vw;
  }
}

#newsTop .newsArea .newsAreaList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2.60417vw;
  margin-top: 4.6875vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList {
    margin-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList {
    width: 100%;
    margin-top: 13.33333vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList:after {
    content: none;
  }
}

#newsTop .newsArea .newsAreaList:after {
  content: "";
  display: block;
  width: 30.769%;
}

#newsTop .newsArea .newsAreaList .list {
  width: 30.769%;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list {
    width: 100%;
  }
  #newsTop .newsArea .newsAreaList .list:not(:last-child) {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a {
    display: flex;
    justify-content: space-between;
  }
}

#newsTop .newsArea .newsAreaList .list a .img {
  margin-bottom: 1.5625vw;
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    width: 40.8vw;
    max-width: 306px;
    margin-bottom: 0vw;
    border-top-left-radius: 4vw;
    border-bottom-right-radius: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    width: 306px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    width: 22.40117vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    width: 22.40117vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-top-left-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

#newsTop .newsArea .newsAreaList .list a .img .imgWrap {
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
  position: relative;
  padding-top: 66.66%;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

#newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 4vw;
    border-bottom-right-radius: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 4vw;
    border-bottom-right-radius: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .img .imgWrap img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt {
    width: 42.66667vw;
    max-width: 320px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt {
    width: 320px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt {
    width: 23.42606vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt {
    width: 23.42606vw;
  }
}

#newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea {
    justify-content: space-between;
    align-items: flex-start;
  }
}

#newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea time {
    font-size: 3.73333vw;
  }
}

#newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
}

#newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
  letter-spacing: 0;
  width: 4.6875vw;
  max-width: 90px;
  text-align: center;
  display: inline-block;
  color: #fff;
  background: #f08707;
  font-weight: bold;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1;
  padding: 0.20833vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    padding: 4px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    padding: 0.29283vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    padding: 0.29283vw;
  }
}

#newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
  margin-left: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 20vw;
    max-width: 150px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 0.26667vw;
    letter-spacing: -.1em;
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    padding: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: auto;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsInfoArea .indexNewsCat span:nth-of-type(n + 2) {
    margin-top: 0.73206vw;
  }
}

#newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
  margin-top: 0.52083vw;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 3.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    margin-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .newsArea .newsAreaList .list a .txt .indexNewsPostTtl {
    font-size: 3.73333vw;
  }
}

/* ==============================================
NEWS詳細ページ
============================================== */
#newsSingle {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle {
    padding-top: 14.64129vw;
  }
}

#newsSingle .newsMainVisual {
  position: relative;
  height: 18.22917vw;
  max-height: 350px;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsMainVisual {
    height: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual {
    height: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual {
    height: 25.62225vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsMainVisual {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual {
    height: 41.33333vw;
    max-height: 310px;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual {
    height: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual {
    height: 22.694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual {
    height: 22.694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual {
    margin-bottom: 7.32064vw;
  }
}

#newsSingle .newsMainVisual .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#newsSingle .newsMainVisual .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#newsSingle .newsMainVisual .img.imgFilter::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual .img {
    height: 37.33333vw;
    max-height: 280px;
    top: inherit;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual .img {
    height: 280px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .img {
    height: 20.4978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .img {
    height: 20.4978vw;
  }
}

#newsSingle .newsMainVisual .mainVisualTxt {
  height: 100%;
  padding-top: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt {
    padding-top: 0vw;
  }
}

#newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
  display: inline;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  align-items: center;
  letter-spacing: .07em;
  height: 100%;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 5rem;
    font-size: 2.60417vw;
    margin-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    margin-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl {
    margin-left: 2.92826vw;
  }
}

#newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
  content: "News";
  display: inline-block;
  text-align: center;
  writing-mode: horizontal-tb;
  text-orientation: unset;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  color: #c73640;
  margin-top: 1.04167vw;
  letter-spacing: .05em;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

#newsSingle .newsArea {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea {
    margin-bottom: 18.30161vw;
  }
}

#newsSingle .newsArea .postTtlContent {
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent {
    margin-bottom: 2.19619vw;
  }
}

#newsSingle .newsArea .postTtlContent .postInfo {
  display: flex;
  justify-content: flex-start;
}

#newsSingle .newsArea .postTtlContent .postInfo time {
  font-size: 2rem;
  font-size: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo time {
    font-size: 4.26667vw;
  }
}

#newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
  display: inline-block;
  margin-left: 2.34375vw;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat {
    margin-left: 2.19619vw;
  }
}

#newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
  letter-spacing: 0;
  width: 4.6875vw;
  max-width: 90px;
  text-align: center;
  display: inline-block;
  color: #fff;
  background: #f08707;
  font-weight: bold;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1;
  padding: 0.20833vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    padding: 4px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    padding: 0.29283vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    padding: 0.29283vw;
  }
}

#newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span:nth-of-type(n + 2) {
  margin-left: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span:nth-of-type(n + 2) {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 20vw;
    max-width: 150px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 0.10417vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    padding: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .postInfo .indexNewsCat span {
    padding: 0.14641vw;
  }
}

#newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
  margin-top: 1.04167vw;
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  line-height: 1.7;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 4vw;
    font-size: 4rem;
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postTtlContent .indexNewsPostTtl {
    font-size: 5.33333vw;
  }
}

#newsSingle .newsArea .postContent {
  max-width: 1024px;
  margin: 0 auto;
}

#newsSingle .newsArea .postContent .postEyeCatch {
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 5.85652vw;
  }
}

#newsSingle .newsArea .postContent .postEyeCatch img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .postEyeCatch {
    margin-bottom: 3.66032vw;
  }
}

#newsSingle .newsArea .postContent h2 {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h2 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h2 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h2 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent h2 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h2 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h2 {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h2 {
    margin-bottom: 3.66032vw;
  }
}

#newsSingle .newsArea .postContent p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent p {
    font-size: 4.26667vw;
  }
}

#newsSingle .newsArea .postContent p em {
  font-style: italic;
}

#newsSingle .newsArea .postContent p a {
  text-decoration: underline;
}

#newsSingle .newsArea .postContent p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#newsSingle .newsArea .postContent p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#newsSingle .newsArea .postContent p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#newsSingle .newsArea .postContent h3 {
  font-size: 2.6rem;
  font-size: 1.35417vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 3.46667vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h3 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h3 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h3 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent h3 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h3 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h3 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h3 {
    margin-bottom: 2.92826vw;
  }
}

#newsSingle .newsArea .postContent h4 {
  padding-left: 2.34375vw;
  font-size: 2rem;
  font-size: 1.04167vw;
  color: #c73640;
  font-weight: bold;
  margin-bottom: 2.08333vw;
  position: relative;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 3.29429vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4 {
    margin-bottom: 2.92826vw;
  }
}

#newsSingle .newsArea .postContent h4:before {
  content: "";
  width: 1.5625vw;
  max-width: 30px;
  height: 1px;
  background: #c73640;
  position: absolute;
  top: .75em;
  left: 0;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 8vw;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4 {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 5.33333vw;
    max-width: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent h4:before {
    width: 2.92826vw;
  }
}

#newsSingle .newsArea .postContent ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 2.4vw;
  }
}

#newsSingle .newsArea .postContent ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent ul li {
    font-size: 4.26667vw;
  }
}

#newsSingle .newsArea .postContent ol {
  counter-reset: count 0;
}

#newsSingle .newsArea .postContent ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 2.4vw;
  }
}

#newsSingle .newsArea .postContent ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent ol li {
    font-size: 4.26667vw;
  }
}

#newsSingle .newsArea .postContent table {
  border-left: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
}

#newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
  border-right: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
  padding: .27777em;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent table td, #newsSingle .newsArea .postContent table th {
    font-size: 4.26667vw;
  }
}

#newsSingle .newsArea .postContent .personArea {
  padding: 3.125vw;
  margin-top: 5.20833vw;
  border: 1px solid #707070;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    padding: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    padding: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    padding: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 7.32064vw;
  }
}

#newsSingle .newsArea .postContent .personArea .img {
  width: 27.65%;
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .img {
    border-top-right-radius: 2.92826vw;
  }
}

#newsSingle .newsArea .postContent .personArea .img img {
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 2.92826vw;
  }
}

#newsSingle .newsArea .postContent .personArea .personName {
  font-size: 2.6rem;
  font-size: 1.35417vw;
  font-weight: bold;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 3.46667vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    margin-bottom: 2.19619vw;
  }
}

#newsSingle .newsArea .postContent .personArea .txt {
  width: 65.7%;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc p {
    font-size: 3.73333vw;
  }
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc p em {
  font-style: italic;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc p a {
  text-decoration: underline;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 2.4vw;
  }
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ul li {
    font-size: 3.73333vw;
  }
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc ol {
  counter-reset: count 0;
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 2.4vw;
  }
}

#newsSingle .newsArea .postContent .personArea .txt .personDesc ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt .personDesc ol li {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 13.33333vw;
    padding-top: 8vw;
    padding-bottom: 8vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .img {
    width: 33.33333vw;
    max-width: 250px;
    border-top-right-radius: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .img {
    width: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .img {
    width: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .img {
    width: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .img {
    border-top-right-radius: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    width: 40vw;
    max-width: 300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    width: 300px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    width: 21.96193vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .personName {
    width: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .postContent .personArea .txt {
    width: 100%;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .postContent .personArea .txt {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .postContent .personArea .txt {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .postContent .personArea .txt {
    margin-top: 2.92826vw;
  }
}

#newsSingle .newsArea .pageLink {
  border-top: 1px solid #B7B7B7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10.41667vw;
  margin-bottom: 5.20833vw;
  padding-top: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 20vw;
    margin-top: 20vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink {
    margin-bottom: 10.98097vw;
  }
}

#newsSingle .newsArea .pageLink li {
  width: 7.8125vw;
  max-width: 150px;
  margin-left: 5.46875vw;
  margin-right: 5.46875vw;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li {
    width: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li {
    width: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li {
    width: 10.98097vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li {
    margin-left: 105px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li {
    margin-left: 7.68668vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li {
    margin-left: 7.68668vw;
  }
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li {
    margin-right: 105px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li {
    margin-right: 7.68668vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li {
    margin-right: 7.68668vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li {
    width: 30.66667vw;
    max-width: 230px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li {
    width: 230px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li {
    width: 16.83748vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li {
    width: 16.83748vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li.prevLink {
    margin-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li.prevLink {
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li.prevLink {
    margin-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li.prevLink {
    margin-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li.nextLink {
    margin-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li.nextLink {
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li.nextLink {
    margin-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li.nextLink {
    margin-left: 2.92826vw;
  }
}

#newsSingle .newsArea .pageLink li.prevLink {
  text-align: right;
}

#newsSingle .newsArea .pageLink li.prevLink a:before {
  background: url(/images/common/single_arrow_prev.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li.prevLink a:before {
    background: url(/images/common/single_arrow_prev_sp.svg) center center no-repeat;
    background-size: contain;
  }
}

#newsSingle .newsArea .pageLink li.nextLink {
  text-align: left;
}

#newsSingle .newsArea .pageLink li.nextLink a:before {
  background: url(/images/common/single_arrow_next.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li.nextLink a:before {
    background: url(/images/common/single_arrow_next_sp.svg) center center no-repeat;
    background-size: contain;
  }
}

#newsSingle .newsArea .pageLink li a {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  position: relative;
  padding-bottom: 1.25em;
  line-height: 1;
}

@media screen and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsSingle .newsArea .pageLink li a {
    font-size: 4.26667vw;
  }
}

#newsSingle .newsArea .pageLink li a:before {
  content: "";
  width: 100%;
  height: .6em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* ==============================================
recipeページ
============================================== */
#recipeTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop {
    padding-top: 14.64129vw;
  }
}

#recipeTop .recipeFilterArea {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  flex-wrap: wrap;
}

#recipeTop .recipeFilterArea:not(.filterResult) {
  padding-bottom: 1.5625vw;
  border-bottom: 1px solid #000;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 0vw;
    border-bottom: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea:not(.filterResult) {
    padding-bottom: 0vw;
  }
}

#recipeTop .recipeFilterArea .searchResult {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#recipeTop .recipeFilterArea .searchResult .searchResultTtl {
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeFilterArea .searchResult .searchResultTtl {
    font-size: 4.8vw;
  }
}

#recipeTop .recipeFilterArea .searchResult span {
  font-size: 2.4rem;
  font-size: 1.25vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 3.2vw;
  }
}

#recipeTop .recipeFilterArea .searchResult span:not(.num) {
  margin-left: 1.2em;
  margin-right: 2em;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeFilterArea .searchResult span {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav {
    transition: all .3s ease-in-out;
  }
  #recipeTop .recipeFilterArea .recipeNav.searchOn {
    opacity: 0.01;
    visibility: hidden;
  }
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList {
  display: flex;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList {
    position: relative;
    display: block;
    z-index: 10;
    width: 52vw;
    max-width: 390px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList {
    width: 390px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList {
    width: 28.55051vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList {
    width: 28.55051vw;
  }
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category {
  padding-left: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category {
    display: flex;
  }
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active {
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: relative;
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
  content: "";
  background: url(/images/recipe/recipe_current_icon.svg) center center no-repeat;
  background-size: contain;
  width: 2.91667vw;
  max-width: 56px;
  height: 1.19792vw;
  max-height: 23px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0%, -100%);
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 4.09956vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 4.09956vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    height: 23px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    height: 1.68375vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    height: 1.68375vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active {
    writing-mode: horizontal-tb;
    text-orientation: inherit;
    padding-bottom: 1.33333vw;
    border-bottom: 1px solid #000;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 13.73333vw;
    max-width: 103px;
    height: 5.73333vw;
    max-height: 43px;
    transform: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 103px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 7.54026vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    width: 7.54026vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    height: 43px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    height: 3.14788vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:before {
    height: 3.14788vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0vw;
    margin: auto;
    background-image: url(/images/news/news_archives_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 3.73333vw;
    max-width: 28px;
    height: 1.86667vw;
    max-height: 14px;
    transition: .5s;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    width: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    width: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    width: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    height: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    height: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active:after {
    height: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active.categoryOpen::after {
    transform: rotate(180deg);
  }
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
  color: #c73640;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  padding-top: 0vw;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 0vw;
  }
}

@media screen and (min-width: 768px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 0.78125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 15px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    display: block;
    width: 100%;
    font-size: 3.6rem;
    font-size: 1.875vw;
    padding-top: 0.78125vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .category.active .activeTxt {
    padding-top: 1.0981vw;
  }
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li {
  margin-left: 2.60417vw;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li {
    margin-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li {
    margin-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li {
    margin-left: 3.66032vw;
  }
}

#recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  padding-top: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-top: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-top: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    display: none;
    position: absolute;
    max-width: 100%;
    width: 100%;
    left: 0;
    width: 100%;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    z-index: 1;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.95);
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li {
    width: 100%;
    margin: 0;
    writing-mode: horizontal-tb;
    text-orientation: inherit;
  }
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    display: block;
    text-align: center;
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    font-size: 3.6rem;
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp .listTop li a {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 768px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li:first-child {
    margin-left: 0.52083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li:first-child {
    margin-left: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li:first-child {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li:first-child {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current {
    position: relative;
  }
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current a {
    color: #c73640;
  }
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    content: "";
    background: url(/images/recipe/recipe_current_icon.svg) center center no-repeat;
    background-size: contain;
    width: 2.91667vw;
    max-width: 56px;
    height: 1.19792vw;
    max-height: 23px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    width: 56px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    width: 4.09956vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    width: 4.09956vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    height: 23px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    height: 1.68375vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current:before {
    height: 1.68375vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea .recipeNav .recipeCategoryColumn .recipeCategoryList .recipeCategoryListSp.catePage .listTop li.current {
    display: none;
  }
}

#recipeTop .recipeFilterArea #searchWrap {
  text-align: center;
  border-width: 0.15625vw;
  border-color: rgba(255, 255, 255, 0.4);
  transition: border-color .5s;
  border-style: solid;
  border-radius: 100px;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 3px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 0.21962vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 0.21962vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap {
    border-width: 0.36603vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap.searchOn {
  border-color: rgba(199, 54, 64, 0.4);
}

#recipeTop .recipeFilterArea #searchWrap:not(.searchResultFilter) {
  position: absolute;
  bottom: 2.08333vw;
  right: 0;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap:not(.searchResultFilter) {
    bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap:not(.searchResultFilter) {
    bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap:not(.searchResultFilter) {
    bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap:not(.searchResultFilter) {
    bottom: inherit;
    top: 0;
  }
}

#recipeTop .recipeFilterArea #searchWrap.searchResultFilter {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter {
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box {
    width: 89.33333vw;
    max-width: 670px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box {
    width: 670px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box {
    width: 49.04832vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box {
    width: 49.04832vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #searchSubmit {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    flex: 1;
    padding-left: 1.33333vw;
    padding-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap.searchResultFilter .box #contentsSearch {
    padding-right: 0.73206vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap .box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #000;
  padding-left: 1.04167vw;
  padding-right: 1.04167vw;
  padding-top: 0.36458vw;
  padding-bottom: 0.36458vw;
  border-radius: 100px;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  width: 6.04167vw;
  max-width: 116px;
  transition: all .3s ease-in-out;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 116px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 8.49195vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 8.49195vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 29.33333vw;
    max-width: 220px;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-top: 1.86667vw;
    padding-bottom: 1.86667vw;
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 220px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 16.10542vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    width: 16.10542vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box {
    font-size: 4.26667vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap .box:before {
  content: "";
  background: url(/images/recipe/recipe_search_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
}

#recipeTop .recipeFilterArea #searchWrap .box #contentsSearch {
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  width: 0;
  transition: all .3s ease-in-out;
}

#recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
  border: none;
  outline: none;
  background: #fff;
  line-height: 1;
  display: inline-block;
  padding-top: 0.46875vw;
  padding-bottom: 0.46875vw;
  padding-right: 0.3125vw;
  padding-left: 0.3125vw;
  border-radius: 100px;
  transition: all .3s ease-in-out;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  color: #000;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-bottom: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-bottom: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-bottom: 0.65886vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-right: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-right: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-right: 0.43924vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-left: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-left: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-left: 0.43924vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 1.06667vw;
    padding-bottom: 1.06667vw;
    padding-right: 2.4vw;
    padding-left: 2.4vw;
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-top: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-right: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-right: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-right: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-left: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-left: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    padding-left: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box #searchSubmit {
    font-size: 4.26667vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap .box #searchSubmit:hover {
  cursor: pointer;
}

#recipeTop .recipeFilterArea #searchWrap .box.searchOn {
  width: 26.30208vw;
  max-width: 505px;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 505px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 36.96925vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 36.96925vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 89.33333vw;
    max-width: 670px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 670px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 49.04832vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn {
    width: 49.04832vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
  background: #c73640;
  color: #fff;
  padding-right: 0.625vw;
  padding-left: 0.625vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 0.87848vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-left: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-left: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #searchSubmit {
    padding-left: 1.83016vw;
  }
}

#recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
  padding-left: 0.52083vw;
  padding-right: 0.52083vw;
  flex: 1;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 1.33333vw;
    padding-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeFilterArea #searchWrap .box.searchOn #contentsSearch {
    padding-right: 0.73206vw;
  }
}

#recipeTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "Recipe";
}

#recipeTop .recipeArea .recipeAreaList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4.6875vw;
  margin-top: 4.6875vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList {
    width: 100%;
    margin-top: 13.33333vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList:after {
    content: none;
  }
}

#recipeTop .recipeArea .recipeAreaList:after {
  content: "";
  display: block;
  width: 30.769%;
}

#recipeTop .recipeArea .recipeAreaList .list {
  width: 30.769%;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list {
    width: 100%;
  }
  #recipeTop .recipeArea .recipeAreaList .list:not(:last-child) {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list:not(:last-child) {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list:not(:last-child) {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list:not(:last-child) {
    margin-bottom: 7.32064vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list a:hover img {
  transform: scale(1.1);
}

#recipeTop .recipeArea .recipeAreaList .list a:hover .recipeSubName, #recipeTop .recipeArea .recipeAreaList .list a:hover .recipeName {
  opacity: .6;
}

#recipeTop .recipeArea .recipeAreaList .list .img {
  margin-bottom: 1.5625vw;
  position: relative;
  border-top-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
  padding-top: 66.666%;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-bottom-right-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 5.33333vw;
    border-top-left-radius: 8vw;
    border-bottom-right-radius: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-top-left-radius: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-top-left-radius: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-top-left-radius: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-bottom-right-radius: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-bottom-right-radius: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img {
    border-bottom-right-radius: 4.39239vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .8s;
  border-top-left-radius: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 8vw;
    border-bottom-right-radius: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-top-left-radius: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-bottom-right-radius: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-bottom-right-radius: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img img {
    border-bottom-right-radius: 4.39239vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
  position: absolute;
  top: 0.52083vw;
  right: 0.52083vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 1.33333vw;
    right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate {
    right: 0.73206vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
  width: 6.25vw;
  max-width: 120px;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  display: inline-block;
  position: relative;
  z-index: 1;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 2.4vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 25.33333vw;
    max-width: 190px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 190px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 13.90922vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    width: 13.90922vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .img .recipeCate span {
    font-size: 3.73333vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
  font-size: 2rem;
  font-size: 1.04167vw;
  margin-bottom: 0.52083vw;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 3rem;
    font-size: 1.5625vw;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeSubName {
    margin-bottom: 0.73206vw;
  }
}

#recipeTop .recipeArea .recipeAreaList .list .recipeName {
  margin-bottom: 1.04167vw;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 3.33333vw;
    font-size: 3.6rem;
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .recipeAreaList .list .recipeName {
    font-size: 4.8vw;
  }
}

#recipeTop .recipeArea .notCon {
  margin-top: 5.20833vw;
  margin-bottom: 13.02083vw;
  width: 100%;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon {
    margin-bottom: 18.30161vw;
  }
}

#recipeTop .recipeArea .notCon:before {
  content: "";
  background: url(/images/recipe/recipe_not_con_icon.svg) center center no-repeat;
  background-size: contain;
  display: block;
  width: 10.41667vw;
  max-width: 200px;
  height: 9.21875vw;
  max-height: 177px;
  margin: 0 auto;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon:before {
    width: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon:before {
    width: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon:before {
    width: 14.64129vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon:before {
    height: 177px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon:before {
    height: 12.95754vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon:before {
    height: 12.95754vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 20vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon:before {
    width: 26.66667vw;
    max-width: 200px;
    height: 23.6vw;
    max-height: 177px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon:before {
    width: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon:before {
    width: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon:before {
    width: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon:before {
    height: 177px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon:before {
    height: 12.95754vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon:before {
    height: 12.95754vw;
  }
}

#recipeTop .recipeArea .notCon .notTopics {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.64583vw;
  margin-top: 2.60417vw;
  font-size: 2.4rem;
  font-size: 1.25vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 9.33333vw;
    margin-top: 6.66667vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notTopics {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .notTopics {
    font-size: 3.73333vw;
  }
}

#recipeTop .recipeArea .notCon .notConListTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
  margin-top: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    margin-bottom: 5.33333vw;
    margin-top: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .notConListTtl {
    margin-top: 7.32064vw;
  }
}

#recipeTop .recipeArea .notCon .itemRecommendList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#recipeTop .recipeArea .notCon .itemRecommendList:after {
  content: "";
  width: 30.769%;
  display: block;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList:after {
    content: none;
  }
}

#recipeTop .recipeArea .notCon .itemRecommendList .list {
  width: 30.769%;
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list {
    width: 100%;
  }
  #recipeTop .recipeArea .notCon .itemRecommendList .list:not(:last-child) {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list:not(:last-child) {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list:not(:last-child) {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list a {
    display: flex;
    justify-content: space-between;
  }
}

#recipeTop .recipeArea .notCon .itemRecommendList .list a:hover img {
  transform: scale(1.1);
}

#recipeTop .recipeArea .notCon .itemRecommendList .list a:hover .recipeSubName, #recipeTop .recipeArea .notCon .itemRecommendList .list a:hover .recipeName {
  opacity: .6;
}

#recipeTop .recipeArea .notCon .itemRecommendList .list .img {
  position: relative;
  margin-bottom: 1.5625vw;
  position: relative;
  padding-top: 66.666%;
  overflow: hidden;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    width: 46.2%;
    margin-bottom: 0vw;
    padding-top: 30.89%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img {
    margin-bottom: 0vw;
  }
}

#recipeTop .recipeArea .notCon .itemRecommendList .list .img img {
  position: absolute;
  transition: all .8s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    position: absolute;
    top: 0.52083vw;
    right: 0.52083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    top: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    right: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    width: 6.25vw;
    max-width: 120px;
    text-align: center;
    display: inline-block;
    color: #000;
    background: rgba(255, 255, 255, 0.85);
    font-weight: bold;
    font-size: 1.8rem;
    font-size: 0.9375vw;
    line-height: 1;
    padding: 0.3125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 120px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    padding: 6px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    padding: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    padding: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 18.66667vw;
    max-width: 140px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 0.10417vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    width: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    padding: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    padding: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .img .recipeCate span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt {
    width: 49.2%;
  }
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    width: 21.33333vw;
    max-width: 160px;
    text-align: center;
    display: inline-block;
    color: #fff;
    background: #c73640;
    font-weight: bold;
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1;
    padding: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    width: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    padding: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    padding: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeCate span {
    padding: 0.58565vw;
  }
}

#recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
  font-size: 2rem;
  font-size: 1.04167vw;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    margin-top: 2.66667vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeSubName {
    font-size: 3.73333vw;
  }
}

#recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
  font-size: 2.4rem;
  font-size: 1.25vw;
  transition: all .3s;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeTop .recipeArea .notCon .itemRecommendList .list .txt .recipeName {
    font-size: 4.26667vw;
  }
}

/* ==============================================
recipe詳細ページ
============================================== */
#recipeSingle {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle {
    padding-top: 14.64129vw;
  }
}

#recipeSingle .recipePostMainVisual {
  position: relative;
  height: 30.20833vw;
  max-height: 580px;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual {
    height: 580px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual {
    height: 42.45974vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual {
    height: 42.45974vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual {
    height: 70vw;
    max-height: 525px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual {
    height: 525px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual {
    height: 38.43338vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual {
    height: 38.43338vw;
  }
}

#recipeSingle .recipePostMainVisual .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18.22917vw;
  max-height: 350px;
  z-index: -1;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 25.62225vw;
  }
}

#recipeSingle .recipePostMainVisual .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#recipeSingle .recipePostMainVisual .img.imgFilter::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 44vw;
    max-height: 330px;
    top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 330px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 24.15813vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .img {
    height: 24.15813vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .img {
    top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .img {
    top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .img {
    top: 2.19619vw;
  }
}

#recipeSingle .recipePostMainVisual .mainVisualTxt {
  height: 100%;
  padding-top: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt {
    padding-top: 0vw;
  }
}

#recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
  display: inline;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  align-items: center;
  letter-spacing: .1em;
  height: 100%;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 5rem;
    font-size: 2.60417vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl {
    padding-left: 2.92826vw;
  }
}

#recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
  content: "Recipe";
  display: inline-block;
  text-align: center;
  writing-mode: horizontal-tb;
  text-orientation: unset;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  color: #c73640;
  margin-top: 1.04167vw;
  letter-spacing: .05em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostMainVisual .mainVisualTxt .mainVisualTxtTtl:after {
    margin-top: 2.19619vw;
  }
}

#recipeSingle .recipePostArea {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea {
    margin-bottom: 18.30161vw;
  }
}

#recipeSingle .recipePostArea .mainArea {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 5.20833vw;
  max-width: 1406px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 20vw;
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea {
    padding-right: 0vw;
  }
}

#recipeSingle .recipePostArea .mainArea .img {
  width: 54.9%;
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 5.85652vw;
  }
}

#recipeSingle .recipePostArea .mainArea .img img {
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 10.66667vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .img img {
    border-top-left-radius: 5.85652vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt {
  width: 37.77%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt {
    width: 100%;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt {
    padding-right: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .cateList {
  display: flex;
  align-items: center;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 3.66032vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .cateList span {
  letter-spacing: 0;
  width: 6.25vw;
  max-width: 120px;
  text-align: center;
  display: inline-block;
  color: #fff;
  background: #c73640;
  font-weight: bold;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 1;
  padding: 0.3125vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    padding: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    padding: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    padding: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 21.33333vw;
    max-width: 160px;
    font-size: 2.4rem;
    font-size: 1.25vw;
    padding: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    padding: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    padding: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList span {
    padding: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .cateList {
    margin-bottom: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeSubName {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipeName {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  margin-top: 0.52083vw;
  margin-bottom: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 4.5rem;
    font-size: 2.34375vw;
    margin-top: 1.33333vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    font-size: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeName {
    margin-bottom: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
  margin-bottom: 2.08333vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-top: 8vw;
    margin-bottom: 9.33333vw;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p em {
  font-style: italic;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p a {
  text-decoration: underline;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol {
  counter-reset: count 0;
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 2.4vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipePostDesc ol li {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipeTime {
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 2.4vw;
  }
}

#recipeSingle .recipePostArea .mainArea .txt .recipeTime:before {
  content: "";
  background: url(/images/recipe/recipe_time_icon.svg) center center no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.66em;
  height: 1.66em;
  vertical-align: bottom;
  margin-right: 0.722em;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .mainArea .txt .recipeTime {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea {
    margin-bottom: 7.32064vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea {
  width: 45%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea {
    width: 100%;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  margin-bottom: 1.04167vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    margin-bottom: 1.46413vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
  font-size: 2.2rem;
  font-size: 1.14583vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodListTtl span {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList {
  border-top: 1px dashed #D3D3D3;
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #D3D3D3;
  padding-top: 0.46875vw;
  padding-bottom: 0.46875vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-bottom: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-bottom: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-bottom: 0.65886vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 3.33333vw;
    padding-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li {
    padding-bottom: 1.83016vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 2.4vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  text-decoration: underline;
  color: #c73640;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li p a {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  width: 35%;
  text-align: right;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: bold;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li .foodCapa {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListMain p {
  padding-left: 1em;
  text-indent: -1em;
  width: 60%;
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListMain p::before {
  content: "・";
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListMain p {
    width: 100%;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListMain .foodCapa {
  font-weight: bold;
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub:not(.groupTtl) p {
  padding-left: 1em;
  text-indent: -1em;
  width: 60%;
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub:not(.groupTtl) p::before {
  content: "・";
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub:not(.groupTtl) p {
    width: 100%;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
  padding-top: 1.82292vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl {
    padding-top: 5.12445vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl p {
  width: 100%;
}

#recipeSingle .recipePostArea .twoColumnArea .foodListArea .foodList li.foodListSub.groupTtl .foodCapa {
  display: none;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
  width: 50%;
  background: #FAF6F4;
  border-top-right-radius: 4.16667vw;
  padding: 3.125vw;
  position: relative;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    margin-top: 26.66667vw;
    width: 100%;
    border-top-right-radius: 10.66667vw;
    padding-top: 9.33333vw;
    padding-bottom: 9.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea {
    padding-right: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
  content: "";
  background: url(/images/recipe/trick_create_icon.svg) center center no-repeat;
  background-size: contain;
  width: 6.77083vw;
  max-width: 130px;
  height: 7.03125vw;
  max-height: 135px;
  position: absolute;
  right: 4.6875vw;
  top: -3.125vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 130px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 9.51684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 9.51684vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    height: 135px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    height: 9.88287vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    height: 9.88287vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    right: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    right: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    right: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    top: -60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    top: -4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    top: -4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 25.06667vw;
    max-width: 188px;
    height: 27.46667vw;
    max-height: 206px;
    right: 5.33333vw;
    top: -14.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 188px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 13.76281vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    width: 13.76281vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    height: 206px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    height: 15.08053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    height: 15.08053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    top: -108px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    top: -7.9063vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea:before {
    top: -7.9063vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
  font-size: 2.6rem;
  font-size: 1.35417vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 3.46667vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 9.33333vw;
    text-align: left;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateTtl {
    padding-left: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li {
  position: relative;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li:not(:last-child) {
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li:not(:last-child) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li:not(:last-child) {
    margin-bottom: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
  content: "";
  width: .22em;
  height: .22em;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  border-radius: 50%;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li::before {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
  padding-left: 1.5em;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon {
    padding-left: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p em {
  font-style: italic;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p a {
  text-decoration: underline;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li {
    padding-left: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol {
  counter-reset: count 0;
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li {
    padding-left: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .twoColumnArea .trickCreateArea .trickCreateList li .createCon ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

#recipeSingle .recipePostArea .recipeListDesc {
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc {
    margin-bottom: 10.98097vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  margin-bottom: 3.125vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListTtl {
    margin-bottom: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  counter-reset: count 0;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea:after {
  content: "";
  display: block;
  width: 32.21%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea:after {
    content: none;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
  width: 32.21%;
  margin-bottom: 5.20833vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    width: 100%;
    margin-bottom: 8vw;
    padding-left: 11.33333vw;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    padding-left: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    padding-left: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list {
    padding-left: 6.22255vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:last-child {
    margin-bottom: 0vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
  content: counter(count);
  counter-increment: count 1;
  position: absolute;
  top: -1.51042vw;
  left: 0;
  color: #fff;
  background: #c73640;
  width: 2.96875vw;
  max-width: 57px;
  height: 2.96875vw;
  max-height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
  z-index: 5;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: -29px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: -2.12299vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: -2.12299vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    width: 57px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    width: 4.17277vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    width: 4.17277vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    height: 57px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    height: 4.17277vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    height: 4.17277vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: 0vw;
    width: 8vw;
    max-width: 60px;
    height: 8vw;
    max-height: 60px;
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    width: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    width: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    width: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    height: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    height: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    height: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list:before {
    font-size: 4.26667vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
  position: relative;
  padding-top: 68.18%;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 0vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img {
    margin-top: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p em {
  font-style: italic;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p a {
  text-decoration: underline;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol {
  counter-reset: count 0;
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 2.4vw;
  }
}

#recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .recipeListDesc .recipeListArea .list .txt .recipeDescContent ol li {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend {
  margin-bottom: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend {
    margin-bottom: 10.98097vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  margin-bottom: 2.08333vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendTtl {
    margin-bottom: 2.19619vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
  border: 1px solid #CECECE;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  padding-left: 5.72917vw;
  padding-right: 5.72917vw;
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-left: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-left: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-left: 8.05271vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-right: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-right: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding-right: 8.05271vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    border-top-right-radius: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
  margin-top: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding: 8.66667vw;
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding: 65px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding: 4.75842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    padding: 4.75842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list:nth-child(n + 2) {
    margin-top: 4.39239vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
  width: 37.52%;
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    border-top-right-radius: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    width: 66.66667vw;
    max-width: 500px;
    border-top-right-radius: 5.33333vw;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    width: 500px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    width: 36.60322vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    width: 36.60322vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    border-top-right-radius: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .img img {
    border-top-right-radius: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt {
  width: 54.97%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt {
    width: 100%;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt {
    margin-top: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 5.33333vw;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .listItemTtl {
    margin-bottom: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
  margin-bottom: 2.08333vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 5.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p em {
  font-style: italic;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p a {
  text-decoration: underline;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol {
  counter-reset: count 0;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 2.4vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .itemRecommendDesc ol li {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn02 {
  width: 47.61%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn02 {
    width: 100%;
  }
}

#recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn05 {
  width: 47.61%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn05 {
    width: 100%;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn05 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn05 {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .itemRecommend .itemRecommendList .list .txt .linkArea .btn05 {
    margin-top: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .otherArea {
  margin-bottom: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea {
    margin-bottom: 10.98097vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  margin-bottom: 2.08333vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaTtl {
    margin-bottom: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn {
    align-items: center;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
  width: 25.62%;
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    border-top-right-radius: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
  border-top-right-radius: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    width: 37.33333vw;
    max-width: 280px;
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    width: 280px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    width: 20.4978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    width: 20.4978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .img img {
    border-top-right-radius: 5.85652vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
  font-size: 2.6rem;
  font-size: 1.35417vw;
  font-weight: bold;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 3.46667vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    width: 46.66667vw;
    max-width: 350px;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    width: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    width: 25.62225vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    width: 25.62225vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .otherAreaConTtl {
    margin-bottom: 0vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt {
  width: 69.25%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt {
    width: 100%;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
  margin-bottom: 3.125vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-top: 6.66667vw;
    margin-bottom: 9.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p em {
  font-style: italic;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p a {
  text-decoration: underline;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p .aligncenter {
  margin: 0 auto;
  text-align: center;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p .alignright {
  margin: 0 0 0 auto;
  text-align: right;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc p .alignleft {
  margin: 0 auto 0 0;
  text-align: left;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ul li:before {
  content: "";
  width: .3em;
  height: .3em;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: .75em;
  left: 0;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol {
  counter-reset: count 0;
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  position: relative;
  padding-left: 1em;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 2.4vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li:before {
  content: counter(count) ". ";
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .otherAreaConDesc ol li {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
  width: 19.79167vw;
  max-width: 380px;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 380px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 27.81845vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 27.81845vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 72vw;
    max-width: 540px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .otherArea .otherAreaColumn .txt .btn05 {
    width: 39.53148vw;
  }
}

#recipeSingle .recipePostArea .cateRecommend {
  margin-bottom: 8.85417vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 12.4451vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 12.4451vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend {
    margin-bottom: 10.98097vw;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendTtl {
    margin-bottom: 2.92826vw;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList:after {
  content: "";
  width: 30.769%;
  display: block;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList:after {
    content: none;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list {
  width: 30.769%;
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list {
    width: 100%;
  }
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list:not(:last-child) {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list:not(:last-child) {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list:not(:last-child) {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list a {
    display: flex;
    justify-content: space-between;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list a:hover img {
  transform: scale(1.1);
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list a:hover .cateRecommendHead, #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list a:hover .cateRecommendListTtl {
  opacity: .6;
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
  position: relative;
  padding-top: 66.666%;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    width: 46.2%;
    margin-bottom: 0vw;
    padding-top: 30.89%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img {
    margin-bottom: 0vw;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img img {
  position: absolute;
  transition: all .8s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    position: absolute;
    top: 0.52083vw;
    right: 0.52083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    top: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    right: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList {
    right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    width: 6.25vw;
    max-width: 120px;
    text-align: center;
    display: inline-block;
    color: #000;
    background: rgba(255, 255, 255, 0.85);
    font-weight: bold;
    font-size: 1.8rem;
    font-size: 0.9375vw;
    line-height: 1;
    padding: 0.3125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 120px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    padding: 6px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    padding: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    padding: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 18.66667vw;
    max-width: 140px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 0.10417vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    width: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    padding: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    padding: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .img .cateList span {
    padding: 0.14641vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt {
    width: 49.2%;
  }
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    width: 21.33333vw;
    max-width: 160px;
    text-align: center;
    display: inline-block;
    color: #fff;
    background: #c73640;
    font-weight: bold;
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1;
    padding: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    width: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    width: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    padding: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    padding: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateList span {
    padding: 0.58565vw;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
  font-size: 2rem;
  font-size: 1.04167vw;
  transition: all .3s;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    margin-top: 2.66667vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendHead {
    font-size: 3.73333vw;
  }
}

#recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
  font-size: 2.4rem;
  font-size: 1.25vw;
  transition: all .3s;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #recipeSingle .recipePostArea .cateRecommend .cateRecommendList .list .txt .cateRecommendListTtl {
    font-size: 4.26667vw;
  }
}

/* ==============================================
NEWSページ
============================================== */
#aboutTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop {
    padding-top: 14.64129vw;
  }
}

#aboutTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "About Us";
}

#aboutTop .aboutDescArea {
  margin-bottom: 7.8125vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea {
    margin-bottom: 14.64129vw;
  }
}

#aboutTop .aboutDescArea .flex01 {
  position: relative;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 {
    margin-bottom: 4.39239vw;
  }
}

#aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap {
  display: flex;
  justify-content: flex-end;
}

#aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  height: 15.625vw;
  max-height: 300px;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    height: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    height: 21.96193vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    height: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    height: 74.66667vw;
    max-height: 560px;
    margin-right: 8.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    height: 560px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    height: 40.99561vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    height: 40.99561vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    margin-right: 65px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    margin-right: 4.75842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl {
    margin-right: 4.75842vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    margin-top: 2.19619vw;
  }
}

#aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
  content: "Philosophy";
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  color: #c73640;
  writing-mode: horizontal-tb;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .companyPhilosophyTtlWrap .companyPhilosophyTtl:after {
    margin-top: 2.19619vw;
  }
}

#aboutTop .aboutDescArea .flex01 .txt01 {
  padding-bottom: 6.25vw;
  text-align: right;
  width: 37.03125vw;
  max-width: 711px;
  margin-left: auto;
  margin-right: 6.77083vw;
  margin-top: -11.97917vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    width: 711px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    width: 52.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    width: 52.04978vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-right: 130px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-right: 9.51684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-right: 9.51684vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-top: -230px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-top: -16.83748vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-top: -16.83748vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    text-align: left;
    width: 100%;
    max-width: inherit;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0vw;
    margin-top: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 {
    margin-top: 6.58858vw;
  }
}

#aboutTop .aboutDescArea .flex01 .txt01 .txtInner {
  display: inline-block;
  text-align: left;
  display: flex;
  flex-direction: column;
}

#aboutTop .aboutDescArea .flex01 .txt01 .logo {
  width: 12.03125vw;
  max-width: 231px;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 231px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 16.91069vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 16.91069vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 36vw;
    max-width: 270px;
    margin: 0 auto;
    order: 2;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 270px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 19.76574vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    width: 19.76574vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .logo {
    margin-bottom: 5.12445vw;
  }
}

#aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
  font-size: 4.4rem;
  font-size: 2.29167vw;
  font-weight: bold;
  line-height: 2;
  margin-top: 4.6875vw;
  margin-bottom: 2.60417vw;
  letter-spacing: .1em;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 4.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 3.22108vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 5.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-top: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    text-align: center;
    line-height: 1.6;
    margin-top: 0vw;
    margin-bottom: 5.33333vw;
    order: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaTtl {
    margin-bottom: 2.92826vw;
  }
}

#aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 2.25;
  order: 3;
  letter-spacing: .08em;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 2.93333vw;
  }
}

#aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
  margin-top: 2.08333vw;
  order: 4;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
    letter-spacing: .07em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .txt01 .aboutDescAreaRead:nth-of-type(2) {
    margin-top: 3.66032vw;
  }
}

#aboutTop .aboutDescArea .flex01 .img01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33vw;
  height: 40.10417vw;
  max-height: 770px;
  border-top-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    height: 770px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    height: 56.36896vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    height: 56.36896vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    border-top-right-radius: 5.85652vw;
  }
}

#aboutTop .aboutDescArea .flex01 .img01 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    width: 55.73333vw;
    max-width: 418px;
    height: 74.8vw;
    max-height: 561px;
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    width: 418px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    width: 30.60029vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    width: 30.60029vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    height: 561px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    height: 41.06881vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    height: 41.06881vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex01 .img01 img {
    border-top-right-radius: 5.85652vw;
  }
}

#aboutTop .aboutDescArea .flex02 {
  position: relative;
}

@media screen and (min-width: 768px) {
  #aboutTop .aboutDescArea .flex02 {
    padding-bottom: 5.20833vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 {
    padding-bottom: 7.32064vw;
  }
}

#aboutTop .aboutDescArea .flex02 .txt02 {
  width: 33.17708vw;
  max-width: 637px;
  margin-left: 5.72917vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    width: 637px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    width: 46.6325vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    width: 46.6325vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 8.05271vw;
  }
}

#aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 2.25;
  letter-spacing: .045em;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 2.93333vw;
  }
}

#aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
  margin-top: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 0vw;
    width: 100%;
    max-width: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
    letter-spacing: .07em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .txt02 .aboutDescAreaRead:not(:nth-of-type(1)) {
    margin-top: 4.39239vw;
  }
}

#aboutTop .aboutDescArea .flex02 .img02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 38.22vw;
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    height: 22.91667vw;
    max-height: 440px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    height: 440px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    height: 32.21083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    height: 32.21083vw;
  }
}

#aboutTop .aboutDescArea .flex02 .img02 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    position: relative;
    margin-top: 10.66667vw;
    width: 94.66667vw;
    max-width: 710px;
    border-top-left-radius: 10.66667vw;
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    width: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    width: 51.97657vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    width: 51.97657vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescArea .flex02 .img02 img {
    border-top-left-radius: 5.85652vw;
  }
}

#aboutTop .aboutKnowledge {
  margin-bottom: 15.625vw;
  overflow-x: hidden;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 21.96193vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge {
    margin-bottom: 14.64129vw;
  }
}

#aboutTop .aboutKnowledge .aboutKnowledgeTtl {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 2.6rem;
  font-size: 1.35417vw;
  font-weight: bold;
  padding-left: 3.125vw;
  padding-right: 3.125vw;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 3.46667vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-left: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-left: 4.39239vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-right: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-right: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-right: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    writing-mode: horizontal-tb;
    text-orientation: inherit;
    display: inline-block;
    font-size: 4rem;
    font-size: 2.08333vw;
    padding-left: 5.33333vw;
    padding-right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl {
    padding-right: 5.85652vw;
  }
}

#aboutTop .aboutKnowledge .aboutKnowledgeTtl:before {
  content: "";
  position: absolute;
  top: 6.51042vw;
  left: 0;
  width: 100vw;
  height: 1px;
  background: #c73640;
  transform-origin: top left;
  transform: scaleX(-1);
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:before {
    top: 125px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:before {
    top: 9.15081vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:before {
    top: 9.15081vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:before {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}

#aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
  content: "";
  position: absolute;
  top: 6.51042vw;
  right: 0;
  width: 25vw;
  max-width: 480px;
  height: 1px;
  background: #c73640;
  transform-origin: top right;
  transform: scaleX(-1);
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    top: 125px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    top: 9.15081vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    top: 9.15081vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    width: 480px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    width: 35.13909vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    width: 35.13909vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl:after {
    width: 100vw;
    max-width: 100vw;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}

#aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
  display: block;
  font-size: 3rem;
  font-size: 1.5625vw;
  margin-top: 2.60417vw;
  position: relative;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-top: 3.66032vw;
  }
}

#aboutTop .aboutKnowledge .aboutKnowledgeTtl span:before {
  content: "“";
  position: absolute;
  top: -.3em;
  right: -.3em;
}

#aboutTop .aboutKnowledge .aboutKnowledgeTtl span:after {
  content: "”";
  position: absolute;
  bottom: -1em;
  left: -.3em;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 4rem;
    font-size: 2.08333vw;
    margin-top: 0vw;
    margin-left: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span {
    margin-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span:before {
    content: "“";
    position: relative;
    top: inherit;
    right: inherit;
  }
  #aboutTop .aboutKnowledge .aboutKnowledgeTtl span:after {
    content: "”";
    position: relative;
    bottom: inherit;
    left: inherit;
  }
}

#aboutTop .aboutKnowledge .aboutKnowledgeTxt {
  width: 47.5vw;
  max-width: 912px;
  margin-left: 15.625vw;
  margin-top: -3.64583vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.33;
  display: inline-block;
  vertical-align: top;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    width: 912px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    width: 66.76428vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    width: 66.76428vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-left: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-left: 21.96193vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-left: 21.96193vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-top: -70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-top: -5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-top: -5.12445vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-left: 0vw;
    margin-top: 10.66667vw;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutKnowledge .aboutKnowledgeTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) {
  #aboutTop .aboutKnowledge .slider01 {
    margin-top: 15.625vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .slider01 {
    margin-top: 300px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .slider01 {
    margin-top: 21.96193vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .slider01 {
    margin-top: 21.96193vw;
  }
}

@media screen and (min-width: 768px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-left: 0.52083vw;
    padding-right: 0.52083vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-left: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-right: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .slider01 .inner {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) {
  #aboutTop .aboutKnowledge .slider01 .inner img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .sliderArea {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .sliderArea {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .sliderArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .sliderArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-left: 1.33333vw;
    padding-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .sliderArea .inner {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutKnowledge .sliderArea .inner img {
    width: 100%;
  }
  #aboutTop .aboutKnowledge .sliderArea .slider02 {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutKnowledge .sliderArea .slider02 {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutKnowledge .sliderArea .slider02 {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutKnowledge .sliderArea .slider02 {
    margin-bottom: 7.32064vw;
  }
}

#aboutTop .aboutStory {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory {
    margin-bottom: 18.30161vw;
  }
}

#aboutTop .aboutStory .storyIntro {
  position: relative;
}

#aboutTop .aboutStory .aboutStoryTtlArea {
  width: 38.54vw;
  height: 41.66667vw;
  max-height: 800px;
  position: sticky;
  top: 6.25vw;
  left: 0;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    height: 800px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    height: 58.56515vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    height: 58.56515vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    width: 100%;
    height: 100vw;
    max-height: 750px;
    position: inherit;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    height: 750px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    height: 54.90483vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    height: 54.90483vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea {
    margin-bottom: 7.32064vw;
  }
}

#aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner {
  position: relative;
  height: 100%;
}

#aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
  position: absolute;
  height: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 5rem;
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl {
    font-size: 6.66667vw;
  }
}

#aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
  content: "Story";
  letter-spacing: .05em;
  display: inline-block;
  text-align: center;
  writing-mode: horizontal-tb;
  text-orientation: unset;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  color: #fff;
  margin-top: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner .aboutStoryTtl:after {
    margin-top: 2.19619vw;
  }
}

#aboutTop .aboutStory .aboutStoryTtlArea .aboutStoryTtlAreaInner img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

#aboutTop .aboutStory .scrollPoint {
  width: 38.02083vw;
  max-width: 730px;
  margin-top: -41.66667vw;
  margin-left: auto;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .scrollPoint {
    width: 730px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .scrollPoint {
    width: 53.4407vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .scrollPoint {
    width: 53.4407vw;
  }
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .scrollPoint {
    margin-top: -800px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .scrollPoint {
    margin-top: -58.56515vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .scrollPoint {
    margin-top: -58.56515vw;
  }
}

#aboutTop .aboutStory .scrollPoint .txt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.33;
}

@media screen and (min-width: 1367px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .scrollPoint {
    width: 100%;
    height: inherit;
    max-height: inherit;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .scrollPoint {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .scrollPoint {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .scrollPoint {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutStory .scrollPoint .txt {
    font-size: 4.26667vw;
  }
}

/* ==============================================
NEWSページ
============================================== */
#companyTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #companyTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop {
    padding-top: 14.64129vw;
  }
}

#companyTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "Company";
}

#companyTop .companyMessageArea {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea {
    margin-bottom: 14.64129vw;
  }
}

#companyTop .companyMessageArea .companyMessageTtlWrap {
  margin-bottom: -18.75vw;
}

@media screen and (min-width: 768px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: -360px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: -26.35432vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: -26.35432vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap {
    margin-bottom: 10.98097vw;
  }
}

#companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 768px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    height: 18.75vw;
    max-height: 360px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    height: 360px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    height: 26.35432vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    height: 26.35432vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    height: inherit;
    max-height: inherit;
    writing-mode: horizontal-tb;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    margin-top: 0vw;
  }
}

#companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
  content: "Message";
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  color: #c73640;
  writing-mode: horizontal-tb;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageTtlWrap .companyMessageTtl:after {
    margin-top: 2.19619vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

#companyTop .companyMessageArea .companyMessageFlex .img01 {
  position: relative;
  width: 50%;
}

#companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
  border-top-right-radius: 4.16667vw;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 5.85652vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
  : 4.16667vw;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    width: 94.66667vw;
    max-width: 710px;
    margin-left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    width: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    width: 51.97657vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    width: 51.97657vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    margin-left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    margin-left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 {
    margin-left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner {
    border-top-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .inner img {
    : 5.85652vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  line-height: 2.33;
  position: absolute;
  top: 7.29167vw;
  right: -10.41667vw;
  z-index: 5;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    top: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    top: 10.2489vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    right: -200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    right: -14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    right: -14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 3.6rem;
    font-size: 1.875vw;
    writing-mode: vertical-rl;
    text-orientation: upright;
    top: -17.33333vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    top: -130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    top: -9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    top: -9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img01 .companyMessageCopy {
    right: 2.92826vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt01 {
  width: 41.94%;
  margin-top: 18.75vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    margin-top: 360px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    margin-top: 26.35432vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    margin-top: 26.35432vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    width: 100%;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 {
    margin-top: 2.92826vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
  font-size: 3rem;
  font-size: 1.5625vw;
  margin-bottom: 3.64583vw;
  font-weight: bold;
  text-align: right;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    margin-bottom: 5.12445vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  display: inline-block;
  margin-right: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    margin-right: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    margin-right: 2.19619vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
  content: "";
  width: 2.60417vw;
  max-width: 50px;
  height: 1px;
  background: #c73640;
  display: inline-block;
  margin-right: 1.5625vw;
  vertical-align: middle;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    margin-right: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 6.66667vw;
    max-width: 50px;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    width: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageCeo span:before {
    margin-right: 2.19619vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.33;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 3.66032vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
  margin-top: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt01 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 2.92826vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .img02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 31.25vw;
  height: 31.25vw;
  max-height: 600px;
}

#companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
  border-top-left-radius: 4.16667vw;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 5.85652vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
  border-top-left-radius: 4.16667vw;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    position: relative;
    width: 89.33333vw;
    max-width: 670px;
    height: inherit;
    max-height: inherit;
    transform: translate(5.3333vw, 0);
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    width: 670px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    width: 49.04832vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    width: 49.04832vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .img02 .inner img {
    border-top-left-radius: 5.85652vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt02 {
  width: 68.3%;
  margin-top: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    margin-top: 7.32064vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.33;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 2.4vw;
  }
}

#companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
  margin-top: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    width: 100%;
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyMessageArea .companyMessageFlex .txt02 .companyMessageDesc:not(:nth-of-type(1)) {
    margin-top: 3.66032vw;
  }
}

#companyTop .companyInfoArea {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea {
    margin-bottom: 18.30161vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 18.66667vw;
    justify-content: center;
    display: block;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (min-width: 768px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    position: sticky;
    top: 6.25vw;
    height: 15.625vw;
    max-height: 300px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    top: 120px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 300px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 21.96193vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 53.33333vw;
    max-height: 400px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 400px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 29.28258vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtlWrap {
    height: 29.28258vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    margin-top: 2.19619vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
  content: "Company\Ainfo";
  white-space: pre;
  text-align: center;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  color: #c73640;
  writing-mode: horizontal-tb;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoTtl:after {
    margin-top: 2.19619vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoList {
  width: 76.79%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList {
    width: 100%;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
  width: 18.39%;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    margin-bottom: 3.66032vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1;
  padding-left: 1.82292vw;
  padding-top: 0.67708vw;
  padding-bottom: 0.67708vw;
  border-left: 1px solid #c73640;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-left: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-left: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-left: 2.56223vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-top: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    width: 100%;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-left: 5.33333vw;
    padding-top: 1.06667vw;
    padding-bottom: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-top: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-top: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-top: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dt .companyInfoListTtl {
    padding-bottom: 0.58565vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
  width: 81.61%;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    margin-bottom: 3.66032vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 2.4vw;
  }
}

#companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
  margin-top: 2.08333vw;
  width: 100%;
  height: 23.95833vw;
  max-height: 460px;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    height: 460px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    height: 33.67496vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    height: 33.67496vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    width: 100%;
    padding-left: 5.33333vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd .companyInfoListDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 4vw;
    height: 61.33333vw;
    max-height: 460px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    height: 460px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    height: 33.67496vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoMain .companyInfoList dd iframe {
    height: 33.67496vw;
  }
}

#companyTop .companyInfoArea .companyInfoAreaImg {
  position: relative;
  height: 21.35417vw;
  max-height: 410px;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 410px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 30.01464vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 30.01464vw;
  }
}

#companyTop .companyInfoArea .companyInfoAreaImg .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(0);
}

#companyTop .companyInfoArea .companyInfoAreaImg .bgImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(/images/company/company_info_anime_img.jpg);
  background-size: cover;
  background-position: center;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 100vw;
    max-height: 750px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 750px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 54.90483vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyInfoAreaImg {
    height: 54.90483vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyInfoAreaImg .bgImg {
    background-image: url(/images/company/company_info_anime_img_sp.jpg);
    background-size: cover;
  }
}

#companyTop .companyInfoArea .contactLink {
  margin-top: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink {
    margin-top: 5.85652vw;
  }
}

#companyTop .companyInfoArea .contactLink .contactLinkTtl {
  text-align: center;
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 4rem;
    font-size: 2.08333vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkTtl {
    margin-bottom: 5.85652vw;
  }
}

#companyTop .companyInfoArea .contactLink .contactLinkDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.33;
  margin-bottom: 2.60417vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    text-align: left;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .contactLinkDesc {
    margin-bottom: 5.85652vw;
  }
}

#companyTop .companyInfoArea .contactLink .btn04 {
  width: 18.22917vw;
  max-width: 350px;
  margin: 0 auto;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 25.62225vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 72vw;
    max-width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .contactLink .btn04 {
    width: 39.53148vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea {
  margin-top: 10.41667vw;
  position: relative;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 29.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 220px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 16.10542vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea {
    margin-top: 16.10542vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
  position: relative;
  text-align: center;
  padding-top: 3.69792vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 71px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 5.19766vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 5.19766vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 52.29167vw;
  max-width: 1004px;
  height: 52.29167vw;
  max-height: 1004px;
  background: #FAF6F4;
  border-radius: 50%;
  z-index: -10;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 1004px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 73.49927vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 73.49927vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    height: 1004px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    height: 73.49927vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    height: 73.49927vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 8vw;
    padding-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 83.46667vw;
    max-width: 626px;
    height: 83.46667vw;
    max-height: 626px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 626px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 45.82723vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    width: 45.82723vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    height: 626px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    height: 45.82723vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:before {
    height: 45.82723vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    content: "";
    background: #FAF6F4;
    position: absolute;
    top: 21.33333vw;
    left: -5.33333vw;
    width: 100vw;
    height: 100%;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea:after {
    left: -2.92826vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
  font-size: 4rem;
  font-size: 2.08333vw;
  color: #c73640;
  font-weight: bold;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 5rem;
    font-size: 2.60417vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 28.13333vw;
    max-width: 211px;
    height: 11.73333vw;
    max-height: 88px;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 211px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 15.44656vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 15.44656vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    height: 88px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    height: 6.44217vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    height: 6.44217vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    margin-bottom: 3.66032vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
  content: "";
  background: url(/images/company/company_red_icon.png) center center no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 7.55208vw;
  max-width: 145px;
  height: 3.17708vw;
  max-height: 61px;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 145px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 10.61493vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    width: 10.61493vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    height: 61px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    height: 4.46559vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    height: 4.46559vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaTtl:before {
    margin-bottom: 2.19619vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    line-height: 1.6;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .companyStoryListAreaTtlArea .companyStoryListAreaDesc {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    position: sticky;
    top: 13.33333vw;
    padding: 5.33333vw;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    padding: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap {
    padding: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap:before {
    content: "";
    background: #EDE1D9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    left: -5.33333vw;
    z-index: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap nav {
    overflow-x: hidden;
  }
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList {
    overflow-x: hidden;
    display: flex;
  }
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    position: relative;
    z-index: 1;
    display: inline-block;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    padding-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    padding-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    padding-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList {
    padding-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList a {
    font-size: 5rem;
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList a {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList a {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList a {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList a {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .subNavList .pageLinkList.active a {
    color: #c73640;
  }
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    left: 0;
    background: url(/images/company/company_story_prev_arrow.svg) center center no-repeat;
    background-size: contain;
    width: 2.66667vw;
    max-width: 20px;
    height: 5.33333vw;
    max-height: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    width: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    width: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    width: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    height: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    height: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.prev {
    height: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    right: 0;
    background: url(/images/company/company_story_next_arrow.svg) center center no-repeat;
    background-size: contain;
    width: 2.66667vw;
    max-width: 20px;
    height: 5.33333vw;
    max-height: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    width: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    width: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    width: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    height: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    height: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .subNavListWrap .buttons .button.next {
    height: 2.92826vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .listWrap {
  position: relative;
  z-index: -10;
}

#companyTop .companyInfoArea .companyStoryListArea .listWrap:before {
  content: "";
  background: #FAF6F4;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 100%;
  z-index: -2;
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
  padding-top: 5.20833vw;
  padding-bottom: 5.20833vw;
  padding-left: 7.29167vw;
  padding-right: 7.29167vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-top: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-left: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-left: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-left: 10.2489vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-right: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-right: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-right: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    z-index: -1;
    padding-top: 0vw;
    padding-bottom: 13.33333vw;
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list .monthInner {
    opacity: .3;
    transition: opacity .3s;
  }
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list.current .monthInner {
    opacity: 1;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
  padding-bottom: 3.64583vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:not(:last-child) dl .month {
    padding-bottom: 4.39239vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
  padding-right: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-top: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:first-child dl .month {
    padding-top: 5.12445vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
  padding-bottom: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list:last-child dl .month {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4 {
    position: relative;
  }
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    content: "";
    background: url(/images/company/company_story_list_icon01.svg) center center no-repeat;
    background-size: contain;
    width: 6.19792vw;
    max-width: 119px;
    height: 5.10417vw;
    max-height: 98px;
    position: absolute;
    right: -6.19792vw;
    top: -5.10417vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    width: 119px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    width: 8.71157vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    width: 8.71157vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    height: 98px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    height: 7.17423vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    height: 7.17423vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    right: -119px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    right: -8.71157vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    right: -8.71157vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    top: -98px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    top: -7.17423vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:before {
    top: -7.17423vw;
  }
}

@media screen and (min-width: 768px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    content: "";
    background: url(/images/company/company_story_list_icon02.svg) center center no-repeat;
    background-size: contain;
    width: 5.9375vw;
    max-width: 114px;
    height: 7.5vw;
    max-height: 144px;
    position: absolute;
    left: -7.29167vw;
    top: 6.25vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    width: 114px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    width: 8.34553vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    width: 8.34553vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    height: 144px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    height: 10.54173vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    height: 10.54173vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    left: -140px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    left: -10.2489vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    left: -10.2489vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    top: 120px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index4:after {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7 {
    position: relative;
  }
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    content: "";
    background: url(/images/company/company_story_list_icon03.svg) center center no-repeat;
    background-size: contain;
    width: 5.26042vw;
    max-width: 101px;
    height: 6.92708vw;
    max-height: 133px;
    position: absolute;
    right: 0vw;
    bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    width: 101px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    width: 7.39385vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    width: 7.39385vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    height: 133px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    height: 9.73646vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    height: 9.73646vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    right: 0px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    right: 0vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    right: 0vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list#index7:before {
    bottom: 0vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl {
  display: flex;
  align-items: baseline;
}

@media screen and (min-width: 768px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .age {
    width: 8.839%;
    font-size: 3rem;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .age {
    font-size: 3rem;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .age {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .age {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .age {
    font-size: 4vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
  width: 91.161%;
  border-left: 1px solid #707070;
  padding-top: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    width: 100%;
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month {
    padding-top: 0vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner {
  display: flex;
  align-items: flex-start;
  padding-left: 2.52%;
  flex-wrap: wrap;
  position: relative;
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 2.19619vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.9375vw;
  transform: translate(-50%, -50%);
  width: 0.52083vw;
  max-width: 10px;
  height: 0.52083vw;
  max-height: 10px;
  background: #c73640;
  border-radius: 50%;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 1.31772vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    width: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    width: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    width: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    height: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    height: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:not(:nth-of-type(1)) {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 4.26667vw;
    width: 2vw;
    max-width: 15px;
    height: 2vw;
    max-height: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    top: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    width: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    height: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    height: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner:before {
    height: 1.0981vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
  width: 6.53%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    width: 100%;
    margin-bottom: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthTtl {
    margin-bottom: 2.19619vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap {
  width: 93.47%;
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 2.4vw;
  }
}

#companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
  margin-top: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap {
    width: 100%;
  }
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #companyTop .companyInfoArea .companyStoryListArea .aboutStoryListWrap .list dl .month .monthInner .monthDescWrap .monthDesc:not(:nth-of-type(1)) {
    margin-top: 2.34261vw;
  }
}

/* ==============================================
404ページ
============================================== */
#notFoundTop {
  padding-top: 14.84375vw;
}

@media screen and (min-width: 1367px) {
  #notFoundTop {
    padding-top: 285px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop {
    padding-top: 20.86384vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop {
    padding-top: 20.86384vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop {
    padding-top: 14.64129vw;
  }
}

#notFoundTop .notFoundArea {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 18.30161vw;
  }
}

#notFoundTop .notFoundArea .notFoundAreaTtl {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  text-align: center;
  line-height: 2;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    margin-bottom: 4.39239vw;
  }
}

#notFoundTop .notFoundArea .notFoundAreaTtl:before {
  content: "";
  background: url(/images/404/404_icon.svg) center center no-repeat;
  background-size: contain;
  width: 10.41667vw;
  max-width: 200px;
  height: 9.11458vw;
  max-height: 175px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.82292vw;
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 14.64129vw;
  }
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    height: 175px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    height: 12.81113vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    height: 12.81113vw;
  }
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    margin-bottom: 2.56223vw;
  }
}

#notFoundTop .notFoundArea .notFoundAreaDesc {
  font-size: 2.4rem;
  font-size: 1.25vw;
  line-height: 2.25;
  margin-bottom: 6.25vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    margin-bottom: 8.78477vw;
  }
}

#notFoundTop .notFoundArea .notFoundAreaDesc a {
  font-size: 2.4rem;
  font-size: 1.25vw;
  line-height: 2.25;
}

@media screen and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 3.2vw;
  }
}

#notFoundTop .notFoundArea .btn01 {
  text-align: center;
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 26.66667vw;
    max-width: 200px;
    height: 23.33333vw;
    max-height: 175px;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    width: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    height: 175px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    height: 12.81113vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    height: 12.81113vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaTtl:before {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    text-align: left;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 2.25;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundAreaDesc a {
    font-size: 3.2vw;
  }
}

/* ==============================================
404ページ
============================================== */
#policyTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #policyTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop {
    padding-top: 14.64129vw;
  }
}

#policyTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "privacy\Apolicy";
  white-space: pre;
}

#policyTop .privacyArea {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea {
    margin-bottom: 14.64129vw;
  }
}

#policyTop .privacyArea .privacyParagraph {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 2.4vw;
  }
}

#policyTop .privacyArea .privacyParagraph a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  word-break: break-all;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    line-height: 2;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    margin-top: 0.73206vw;
  }
}

#policyTop .privacyArea .privacyParagraph.multipleRow {
  line-height: 2.88;
  margin-top: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyParagraph.multipleRow {
    margin-top: 0.73206vw;
  }
}

#policyTop .privacyArea .privacyList {
  counter-reset: count 0;
  margin-top: 3.64583vw;
  margin-bottom: 4.6875vw;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 5.12445vw;
  }
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList {
    margin-bottom: 6.58858vw;
  }
}

#policyTop .privacyArea .privacyList li {
  position: relative;
  padding-left: 1.7em;
}

#policyTop .privacyArea .privacyList li:before {
  content: counter(count) ".";
  counter-increment: count 1;
  position: absolute;
  top: 0em;
  left: 0;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 2.4vw;
  }
}

#policyTop .privacyArea .privacyList li:not(:last-child) {
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 9.33333vw;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList {
    margin-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #policyTop .privacyArea .privacyList li:before {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .privacyList li:not(:last-child) {
    margin-bottom: 2.92826vw;
  }
}

#policyTop .privacyArea .inner .privacyTtl {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .inner .privacyTtl {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .inner .privacyTtl {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .inner .privacyTtl {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #policyTop .privacyArea .inner .privacyTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .inner .privacyTtl {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .inner .privacyTtl {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .inner .privacyTtl {
    margin-bottom: 1.83016vw;
  }
}

#policyTop .privacyArea .inner:not(:last-child) {
  margin-bottom: 4.6875vw;
}

@media screen and (min-width: 1367px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #policyTop .privacyArea .inner:not(:last-child) {
    margin-bottom: 6.58858vw;
  }
}

/* ==============================================
お問合せページ
============================================== */
#contactTop {
  padding-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #contactTop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop {
    padding-top: 14.64129vw;
  }
}

#contactTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
  content: "Contact Us";
}

@media screen and (max-width: 767px) {
  #contactTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 20vw;
    max-width: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .nonIndexMainVisual .nonIndexMainVisualTtl:after {
    width: 10.98097vw;
  }
}

#contactTop .contactForm {
  margin-bottom: 13.02083vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm {
    margin-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm {
    margin-bottom: 18.30161vw;
  }
}

#contactTop .contactForm .contactFormTit {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  margin-bottom: 5.20833vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTit {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTit {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTit {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .contactFormTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTit {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTit {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTit {
    margin-bottom: 7.32064vw;
  }
}

#contactTop .contactForm .contactFormTitSub {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: bold;
  margin-bottom: 5.20833vw;
  text-align: center;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTitSub {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTitSub {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTitSub {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .contactFormTitSub {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTitSub {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTitSub {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTitSub {
    margin-bottom: 7.32064vw;
  }
}

#contactTop .contactForm .contactFormTxt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.33;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxt {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxt {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxt {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.9;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxt {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxt {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxt {
    margin-bottom: 10.98097vw;
  }
}

#contactTop .contactForm .contactFormTxt.centerTxt {
  text-align: center;
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxt.centerTxt {
    text-align: left;
  }
}

#contactTop .contactForm .contactFormTxtAttention {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  margin-bottom: 3.125vw;
  color: #c73640;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxtAttention {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxtAttention {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxtAttention {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .contactFormTxtAttention {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .contactFormTxtAttention {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .contactFormTxtAttention {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .contactFormTxtAttention {
    margin-bottom: 7.32064vw;
  }
}

#contactTop .contactForm .attention {
  color: #c73640;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  margin-top: .62em;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .attention {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .attention {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .attention {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .attention {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .attention {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-left: 1em;
    text-indent: -1em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .attention {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .attention {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .attention {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .attention {
    font-size: 3.73333vw;
  }
}

#contactTop .contactForm .errorTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  color: #c73640;
  margin-top: .7em;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .errorTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .errorTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .errorTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .errorTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .errorTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .errorTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .errorTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .errorTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .errorTxt {
    font-size: 3.73333vw;
  }
}

#contactTop .contactForm dl {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dl {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dl {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dl {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dl {
    display: block;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dl {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dl {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dl {
    margin-bottom: 8.78477vw;
  }
}

#contactTop .contactForm dt {
  width: 19.47%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dt {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt {
    margin-bottom: 4.39239vw;
  }
}

#contactTop .contactForm dt:first-of-type {
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 2.92826vw;
  }
}

#contactTop .contactForm dt:last-of-type {
  margin-bottom: 0;
}

#contactTop .contactForm dt label {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  padding-top: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dt label {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt label {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt label {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt label {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dt label {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt label {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt label {
    padding-top: 1.31772vw;
  }
}

#contactTop .contactForm dt span {
  color: #c73640;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  padding-left: .5em;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dt span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt span {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt span {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt {
    width: 100%;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm dt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dt {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt:first-of-type {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt:last-of-type {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dt:last-of-type {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt:last-of-type {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt:last-of-type {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt label {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dt label {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt label {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt label {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm dt label {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dt span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dt span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dt span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dt span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm dt span {
    font-size: 4.26667vw;
  }
}

#contactTop .contactForm dd {
  width: 80.53%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dd {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dd {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dd {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd {
    margin-bottom: 4.39239vw;
  }
}

#contactTop .contactForm dd:first-of-type {
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 2.92826vw;
  }
}

#contactTop .contactForm dd:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dd {
    width: 100%;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dd {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm dd {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dd {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd:first-of-type {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm dd:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm dd:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm dd:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm dd:last-of-type {
    margin-bottom: 0vw;
  }
}

#contactTop .contactForm input,
#contactTop .contactForm textarea {
  display: block;
  max-width: 100%;
  width: 100%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  border: solid 1px #DDDDDD;
  outline: none;
  padding-top: 0.9375vw;
  padding-bottom: 0.9375vw;
  padding-right: 1.5625vw;
  padding-left: 1.5625vw;
  appearance: none;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 2.4vw;
  }
}

#contactTop .contactForm input::placeholder,
#contactTop .contactForm textarea::placeholder {
  color: #AFB3B7;
}

#contactTop .contactForm input:-ms-input-placeholder,
#contactTop .contactForm textarea:-ms-input-placeholder {
  color: #AFB3B7;
}

#contactTop .contactForm input::-ms-input-placeholder,
#contactTop .contactForm textarea::-ms-input-placeholder {
  color: #AFB3B7;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-bottom: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-bottom: 1.31772vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-left: 2.19619vw;
  }
}

#contactTop .contactForm input::placeholder,
#contactTop .contactForm textarea::placeholder {
  font-weight: normal;
}

#contactTop .contactForm input.error,
#contactTop .contactForm textarea.error {
  background-color: #F7E1E2;
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 3.33333vw;
    padding-bottom: 3.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-top: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm input,
  #contactTop .contactForm textarea {
    padding-left: 2.92826vw;
  }
}

#contactTop .contactForm textarea {
  resize: vertical;
  height: 14.0625vw;
  max-height: 270px;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm textarea {
    height: 270px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm textarea {
    height: 19.76574vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm textarea {
    height: 19.76574vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm textarea {
    height: 73.33333vw;
    max-height: 550px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm textarea {
    height: 550px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm textarea {
    height: 40.26354vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm textarea {
    height: 40.26354vw;
  }
}

#contactTop .contactForm input[type="radio"],
#contactTop .contactForm input[type="checkbox"] {
  opacity: 0.01;
  position: absolute;
  width: 20px;
  padding: 9px;
  margin-top: 2px;
}

#contactTop .contactForm input[type="radio"]:checked + span::after,
#contactTop .contactForm input[type="checkbox"]:checked + span::after {
  transition: .3s;
  transform: scale(1);
  background: #4d4d4d;
}

#contactTop .contactForm input[type="radio"]:checked + .radioTit::after,
#contactTop .contactForm input[type="checkbox"]:checked + .radioTit::after {
  transition: .3s;
  transform: scale(1);
  background: #4d4d4d;
}

#contactTop .contactForm input[type="radio"]:checked + .checkboxTxt::after,
#contactTop .contactForm input[type="checkbox"]:checked + .checkboxTxt::after {
  transition: .3s;
  transform: scale(1);
  background: #4d4d4d;
}

#contactTop .contactForm .radioArea {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.9375vw;
  padding-bottom: 0.9375vw;
  padding-left: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .radioArea {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioArea {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioArea {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .radioArea {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioArea {
    padding-bottom: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioArea {
    padding-bottom: 1.31772vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .radioArea {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioArea {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioArea {
    padding-left: 2.19619vw;
  }
}

#contactTop .contactForm .radioArea.error {
  background-color: #F7E1E2;
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .radioArea {
    padding: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioArea {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioArea {
    padding: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioArea {
    padding: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .radioArea label:nth-of-type(1) {
    width: 50%;
  }
  #contactTop .contactForm .radioArea label:nth-of-type(2) {
    width: 50%;
  }
  #contactTop .contactForm .radioArea label:nth-of-type(3) {
    width: 100%;
  }
  #contactTop .contactForm .radioArea label:nth-of-type(n + 3) {
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioArea label:nth-of-type(n + 3) {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioArea label:nth-of-type(n + 3) {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioArea label:nth-of-type(n + 3) {
    margin-top: 1.46413vw;
  }
}

#contactTop .contactForm label {
  display: block;
}

#contactTop .contactForm .radioTit {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  margin-right: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .radioTit {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit {
    margin-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit {
    margin-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit {
    padding-left: 28px;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .radioTit {
    padding-left: 6.66667vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit {
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit {
    margin-right: 0vw;
  }
}

#contactTop .contactForm .radioTit::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 25px;
  height: 25px;
  border: 1px solid #707070;
  background: #fff;
  border-radius: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::before {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .radioTit::before {
    margin: auto 0;
    top: 0;
    bottom: 0;
    width: 5.33333vw;
    max-width: 40px;
    height: 5.33333vw;
    max-height: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit::before {
    width: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit::before {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::before {
    width: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit::before {
    height: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit::before {
    height: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::before {
    height: 2.92826vw;
  }
}

#contactTop .contactForm .radioTit::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  margin: auto 0;
  width: 19px;
  height: 19px;
  transform: scale(0.5);
  border-radius: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::after {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .radioTit::after {
    margin: auto 0;
    top: 0;
    bottom: 0;
    width: 4.26667vw;
    max-width: 32px;
    height: 4.26667vw;
    max-height: 32px;
    left: 0.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit::after {
    width: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit::after {
    width: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::after {
    width: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit::after {
    height: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit::after {
    height: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::after {
    height: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .radioTit::after {
    left: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .radioTit::after {
    left: 0.43924vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .radioTit::after {
    left: 0.43924vw;
  }
}

#contactTop .contactForm .privacyPolicy {
  text-align: center;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy {
    text-align: left;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy {
    margin-bottom: 10.98097vw;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea label {
  align-items: center;
  position: relative;
}

#contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label:not(:last-of-type) {
    margin-bottom: 2.92826vw;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
  flex-shrink: 0;
  position: relative;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  cursor: pointer;
  padding-left: 30px;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) {
    font-size: 4.26667vw;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class])::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 16px;
  height: 16px;
  border: 1px solid #95989A;
  background: #fff;
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class])::before {
    width: 20px;
    height: 20px;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class])::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  margin: auto 0;
  width: 12px;
  height: 12px;
  transform: scale(0.5);
  z-index: 1;
  border: none;
  appearance: none;
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class])::after {
    width: 16px;
    height: 16px;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  text-decoration: underline;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label span:not([class]) a {
    font-size: 4.26667vw;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
  margin-top: 1.5625vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 5.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactForm .privacyPolicy .checkboxArea label .stamp {
    font-size: 3.73333vw;
  }
}

#contactTop .contactForm .privacyPolicy .checkboxArea input[type="checkbox"] {
  opacity: 0.01;
  position: absolute;
  width: 20px;
  padding: 9px;
  margin-top: 2px;
}

#contactTop .contactForm .privacyPolicy .checkboxArea input[type="checkbox"]:checked + span::after {
  transition: .3s;
  transform: scale(1);
  background: #333;
}

@media screen and (min-width: 768px) {
  #contactTop .contactForm.contactFormConf dl dt:nth-of-type(1) {
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #contactTop .contactForm.contactFormConf dl dt:nth-of-type(1) {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm.contactFormConf dl dt:nth-of-type(1) {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm.contactFormConf dl dt:nth-of-type(1) {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) {
  #contactTop .contactForm.contactFormConf dl dd:nth-of-type(1) {
    margin-bottom: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1367px) {
  #contactTop .contactForm.contactFormConf dl dd:nth-of-type(1) {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactForm.contactFormConf dl dd:nth-of-type(1) {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactForm.contactFormConf dl dd:nth-of-type(1) {
    margin-bottom: 4.39239vw;
  }
}

#contactTop .sendBtn {
  width: 18.22917vw;
  max-width: 350px;
  margin: 0 auto;
}

@media screen and (min-width: 1367px) {
  #contactTop .sendBtn {
    width: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn {
    width: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn {
    width: 25.62225vw;
  }
}

#contactTop .sendBtn button {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 1;
  padding-top: 1.35417vw;
  padding-bottom: 1.35417vw;
  background: #484848;
  color: #fff;
  border: 1px solid #484848;
  transition: all .5s;
}

@media screen and (min-width: 1367px) {
  #contactTop .sendBtn button {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn button {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn button {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .sendBtn button {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .sendBtn button {
    padding-top: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn button {
    padding-top: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn button {
    padding-top: 1.90337vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .sendBtn button {
    padding-bottom: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn button {
    padding-bottom: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn button {
    padding-bottom: 1.90337vw;
  }
}

#contactTop .sendBtn button:disabled {
  background: #ccc !important;
  color: #fff !important;
  border: 1px solid #ccc !important;
}

#contactTop .sendBtn button:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

@media screen and (max-width: 767px) {
  #contactTop .sendBtn {
    width: 72vw;
    max-width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .sendBtn {
    width: 540px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn {
    width: 39.53148vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .sendBtn button {
    font-weight: bold;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-top: 4.8vw;
    padding-bottom: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .sendBtn button {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn button {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn button {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .sendBtn button {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .sendBtn button {
    padding-top: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn button {
    padding-top: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn button {
    padding-top: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .sendBtn button {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .sendBtn button {
    padding-bottom: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .sendBtn button {
    padding-bottom: 2.63543vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .sendBtn button:hover {
    cursor: pointer;
  }
}

#contactTop .contactConfirmBtn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #contactTop .contactConfirmBtn {
    justify-content: space-between;
  }
}

#contactTop .contactConfirmBtn li {
  width: 18.22917vw;
  max-width: 350px;
  margin-left: 2.08333vw;
  margin-right: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li {
    width: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li {
    width: 25.62225vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li {
    width: 25.62225vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li {
    margin-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li {
    margin-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li {
    margin-left: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li {
    margin-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li {
    margin-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactConfirmBtn li {
    width: 42.66667vw;
    max-width: 320px;
    margin-left: 0vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li {
    width: 320px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li {
    width: 23.42606vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li {
    width: 23.42606vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li {
    margin-right: 0vw;
  }
}

#contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 1.35417vw;
  padding-bottom: 1.35417vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 1;
  transition: all .5s;
}

@media screen and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 1.90337vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-bottom: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-bottom: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-bottom: 1.90337vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 4.8vw;
    padding-bottom: 4.8vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-top: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-bottom: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    padding-bottom: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .contactConfirmBtn li button, #contactTop .contactConfirmBtn li a {
    font-size: 3.73333vw;
  }
}

#contactTop .contactConfirmBtn li.contactConfirmBtnBack a {
  color: #fff;
  background: #9A9A9A;
  border: 1px solid #9A9A9A;
}

#contactTop .contactConfirmBtn li.contactConfirmBtnBack a:hover {
  color: #000;
  background: #fff;
  border: 1px solid #000;
}

#contactTop .contactConfirmBtn li.contactConfirmBtnSubmit button {
  border: 1px solid #484848;
  color: #fff;
  background: #484848;
}

#contactTop .contactConfirmBtn li.contactConfirmBtnSubmit button:hover {
  cursor: pointer;
  color: #000;
  background: #fff;
  border: 1px solid #000;
}

#contactTop .btn01 {
  text-align: center;
}

#contactTop .telContactArea {
  background: #FAF6F4;
  border-top-left-radius: 4.16667vw;
  border-bottom-right-radius: 4.16667vw;
  padding-top: 4.16667vw;
  padding-bottom: 4.6875vw;
  padding-left: 4.16667vw;
  padding-right: 4.16667vw;
  margin-top: 10.41667vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    border-top-left-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    border-bottom-right-radius: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-left: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-left: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-left: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-right: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-right: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea {
    border-top-left-radius: 10.66667vw;
    border-bottom-right-radius: 10.66667vw;
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    margin-top: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    border-top-left-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    border-top-left-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    border-bottom-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    border-bottom-right-radius: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea {
    margin-top: 10.98097vw;
  }
}

#contactTop .telContactArea .telContactAreaTtl {
  text-align: center;
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .telContactAreaTtl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .telContactAreaTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .telContactAreaTtl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .telContactArea .telContactAreaTtl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .telContactAreaTtl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .telContactAreaTtl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .telContactAreaTtl {
    margin-bottom: 5.85652vw;
  }
}

#contactTop .telContactArea .conInner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

#contactTop .telContactArea .conInner .tel {
  width: 50%;
  padding-right: 2.34375vw;
  text-align: right;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .tel {
    padding-right: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .tel {
    padding-right: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .tel {
    padding-right: 3.29429vw;
  }
}

#contactTop .telContactArea .conInner .tel a {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 5.33333vw;
  }
}

#contactTop .telContactArea .conInner .tel a:before {
  content: "";
  background: url(/images/contact/contact_tel_icon.svg) center center no-repeat;
  background-size: contain;
  width: .875em;
  height: .875em;
  display: inline-block;
  margin-right: .375em;
}

@media screen and (min-width: 768px) {
  #contactTop .telContactArea .conInner .tel a[href^="tel:"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .tel {
    width: 100%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .tel {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .tel {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .tel {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 4.8rem;
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .telContactArea .conInner .tel a {
    font-size: 6.4vw;
  }
}

#contactTop .telContactArea .conInner .info {
  width: 50%;
  padding-left: 2.34375vw;
  border-left: 1px solid #000;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info {
    padding-left: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info {
    padding-left: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info {
    padding-left: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info {
    width: inherit;
    padding-left: 0;
    border-left: none;
    display: inline-block;
  }
}

#contactTop .telContactArea .conInner .info .list {
  display: flex;
}

#contactTop .telContactArea .conInner .info .list .ttl {
  width: 6.5em;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 2.4vw;
  }
}

#contactTop .telContactArea .conInner .info .list .ttl span {
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 2.4vw;
  }
}

#contactTop .telContactArea .conInner .info .list .desc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .ttl {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .ttl span {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contactTop .telContactArea .conInner .info .list .desc {
    font-size: 4.26667vw;
  }
}
