@charset "utf-8";
/* ==============================================
404ページ
============================================== */
#policyTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
		  &:after{
			content: "privacy\Apolicy";
			white-space:pre;
		  }
		}
	}

	.privacyArea{
		@include props(mb,250);

		@include mq(md){
			@include props(mb,200,750);
		}

		.privacyParagraph{
			@include fz_vw(18);
			line-height: 2;

			a{
				@include fz_vw(18);
				line-height: 2;
				word-break: break-all;
			}

			@include mq(md){
				@include fz_vw(32);

				a{
					@include fz_vw(32);
				}

				&.multipleRow{
					line-height: 2;
					@include props(mt,10,750);
				}
			}

			&.multipleRow{
				line-height: 2.88;
				@include props(mt,10);
			}
		}

		.privacyList{
			counter-reset: count 0;
			@include props(mt,70);
			@include props(mb,90);
			li{
				position: relative;
				padding-left: 1.7em;
				&:before{
					content: counter(count) ".";
					counter-increment: count 1;
					position: absolute;
					top: 0em;
					left: 0;
					@include fz_vw(18);
					line-height: 2;
				}

				&:not(:last-child){
					@include props(mb,40);
				}
			}

			@include mq(md){
				@include props(mt,70,750);
				@include props(mb,90,750);

				li{
					&:before{
						@include fz_vw(32);
					}
					&:not(:last-child){
						@include props(mb,40,750);
					}
				}
			}
		}

		.inner{
			.privacyTtl{
				@include fz_vw(18);
				font-weight: bold;
				@include props(mb,25);

				@include mq(md){
					@include fz_vw(36);
					@include props(mb,25,750);
				}
			}
			&:not(:last-child){
				@include props(mb,90);

				@include mq(md){
					@include props(mb,90,750);
				}
			}
		}
	}
}