@charset "utf-8";
/* ==============================================
productsページ
============================================== */

#productsTop{
	@include props(pt,200);
	@include mq(md){
		@include props(pt,200,750);
	}
	.nonIndexMainVisual{
		.nonIndexMainVisualTtl{
		  &:after{
			content: "Products";
		  }
		}
	}

	.productsWrap{
		position: relative;
		@include props(mt,150);

		@include mq(md){
			@include props(mt,100,750);
		}

		.subNav{
			position: sticky;
			@include props(h,120);
			@include props(t,110);
			@include props(mb,100);
			left: 0;
			z-index: 10;

			@include mq(md){
				@include props(t,95,750);
				@include props(mb,100,750);
				@include props(h,120,750);
			}
			dl{
				background: #FAF6F4;
				position: absolute;
				top: 0;
				width: 100%;
				dt{
					.navTtl{
						@include fz_vw(18);
						font-weight: bold;
						line-height: 1;
						@include props(pt,51);
						@include props(pb,51);
						text-align: center;
						&:after{
							content: "";
							background: url(/images/products/products_nav_arrow.svg) center center no-repeat;
							background-size: contain;
							margin-left: .8em;
							width: 1.66em;
							height: .55em;
							display: inline-block;
							transition: .5s;
						}

						@include mq(md){
							@include fz_vw(36);
							@include props(pt,42,750);
							@include props(pb,42,750);

							&:after{
								margin-left: .5em;
							}
						}
					}

					&:hover{
						cursor: pointer;
					}

					&.open{
						.navTtl{
							&:after{
								transform: rotate(180deg);
							}
						}
					}
				}

				dd{
					display: none;
					@include props(pb,60);

					@include mq(md){
						@include props(pb,60,750);
					}
					ul{
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						@include props(pt,35);
						@include props(pb,35);
						@include props(pr,83);
						@include props(pl,83);
						background: $basicWhiteColor;

						@include mq(md){
							@include props(pt,0);
							@include props(pb,0);
							@include props(pr,40,750);
							@include props(pl,40,750);
							background: none;
						}

						&:after{
							@include mq(pc){
								content: "";
								display: block;
								width: 31.6666%;
							}
						}

						li{
							width: 31.6666%;
							@include props(h,70);
							text-align: center;
							@include props(mt,15);
							@include props(mb,15);

							a{
								border: 1px solid $basicColor;
								display: flex;
								align-items: center;
								justify-content: center;
								width: 100%;
								height: 100%;
								@include fz_vw(18);
								font-weight: bold;

								@include mq(pc){
									transition: all .5s;
									&:hover{
										border: 1px solid #484848;
										background: #484848;
										color: $basicWhiteColor;
									}
								}
							}

							@include mq(md){
								width: 100%;
								height: inherit;
								max-height: inherit;
								@include props(mt,0);
								@include props(mb,0);

								a{
									border-top: none;
									border-right: none;
									border-left: none;
									border-bottom: 1px solid #D3C5BC;
									@include fz_vw(32);
									@include props(pt,25,750);
									@include props(pb,25,750);
								}
							}
						}
					}
				}
			}
		}

		.productsCat{
			@include props(mb,50);
	
			@include mq(md){
				@include props(mb,200,750);
			}
	
			.productsCatTtl{
				@include fz_vw(40);
				font-weight: bold;
				color: $keyColor;
				text-align: center;
				@include props(mb,80);
	
				@include mq(md){
					@include fz_vw(40);
					@include props(mb,50,750);
				}
			}
	
			.productsCatDesc{
				@include fz_vw(18);
				line-height: 2.3;
				text-align: center;
				@include props(mb,100);
	
				@include mq(md){
					line-height: 1.9;
					@include fz_vw(32);
					@include props(mb,125,750);
					text-align: left;
				}
			}
	
			.productsCatList{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				
				.list{
					display: flex;
					flex-direction: column;
					width: 46.12%;
					@include props(mb,150);
	
					@include mq(md){
						width: 100%;
						@include props(mb,150,750);
	
						&:last-child{
							@include props(mb,0,750);
						}
					}
	
					.productsCatListMainImgArea{
						position: relative;
						@include props(mb,30);
						@include mq(md){
							@include props(mb,50,750);
						}
	
						.productsCatListMainImg{
							@include props(h,400);
							width: 100%;
							opacity: 0.01;
							transition: all .3s;
	
							.slick-list,.slick-track {
								height: 100%;
							}
	
							@include mq(md){
								@include props(h,425,750);
							}
		
							&.slick-initialized{
								opacity: 1;
							}
							.mainInner{
								width: 100%;
								position: relative;
								padding-top: 63.49%;
								@include props(btrr,40);
	
								@include mq(md){
									@include props(btrr,40,750);
								}
								img{
									width: 100%;
									position: absolute;
									top: 0;
									left: 0;
									height: 100%;
									object-fit: cover;
									@include props(btrr,40);
		
									@include mq(md){
										@include props(btrr,40,750);
									}
								}
							}
						}
	
						.productsItemIcon{
							position: absolute;
							@include props(l,20);
							@include props(b,0);
							transform: translate(0,50%);
							@include props(w,113);
							@include props(h,113);
							z-index: 1;
	
							@include mq(md){
								@include props(l,20,750);
								@include props(w,160,750);
								@include props(h,160,750);
							}
						}
					}
	
					.productsCatListThumImg{
						@include props(h,56);
						@include props(mb,50);
						opacity: 0.01;
						transition: all .3s;
	
						.slick-list,.slick-track {
							height: 100%;
						}
	
						@include mq(md){
							@include props(h,98.5,750);
							@include props(mb,40,750);
							@include props(mr,-20,750);
	
							.slick-track{
								margin: 0 0 0 auto;
							}

							*{
								height: 100%;
							}
						}
	
						&.slick-initialized{
							opacity: 1;
						}
	
						.slick-current {
							.thumInner{
								img{
									border-color: $keyColor
								}
							}
						}
						.thumInner{
							@include props(pl,15);
							@include props(pr,15);
							&:hover{
								cursor: pointer;
							}

							.inner{
								position: relative;
								padding-top: 65.44%;
							}
	
							img{
								border-width: 2px;
								border-style: solid;
								border-color: $basicWhiteColor;
								width: 100%;
								object-fit: cover;
								position: absolute;
								top: 0;
								left: 0;
								height: 100%;
							}
	
							@include mq(md){
								@include props(pl,18,750);
								@include props(pr,18,750);
	
								img{
									border-width: 2px;
								}
							}
						}
					}
	
					.productsCatListTtl{
						@include fz_vw(32);
						font-weight: bold;
						@include props(mb,40);
	
						@include mq(md){
							@include fz_vw(36);
							@include props(mb,40,750);
						}
					}
	
					.productsCatListDesc{
						@include props(mb,50);
						@include fz_vw(18);
						line-height: 2;
						p{
							@include fz_vw(18);
							line-height: 2;
	
							em{
								font-style: italic;
							}
	
							a{
								text-decoration: underline;
							}
						}
	
						@include mq(md){
							@include props(mb,60,750);
							@include fz_vw(32);
	
							p{
								@include fz_vw(32);
							}
						}
					}
	
					.linkArea{
						margin-top: auto;
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
	
						.btn02,.btn05{
							width: 47.61%;
						}
	
						@include mq(md){
							justify-content: center;
							.btn02{
								@include props(w,540,750);
	
								&:last-of-type{
									@include props(mt,40,750);
								}
							}

							.btn05{
								@include props(w,540,750);
								@include props(mt,40,750);
							}
						}
					}
				}
			}
		}
	}
}